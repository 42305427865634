import './ContactsTable.less';

import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { Contact } from '../../models/Contact';

interface IContactTableProps {
  contacts: Contact[];
  loading: boolean;
  onContactSelect: Function;
}

const getFieldValue = (value?: string) => {
  return value ? value : <>&mdash;&mdash;&mdash;&mdash;</>;
};

const companyName = (contact: Contact) => {
  let result = new Set();
  if (isCarrier(contact) && contact.carriers) {
    result.add(contact.carriers[0].name);
  }
  if (isTerminal(contact) && contact.terminals) {
    result.add(contact.terminals[0].name);
  }
  return Array.from(result).join(', ');
};

const isCarrier = (contact: Contact) => {
  return contact.carriers && contact.carriers[0];
};
const isTerminal = (contact: Contact) => {
  return contact.terminals && contact.terminals[0];
};

const companyType = (contact: Contact) => {
  let result = [];
  if (isCarrier(contact)) {
    result.push('Carrier');
  }
  if (isTerminal(contact)) {
    result.push('Terminal');
  }
  return result.join(', ');
};

const hasMatch = (value: string, compare: string) => {
  let result = false;
  if (value && compare) {
    if (value.toLowerCase().indexOf(compare.toLowerCase()) >= 0) {
      result = true;
    }
  }
  return result;
};

const ContactsTable: React.FC<IContactTableProps> = ({
  contacts,
  onContactSelect,
}) => {
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>();

  useEffect(() => {
    setFilteredContacts(contacts);
  }, [contacts]);

  const filterList = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldVal = e.target.value;
    if (!fieldVal) {
      setFilteredContacts(contacts);
    } else {
      if (contacts && contacts.length > 0) {
        let filteredList = contacts.filter((contact) => {
          let result = false;
          if (
            contact &&
            (hasMatch(contact.name, fieldVal) ||
              hasMatch(contact.primary_email, fieldVal) ||
              hasMatch(contact.primary_phone, fieldVal) ||
              hasMatch(companyName(contact), fieldVal) ||
              hasMatch(companyType(contact), fieldVal))
          ) {
            result = true;
          } else {
            result = false;
          }
          return result;
        });
        setFilteredContacts(filteredList);
      }
    }
  };

  return (
    <>
      <Input
        allowClear
        placeholder="Quick filter"
        style={{ marginBottom: 14 }}
        onChange={filterList}
      />

      <table className="contact-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Company</th>
            <th>Labels</th>
          </tr>
        </thead>

        <tbody className="contact-table__body">
          {filteredContacts?.map((contact, index) => (
            <tr key={index} onClick={() => onContactSelect(contact.id)}>
              <td>{getFieldValue(contact.name)}</td>
              <td>{getFieldValue(contact.primary_email)}</td>
              <td>{getFieldValue(contact.primary_phone)}</td>
              <td>{companyName(contact)}</td>
              <td>{companyType(contact)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ContactsTable;
