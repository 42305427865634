import './style.less';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import PageLoader from '../components/common/PageLoader';
import { useCarriersList, useSelectedCarrier } from '../hooks/apiHooks';
import SidebarFooter from './SidebarFooter';

/*
interface ILayoutProps {
  menuIndex: number;
  routes: IPageRoute[];
  showCarriersNavigation?: boolean;
  disableLoading?: boolean;
}
*/

const Layout = ({
  children,
  disableLoading,
  menuIndex,
  routes,
  showCarriersNavigation,
}) => {
  const { carrierId } = useParams();
  const isFetching = useIsFetching();
  const { hash, pathname } = useLocation();
  const { carriersList } = useCarriersList(showCarriersNavigation);
  const { selectedCarrier } = useSelectedCarrier();
  const [collapsed, setCollapsed] = useState(false);

  const visibleRoutes = useMemo(
    () => routes.filter((route) => route.menuIndex === menuIndex),
    [menuIndex, routes]
  );

  const uploadRoutePath = useMemo(() => {
    const uploadRoute = visibleRoutes.find((route) => route.key === 'upload');
    let path = uploadRoute?.path;
    if (uploadRoute && carrierId && path?.startsWith('/carrier/')) {
      path = path.replace(':carrierId', carrierId);
    }
    return path;
  }, [carrierId, visibleRoutes]);

  const sortedCarriersList = useMemo(
    () => sortBy(carriersList, (carrier) => carrier.name),
    [carriersList]
  );

  const domainLogo = () => {
    if (window.location.host === 'www.carshippingrates.ca') {
      return '/assets/images/logo_csr_white.png';
    } else {
      return '/assets/images/logo_white.png';
    }
  };

  return (
    <ProLayout
      fixSiderbar
      collapsed={collapsed}
      collapsedButtonRender={false}
      headerContentRender={() => {
        return (
          <div
            style={{
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        );
      }}
      location={{ pathname: pathname + hash }}
      logo={domainLogo()}
      menuExtraRender={() => {
        if (!uploadRoutePath) {
          return null;
        }
        return (
          <div className="menu-item-upload">
            <Link to={uploadRoutePath}>
              <h1>UPLOAD RATES</h1>
              <PlusCircleOutlined />
            </Link>
          </div>
        );
      }}
      menuFooterRender={() =>
        collapsed ? null : (
          <SidebarFooter
            carriers={sortedCarriersList}
            selectedCarrier={selectedCarrier}
            showCarriersNavigation={showCarriersNavigation}
          />
        )
      }
      menuHeaderRender={(logo, title) => (
        <Link className="logo" to="/">
          {logo}
          {title}
        </Link>
      )}
      menuItemRender={(item, dom) => {
        let path = item.path;
        if (carrierId && path?.startsWith('/carrier/')) {
          path = path.replace(':carrierId', carrierId);
        }
        if (path?.includes('settings#')) {
          return (
            <NavHashLink
              smooth
              scroll={(el) =>
                el.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
              to={path}
            >
              {dom}
            </NavHashLink>
          );
        }
        return <Link to={path || '/'}>{dom}</Link>;
      }}
      menuProps={
        collapsed
          ? {}
          : {
              openKeys: ['settings'],
            }
      }
      navTheme="light"
      pageTitleRender={() =>
        window.location.host === 'www.carshippingrates.ca'
          ? 'carshippingrates.ca'
          : 'Carrier Portal'
      }
      route={{
        path: '/',
        routes: visibleRoutes,
      }}
      siderWidth={250}
      style={{ height: '100vh' }}
      title=""
    >
      {isFetching && !disableLoading ? <PageLoader /> : <>{children}</>}
    </ProLayout>
  );
};

export default Layout;
