import './RateTable.less';

import Icon, {
  CarOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  FrownFilled,
  FrownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MehOutlined,
  MoreOutlined,
  PullRequestOutlined,
  SmileFilled,
  SmileOutlined,
  StopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Menu,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { keyBy, maxBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AuthContext } from '../../contexts/authContext';
import {
  useRateCreate,
  useRatesDuplicate,
  useRatesRemove,
  useRatesReverse,
  useRatesUpdate,
  useRateUpdate,
} from '../../hooks/apiHooks/rate';
import {
  formatDateTime,
  getAlphabet,
  getfuelExpiryString,
} from '../../library/util';
import { UserRole } from '../../models/User';
import AddBusinessSvg from '../icons/AddBusinessSvg';
import DollarSvg from '../icons/DollarSvg';
import FuelSurchargeSvg from '../icons/FuelSurchargeSvg';
import SettingsEthernetSvg from '../icons/SettingsEthernetSvg';
import MapModal from '../modals/MapModal';
import MultipleBaseRateAdjustModal from '../modals/MultipleBaseRateAdjustModal';
import MutipleRateTerminalAssignModal from '../modals/MutipleRateTerminalAssignModal';
import RateDetailsModal from '../modals/RateDetailsModal';
import RateTerminalAssignModal from '../modals/RateTerminalAssignModal';
import ShipTypesModal from '../modals/ShipTypesModal';
import RateTableEditableCell from './RateTableEditableCell';

/*
export interface IRateTableData {
  carriers: {
    [key: number]: Carrier;
  };
  changedRowCarrierId?: number;
  carrierClassesMap: {
    [key: number]: {
      id: number;
      classes: Class[] | undefined;
    };
  };
  carrierOptionMap: {
    [key: number]: {
      id: number;
      option: Option | undefined;
    };
  };
  carrierModesMap: {
    [key: number]: {
      id: number;
      modes: Mode[] | undefined;
    };
  };
  shipTypes: ShipType[];
  onShowOriginMap: Function;
  onShowDestinationMap: Function;
  isShowingExtraColumns: boolean;
}

interface coords {
  lat?: number;
  lng?: number;
}
*/
const defaultState = {
  carrierClassesMap: {},
  carrierModesMap: {},
  carrierOptionMap: {},
  carriers: [],
  isShowingExtraColumns: false,
  onShowDestinationMap: () => {},
  onShowOriginMap: () => {},
  shipTypes: [],
};

export const RateTableContext = React.createContext(defaultState);

/*
interface IProps {
  showAllCarriers?: boolean;
  carriers?: Carrier[];
  selectedCarrier?: Carrier;
  shipTypes: ShipType[];
  rates: Rate[];
  isEditable?: boolean;
  loading: boolean;
}
*/

const stringSorter = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const numberSorter = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const addressSortString = (city) => {
  return city.split(', ').reverse().join(', ');
};

const RateTable = ({
  carriers,
  customEmptyText,
  isEditable = true,
  loading,
  rates,
  selectedCarrier,
  shipTypes,
  showAllCarriers,
}) => {
  const { authUser } = useContext(AuthContext);
  const createMutation = useRateCreate();
  const updateMutation = useRateUpdate();
  const removeMutation = useRatesRemove();
  const reverseMutation = useRatesReverse();
  const duplicateMutation = useRatesDuplicate();
  const bulkUpdateMutation = useRatesUpdate();

  const [form] = Form.useForm();
  const [editingRate, setEditingRate] = useState();
  const [focusedRate, setFocusedRate] = useState();
  const [changedRowCarrierId, setChangedRowCarrierId] = useState();
  const [updatingRateId, setUpdatingRateId] = useState();
  const [selectedRateIds, setSelectedRateIds] = useState([]);
  const [rateForDetails, setRateForDetails] = useState();
  const [isRemoveConfirm, setIsRemoveConfirm] = useState(false);
  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const [isSingleRemoveConfirm, setIsSingleRemoveConfirm] = useState(false);
  const [isReverseConfirm, setIsReverseConfirm] = useState(false);
  const [isAdjustBaseRates, setIsAdjustBaseRates] = useState(false);
  const [isChangingShipTypes, setIsChangingShipTypes] = useState(false);
  const [isDuplicateConfirm, setIsDuplicateConfirm] = useState(false);
  const [isMultipleTerminalsAssign, setIsMultipleTerminalsAssign] =
    useState(false);
  const [selectedRateTerminalsAssign, setSelectedRateTerminalsAssign] =
    useState(null);
  const [isShowingExtraColumns, setIsShowingExtraColumns] = useState(false);
  const [isShowingMiles, setIsShowingMiles] = useState(false);
  const [rateForMap, setRateForMap] = useState(null);
  const [mapCoords, setMapCoords] = useState(null);

  const isSuperAdminUser = useMemo(
    () => authUser?.role === UserRole.SUPER_ADMIN,
    [authUser?.role]
  );

  const keyedRates = useMemo(() => keyBy(rates, (rate) => rate.id), [rates]);
  const keyedCarriers = useMemo(
    () =>
      keyBy(
        selectedCarrier ? [selectedCarrier] : carriers,
        (carrier) => carrier.id
      ),
    [carriers, selectedCarrier]
  );

  const carrierModesMap = useMemo(() => {
    if (showAllCarriers) {
      return keyBy(
        carriers?.map((carrier) => ({ id: carrier.id, modes: carrier.modes })),
        (carrier) => carrier.id
      );
    } else {
      if (selectedCarrier) {
        return {
          [selectedCarrier.id]: {
            id: selectedCarrier.id,
            modes: selectedCarrier.modes,
          },
        };
      } else {
        return null;
      }
    }
  }, [carriers, selectedCarrier, showAllCarriers]);

  const carrierClassesMap = useMemo(() => {
    if (showAllCarriers) {
      return keyBy(
        carriers?.map((carrier) => ({
          classes: carrier.classes,
          id: carrier.id,
        })),
        (carrier) => carrier.id
      );
    } else {
      if (selectedCarrier) {
        return {
          [selectedCarrier.id]: {
            classes: selectedCarrier.classes,
            id: selectedCarrier.id,
          },
        };
      } else {
        return null;
      }
    }
  }, [carriers, selectedCarrier, showAllCarriers]);

  const carrierOptionMap = useMemo(() => {
    if (showAllCarriers) {
      return keyBy(
        carriers?.map((carrier) => ({
          id: carrier.id,
          option: carrier.option,
        })),
        (carrier) => carrier.id
      );
    } else {
      if (selectedCarrier) {
        return {
          [selectedCarrier.id]: {
            id: selectedCarrier.id,
            option: selectedCarrier.option,
          },
        };
      } else {
        return null;
      }
    }
  }, [carriers, selectedCarrier, showAllCarriers]);

  const maxClassLength = useMemo(() => {
    if (!showAllCarriers) {
      return selectedCarrier?.classes?.length || 0;
    }
    return (
      maxBy(carriers, (carrier) => carrier.classes?.length)?.classes?.length ||
      0
    );
  }, [carriers, selectedCarrier?.classes?.length, showAllCarriers]);

  const selectedRates = useMemo(
    () =>
      selectedRateIds
        .map((rateId) => keyedRates[rateId])
        .filter((rate) => rate),
    [keyedRates, selectedRateIds]
  );

  const selectedRateTerminals = useMemo(() => {
    return selectedRateTerminalsAssign
      ? (selectedCarrier
          ? selectedCarrier.terminals
          : keyedCarriers[selectedRateTerminalsAssign.carrierId.toString()]
              .terminals) || []
      : [];
  }, [keyedCarriers, selectedCarrier, selectedRateTerminalsAssign]);

  const onEdit = useCallback(
    (row) => {
      form.resetFields();
      form.setFieldsValue(row);
      setEditingRate(row);
    },
    [form]
  );

  const onSave = useCallback(async () => {
    try {
      const row = await form.validateFields();

      const updatedRate = {
        ...editingRate,
        ...row,
      };

      const changedClassRates = row.classRate;
      // if there are new prices then collect the new values here from the fields
      // note that the listing with all carriers does not have price fields so we need to skip these
      if (changedClassRates) {
        const newClassRateMap = {};
        changedClassRates.forEach((value, index) => {
          if (value && value.value) {
            newClassRateMap[index] = { value: Number(value.value) };
          }
        });

        if (newClassRateMap) {
          updatedRate.classRate = newClassRateMap;
        }
      }

      updateMutation.mutate(updatedRate);
      setEditingRate(undefined);
      setUpdatingRateId(updatedRate.id);
      setChangedRowCarrierId(undefined);
    } catch (err) {
      console.log(err);
    }
  }, [editingRate, form, updateMutation]);

  const cancelEditing = useCallback(() => {
    setEditingRate(undefined);
    setChangedRowCarrierId(undefined);
  }, []);

  const onDuplicateRate = useCallback(
    (rate) => {
      const targetRateId = [rate.id];
      duplicateMutation.mutate([targetRateId, selectedCarrier?.id]);
    },
    [duplicateMutation, selectedCarrier?.id]
  );

  const onReverseDuplicateRate = useCallback(
    (rate) => {
      const targetRateId = [rate.id];
      reverseMutation.mutate([targetRateId, selectedCarrier?.id]);
    },
    [reverseMutation, selectedCarrier?.id]
  );

  const onToggleRateActive = useCallback(
    (rate) => {
      rate.active = !rate.active;
      updateMutation.mutate(rate);
      setUpdatingRateId(rate.id);
    },
    [updateMutation]
  );

  const onShowOriginMap = (rate) => {
    setMapCoords({ lat: rate.originLat, lng: rate.originLng });
    setRateForMap(rate);
  };

  const onShowDestinationMap = (rate) => {
    setMapCoords({ lat: rate.destinationLat, lng: rate.destinationLng });
    setRateForMap(rate);
  };

  const canToggleRateTransitional = (rate) => {
    if (rate.shipTypeId === 1 || rate.destinationShipTypeId === 1) {
      return true;
    } else {
      return false;
    }
  };

  const onToggleRateTransitional = useCallback(
    (rate) => {
      rate.transitional = !rate.transitional;
      updateMutation.mutate(rate);
      setUpdatingRateId(rate.id);
    },
    [updateMutation]
  );

  const onToggleRateSkipFuelSurcharge = useCallback(
    (rate) => {
      rate.skipFuelSurcharge = !rate.skipFuelSurcharge;
      updateMutation.mutate(rate);
      setUpdatingRateId(rate.id);
    },
    [updateMutation]
  );

  const onTogglePreferred = useCallback(
    (rate) => {
      if (rate.preferred === 1) {
        rate.preferred = 0;
      } else {
        rate.preferred = 1;
      }
      updateMutation.mutate(rate);
      setUpdatingRateId(rate.id);
    },
    [updateMutation]
  );

  const onToggleUnPreferred = useCallback(
    (rate) => {
      if (rate.preferred === -1) {
        rate.preferred = 0;
      } else {
        rate.preferred = -1;
      }
      updateMutation.mutate(rate);
      setUpdatingRateId(rate.id);
    },
    [updateMutation]
  );

  const onShowDetails = useCallback((rate) => {
    setRateForDetails(rate);
    setIsShowingDetails(true);
  }, []);

  const columns = useMemo(() => {
    const columns = [];
    if (showAllCarriers) {
      columns.push({
        dataIndex: 'carrierId',
        editable: true,
        sorter: (a, b) =>
          stringSorter(
            keyedCarriers[a.carrierId].name || '',
            keyedCarriers[b.carrierId].name || ''
          ),
        title: 'Carrier',
      });
    }
    columns.push(
      ...[
        {
          className: 'rate-table-editable-column',
          dataIndex: 'origin',
          defaultSortOrder: 'ascend',
          editable: true,

          sorter: (a, b) =>
            stringSorter(
              `${addressSortString(a.origin)} ${addressSortString(
                a.destination
              )}`,
              `${addressSortString(b.origin)} ${addressSortString(
                b.destination
              )}`
            ),
          title: 'Origin',
        },
        {
          className: 'rate-table-editable-column',
          dataIndex: 'shipTypeId',
          editable: true,
          sorter: (a, b) =>
            stringSorter(
              shipTypes.find((s) => s.id === a.shipTypeId).name || '',
              shipTypes.find((s) => s.id === b.shipTypeId).name || ''
            ),
          title: 'Orig Type',
        },
        {
          className: 'rate-table-editable-column',
          dataIndex: 'destination',
          editable: true,
          sorter: (a, b) =>
            stringSorter(
              `${addressSortString(a.destination)} ${addressSortString(
                a.origin
              )}`,
              `${addressSortString(b.destination)} ${addressSortString(
                b.origin
              )}`
            ),
          title: 'Destination',
        },
        {
          className: 'rate-table-editable-column',
          dataIndex: 'destinationShipTypeId',
          editable: true,
          sorter: (a, b) =>
            stringSorter(
              shipTypes.find((s) => s.id === a.destinationShipTypeId)?.name ||
                '',
              shipTypes.find((s) => s.id === b.destinationShipTypeId)?.name ||
                ''
            ),
          title: 'Dest Type',
        },
        {
          className: 'rate-table-editable-column',
          dataIndex: 'modeId',
          editable: true,
          sorter: (a, b) => stringSorter(a.modeName ?? '', b.modeName ?? ''),
          title: 'Mode',
        },
        {
          dataIndex: 'distance',
          editable: false,
          isExtra: true,
          render: (cell) =>
            Math.ceil(isShowingMiles ? cell / 1.6 : cell).toLocaleString(),
          sorter: (a, b) => (a.distance ?? 0) - (b.distance ?? 0),
          title: isShowingMiles ? 'Distance (miles)' : 'Distance (kms)',
        },
        {
          dataIndex: 'transitDays',
          editable: true,
          isExtra: true,
          render: (cell) => cell + ' days',
          sorter: (a, b) => (a.transitDays ?? 0) - (b.transitDays ?? 0),
          title: 'Transit',
        },
      ]
    );
    if (selectedCarrier) {
      for (let i = 0; i < maxClassLength; i++) {
        const iClass = selectedCarrier?.classes?.[i];
        const vehicles = iClass?.Vehicles?.map((vehicle) => vehicle.name);
        const title = showAllCarriers ? (
          `Class ${getAlphabet(i)}`
        ) : (
          <Tooltip title={vehicles?.join(', ')}>{iClass?.name}</Tooltip>
        );
        let cellClasses = 'rate-table-editable-column';
        if (i % 2 === 0) {
          cellClasses += ' alt-column';
        }
        columns.push({
          className: cellClasses,
          dataIndex: ['classRate', i, 'value'],
          editable: true,
          title,
        });
      }
    }
    if (!selectedCarrier) {
      // Push the classPrice column only when selectedCarrier is false
      columns.push({
        dataIndex: 'classPrice',
        editable: false,
        isExtra: false,
        render: (cell, record) => {
          let resultString = '';
          const classRates = record.classRate;
          const surcharge = record.fuelSurcharge || 0;
          for (const key in classRates) {
            if (classRates.hasOwnProperty(key)) {
              const className = classRates[key].name;
              let classValue = classRates[key].value;
              if (!record.skipFuelSurcharge) {
                classValue = classRates[key].value * ((100 + surcharge) / 100);
              }
              resultString += `${className}: $${classValue.toFixed(2)}\n`;
            }
          }
          const tooltipTitle = resultString ? (
            <pre>{resultString}</pre>
          ) : (
            'No Rates found'
          );
          return (
            <Space style={{ marginLeft: 10 }}>
              <Tooltip title={tooltipTitle}>
                <InfoCircleOutlined
                  style={{ color: '#1890ff' }}
                  onClick={() => onShowDetails(record)}
                />
              </Tooltip>
            </Space>
          );
        },
        title: 'Rates',
      });
    }
    columns.push(
      ...[
        {
          dataIndex: 'transitional',
          isExtra: true,
          render: (cell) => {
            return cell && <Icon component={SettingsEthernetSvg} />;
          },
          title: <Icon component={SettingsEthernetSvg} />,
        },
        {
          dataIndex: 'id',
          editable: false,
          isExtra: true,
          sorter: (a, b) => numberSorter(a.id, b.id),
          title: 'Id',
        },
        {
          dataIndex: 'updatedAt',
          render: (cell, record) => {
            if (record.id === updatingRateId) {
              return (
                <Spin
                  indicator={<LoadingOutlined spin style={{ fontSize: 24 }} />}
                />
              );
            }
            if (record.id === editingRate?.id) {
              return (
                <Space style={{ marginLeft: 10 }}>
                  <Tooltip title="Save">
                    <Button
                      icon={<CheckOutlined />}
                      shape="circle"
                      size="small"
                      onClick={onSave}
                    />
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <Button
                      icon={<CloseOutlined />}
                      shape="circle"
                      size="small"
                      onClick={cancelEditing}
                    />
                  </Tooltip>
                </Space>
              );
            }
            if (record.id === focusedRate?.id) {
              return (
                <Space style={{ marginLeft: 0 }}>
                  {selectedCarrier && (
                    <Tooltip title="Edit">
                      <Button
                        htmlType="button"
                        icon={<EditOutlined />}
                        shape="circle"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          onEdit(focusedRate);
                        }}
                      />
                    </Tooltip>
                  )}
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="9"
                          onClick={() => onShowDetails(record)}
                        >
                          <Button
                            icon={<InfoCircleOutlined />}
                            size="small"
                            type="link"
                          >
                            Details
                          </Button>
                        </Menu.Item>
                        {isSuperAdminUser && (
                          <Menu.Item
                            key="8"
                            onClick={() => onToggleRateActive(record)}
                          >
                            <Button
                              icon={
                                record.active ? (
                                  <StopOutlined />
                                ) : (
                                  <PullRequestOutlined />
                                )
                              }
                              size="small"
                              type="link"
                            >
                              {record.active
                                ? 'Exclude from search'
                                : 'Include in search'}
                            </Button>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          key="0"
                          onClick={() => setSelectedRateTerminalsAssign(record)}
                        >
                          <Button
                            icon={<Icon component={AddBusinessSvg} />}
                            size="small"
                            type="link"
                          >
                            Add Terminal
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="1"
                          onClick={(e) => {
                            onDuplicateRate(focusedRate);
                          }}
                        >
                          <Button
                            icon={<CopyOutlined />}
                            size="small"
                            type="link"
                          >
                            {' '}
                            Duplicate
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={(e) => {
                            onReverseDuplicateRate(focusedRate);
                          }}
                        >
                          <Button
                            icon={<SwapOutlined />}
                            size="small"
                            type="link"
                          >
                            {' '}
                            Duplicate and Reverse Org/Dest
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="3"
                          onClick={() => onToggleRateTransitional(record)}
                        >
                          <Button
                            disabled={!canToggleRateTransitional(record)}
                            icon={<Icon component={SettingsEthernetSvg} />}
                            size="small"
                            type="link"
                          >
                            Transitional
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="4"
                          onClick={() => onToggleRateSkipFuelSurcharge(record)}
                        >
                          <Button
                            icon={<Icon component={FuelSurchargeSvg} />}
                            size="small"
                            type={record.skipFuelSurcharge ? 'ghost' : 'link'}
                          >
                            {record.skipFuelSurcharge
                              ? 'Include Fuel Surcharge'
                              : 'Skip Fuel Surcharge'}
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="5"
                          onClick={() => onTogglePreferred(record)}
                        >
                          <Button
                            icon={
                              record.preferred === 1 ? (
                                <SmileFilled />
                              ) : (
                                <SmileOutlined />
                              )
                            }
                            size="small"
                            type="link"
                          >
                            Preferred
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="6"
                          onClick={() => onToggleUnPreferred(record)}
                        >
                          <Button
                            icon={
                              record.preferred === -1 ? (
                                <FrownFilled />
                              ) : (
                                <FrownOutlined />
                              )
                            }
                            size="small"
                            type="link"
                          >
                            Un-Preferred
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="7"
                          onClick={() => {
                            setSelectedRateIds([record.id]);
                            setIsSingleRemoveConfirm(true);
                          }}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            loading={removeMutation.isLoading}
                            type="link"
                          >
                            Remove
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      icon={<MoreOutlined />}
                      shape="circle"
                      size="small"
                    />
                  </Dropdown>
                </Space>
              );
            } else {
              return (
                <div>
                  {new Date(cell)
                    .toLocaleDateString('en-US')
                    .replaceAll('/', '-')}
                  {isSuperAdminUser && !record.active && (
                    <Tooltip title="Hidden from search">
                      <StopOutlined style={{ marginLeft: 10 }} />
                    </Tooltip>
                  )}
                </div>
              );
            }
          },
          title: '',
          width: '150px',
        },
      ]
    );
    return columns;
  }, [
    showAllCarriers,
    isShowingMiles,
    selectedCarrier,
    keyedCarriers,
    shipTypes,
    maxClassLength,
    updatingRateId,
    editingRate?.id,
    focusedRate,
    onSave,
    cancelEditing,
    isSuperAdminUser,
    removeMutation.isLoading,
    onEdit,
    onShowDetails,
    onToggleRateActive,
    onDuplicateRate,
    onReverseDuplicateRate,
    onToggleRateTransitional,
    onToggleRateSkipFuelSurcharge,
    onTogglePreferred,
    onToggleUnPreferred,
  ]);

  const mergedColumns = useMemo(() => {
    let rawResult = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record, rowIndex) => ({
          align: col.align,
          dataIndex: col.dataIndex,
          editing: col.editable && record.id === editingRate?.id,
          record,
          rowIndex,
          width: col.width,
        }),
      };
    });

    // filter out extra columns if we aren't using them
    if (!isShowingExtraColumns) {
      rawResult = rawResult.filter((nextCol) => !nextCol.isExtra);
    }

    return rawResult;
  }, [columns, editingRate?.id, isShowingExtraColumns]);

  const onFieldsChange = useCallback(
    (changedFields, allFields) => {
      if (
        changedFields.length &&
        changedFields[0].name[0] === 'carrierId' &&
        changedFields[0].touched
      ) {
        form.setFields([
          {
            name: 'modeId',
            value: undefined,
          },
        ]);
        setChangedRowCarrierId(changedFields[0].value);
        editingRate.carrierId = changedFields[0].value;
      }
    },
    [form, editingRate]
  );

  const onSelectChange = useCallback((selectedRowKeys) => {
    setSelectedRateIds(selectedRowKeys);
  }, []);

  const onRemoveRates = useCallback(() => {
    setIsRemoveConfirm(false);
    removeMutation.mutate([selectedRateIds, selectedCarrier?.id]);
  }, [removeMutation, selectedCarrier?.id, selectedRateIds]);

  const onReverseRates = useCallback(() => {
    setIsReverseConfirm(false);
    reverseMutation.mutate([selectedRateIds, selectedCarrier?.id]);
  }, [reverseMutation, selectedCarrier?.id, selectedRateIds]);

  const onDuplicateRates = useCallback(() => {
    setIsDuplicateConfirm(false);
    duplicateMutation.mutate([selectedRateIds, selectedCarrier?.id]);
  }, [duplicateMutation, selectedCarrier?.id, selectedRateIds]);

  const onToggleTransitionalRates = useCallback(() => {
    const rates = selectedRateIds.map((id) => keyedRates[id]);
    const isTransitional = rates.every((r) => r.transitional);
    const newRates = rates.map((rate) => ({
      ...rate,
      transitional: !isTransitional,
    }));
    bulkUpdateMutation.mutate([newRates, selectedCarrier?.id]);
  }, [bulkUpdateMutation, keyedRates, selectedCarrier?.id, selectedRateIds]);

  const onBulkUpdatePreferred = useCallback(
    (newPreferred) => {
      const rates = selectedRateIds.map((id) => keyedRates[id]);
      const newRates = rates.map((rate) => ({
        ...rate,
        preferred: newPreferred,
      }));
      bulkUpdateMutation.mutate([newRates, selectedCarrier?.id]);
    },
    [bulkUpdateMutation, keyedRates, selectedCarrier?.id, selectedRateIds]
  );

  const onToggleExtraColumns = () => {
    setIsShowingExtraColumns(!isShowingExtraColumns);
  };

  const onToggleShowingMiles = () => {
    setIsShowingMiles(!isShowingMiles);
  };

  useEffect(() => {
    if (updateMutation.isSuccess || createMutation.isSuccess) {
      setUpdatingRateId(undefined);
    }
  }, [createMutation.isSuccess, updateMutation.isSuccess]);

  useEffect(() => {
    if (removeMutation.isSuccess) {
      setSelectedRateIds([]);
    }
  }, [removeMutation.isSuccess, updateMutation.isSuccess]);

  const headerButtonsGeneral = () => {
    return (
      <Space>
        {isShowingExtraColumns && (
          <Button
            loading={bulkUpdateMutation.isLoading}
            style={{ marginLeft: 8 }}
            type="default"
            onClick={onToggleShowingMiles}
          >
            {isShowingMiles ? 'Switch to Kilometres' : 'Switch to Miles'}
          </Button>
        )}
        <Button
          icon={<Icon component={SettingsEthernetSvg} />}
          loading={bulkUpdateMutation.isLoading}
          style={{ marginLeft: 8 }}
          type="default"
          onClick={onToggleExtraColumns}
        >
          {isShowingExtraColumns ? 'Hide Details' : 'Show Details'}
        </Button>
      </Space>
    );
  };

  const headerButtonsForSelections = () => {
    return (
      <Space>
        {selectedRates.length > 0 && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="trans">
                  <Button
                    block
                    icon={<Icon component={SettingsEthernetSvg} />}
                    loading={bulkUpdateMutation.isLoading}
                    onClick={onToggleTransitionalRates}
                  >
                    Transitional
                  </Button>
                </Menu.Item>
                <Menu.Item key="duprev">
                  <Popconfirm
                    style={{
                      maxWidth: '360px',
                    }}
                    title={
                      <>
                        Are you sure you want to DUPLICATE selected rates
                        <br /> and REVERSE origin and destination?
                      </>
                    }
                    visible={isReverseConfirm}
                    onCancel={() => setIsReverseConfirm(false)}
                    onConfirm={onReverseRates}
                  >
                    <Button
                      block
                      icon={<SwapOutlined />}
                      loading={reverseMutation.isLoading}
                      onClick={() => setIsReverseConfirm(true)}
                    >
                      Duplicate and Reverse Org/Dest
                    </Button>
                  </Popconfirm>
                </Menu.Item>
                {selectedCarrier &&
                  (selectedRates.every(
                    (rate, index, arr) => rate.origin === arr[0].origin
                  ) ||
                    selectedRates.every(
                      (rate, index, arr) =>
                        rate.destination === arr[0].destination
                    )) && (
                    <Menu.Item key="3">
                      <Button
                        block
                        icon={<Icon component={AddBusinessSvg} />}
                        onClick={() =>
                          selectedRates.length === 1
                            ? setSelectedRateTerminalsAssign(selectedRates[0])
                            : setIsMultipleTerminalsAssign(true)
                        }
                      >
                        Terminal
                      </Button>
                    </Menu.Item>
                  )}
                <Menu.Item key="remove">
                  <Popconfirm
                    title="Are you sure you want to delete selected rates?"
                    visible={isRemoveConfirm}
                    onCancel={() => setIsRemoveConfirm(false)}
                    onConfirm={onRemoveRates}
                  >
                    <Button
                      block
                      icon={<DeleteFilled />}
                      loading={removeMutation.isLoading}
                      onClick={() => setIsRemoveConfirm(true)}
                    >
                      Remove Rates
                    </Button>
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item key="dup">
                  <Popconfirm
                    title="Are you sure you want to duplicate selected rates?"
                    visible={isDuplicateConfirm}
                    onCancel={() => setIsDuplicateConfirm(false)}
                    onConfirm={onDuplicateRates}
                  >
                    <Button
                      block
                      icon={<CopyOutlined />}
                      loading={duplicateMutation.isLoading}
                      onClick={() => setIsDuplicateConfirm(true)}
                    >
                      Duplicate Rates
                    </Button>
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item key="adjrate">
                  <Button
                    block
                    icon={<Icon component={DollarSvg} />}
                    onClick={() => setIsAdjustBaseRates(true)}
                  >
                    Adjust Base Rates
                  </Button>
                </Menu.Item>
                <Menu.Item key="shiptypes">
                  <Button
                    block
                    icon={<CarOutlined />}
                    onClick={() => setIsChangingShipTypes(true)}
                  >
                    Change Ship Types
                  </Button>
                </Menu.Item>
                <Menu.Item key="preferred">
                  <Button
                    block
                    icon={<SmileOutlined />}
                    onClick={() => onBulkUpdatePreferred(1)}
                  >
                    Mark Preferred
                  </Button>
                </Menu.Item>
                <Menu.Item key="unpreferred">
                  <Button
                    block
                    icon={<FrownOutlined />}
                    onClick={() => onBulkUpdatePreferred(-1)}
                  >
                    Mark Un-Preferred
                  </Button>
                </Menu.Item>
                <Menu.Item key="resetpreferred">
                  <Button
                    block
                    icon={<MehOutlined />}
                    onClick={() => onBulkUpdatePreferred(0)}
                  >
                    Clear Preferred
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button>{'Actions on selected rates >'}</Button>
          </Dropdown>
        )}
      </Space>
    );
  };

  const headerButtons = () => {
    return (
      <>
        {selectedRateIds.length ? headerButtonsForSelections() : null}
        {headerButtonsGeneral()}
      </>
    );
  };

  const fuelSurchargeSummary = () => {
    if (selectedCarrier) {
      let surcharge = selectedCarrier?.option?.fuelSurcharge || 0;
      let surchargeExpiryTimeStamp = selectedCarrier?.option
        ?.fuelSurchargeExpiry
        ? getfuelExpiryString(selectedCarrier?.option?.fuelSurchargeExpiry)
        : '';
      let surchargeTimeStamp = selectedCarrier?.last_surcharge_change
        ? formatDateTime(new Date(selectedCarrier.last_surcharge_change))
        : '';

      let result = `Fuel surcharge: ${surcharge.toFixed(2)}%`;
      if (surchargeExpiryTimeStamp) {
        result += `, Valid until: ${surchargeExpiryTimeStamp}`;
      }
      if (surchargeTimeStamp) {
        result += `, Last updated: ${surchargeTimeStamp}`;
      }
      return result;
    } else {
      return '';
    }
  };

  const costsPerDistanceSummary = () => {
    /*
    interface IMileage {
      distance?: number;
      price?: number;
    }
    */
    if (selectedCarrier) {
      const allPrices = [];

      rates.forEach((rate) => {
        const ratesMap = rate.classRate;
        const prices = Object.keys(ratesMap).map(
          (key) => ratesMap[Number(key)]
        );
        prices.forEach((price) => {
          if (Number(price.value)) {
            allPrices.push({
              distance: rate.distance,
              price: Number(price.value),
            });
          }
        });
      });

      const allMileages = allPrices.map((nextMileage) => {
        const surcharge = selectedCarrier.option?.fuelSurcharge || 0;
        let surchargePrice = 0;
        if (nextMileage.price) {
          surchargePrice = nextMileage.price * ((100 + surcharge) / 100);
        }

        if (nextMileage.distance && nextMileage.distance > 0) {
          return surchargePrice / nextMileage.distance;
        } else {
          return 0;
        }
      });

      const max = Math.max(...allMileages);
      const min = Math.min(...allMileages);
      const avg = allMileages.reduce((a, b) => a + b, 0) / allMileages.length;

      if (isShowingMiles) {
        return `Max: ${(max * 1.6).toFixed(3)}$/mi, Min: ${(min * 1.6).toFixed(
          3
        )}$/mi, Avg: ${(avg * 1.6).toFixed(3)}$/mi`;
      } else {
        return `Max: ${max.toFixed(3)}$/km, Min: ${min.toFixed(
          3
        )}$/km, Avg: ${avg.toFixed(3)}$/km`;
      }
    } else {
      return '';
    }
  };

  return (
    <RateTableContext.Provider
      value={{
        carrierClassesMap: carrierClassesMap,
        carrierModesMap: carrierModesMap,
        carrierOptionMap: carrierOptionMap,
        carriers: keyedCarriers,
        changedRowCarrierId: changedRowCarrierId,
        isShowingExtraColumns: isShowingExtraColumns,
        onShowDestinationMap: onShowDestinationMap,
        onShowOriginMap: onShowOriginMap,
        shipTypes: shipTypes,
      }}
    >
      <Modal
        title="Are you sure you want to delete this rate?"
        visible={isSingleRemoveConfirm}
        onCancel={() => setIsSingleRemoveConfirm(false)}
        onOk={() => {
          onRemoveRates();
          setIsSingleRemoveConfirm(false);
        }}
      ></Modal>
      <Card
        className="rate-table"
        extra={headerButtons()}
        title={
          <>
            <div>{rates.length} Rates </div>
            <div>{costsPerDistanceSummary()}</div>
            <div dangerouslySetInnerHTML={{ __html: fuelSurchargeSummary() }} />
          </>
        }
        type="inner"
      >
        <Form component={false} form={form} onFieldsChange={onFieldsChange}>
          <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: RateTableEditableCell,
              },
            }}
            dataSource={rates}
            loading={loading}
            locale={{
              emptyText: customEmptyText ? customEmptyText : 'No data',
            }}
            pagination={false}
            rowClassName={(record) => {
              let result = '';
              if (isSuperAdminUser) {
                if (record.preferred === 1) {
                  result = 'row-preferred';
                } else if (record.preferred === -1) {
                  result = 'row-unpreferred';
                }
              }
              return result;
            }}
            rowKey={(rate) => rate.id}
            rowSelection={{
              onChange: onSelectChange,
              type: 'checkbox',
            }}
            size="small"
            onRow={(record) => {
              if (!isEditable) {
                return {};
              }
              return {
                onDoubleClick: () => {
                  if (editingRate?.id !== record.id) {
                    onEdit(record);
                  }
                },
                onMouseEnter: () => {
                  if (editingRate?.id !== record.id) {
                    setFocusedRate(record);
                  }
                },
                onMouseLeave: () => {
                  setFocusedRate(undefined);
                },
              };
            }}
          />
        </Form>
      </Card>
      <div style={{ marginTop: 10 }}></div>

      {isMultipleTerminalsAssign && (
        <MutipleRateTerminalAssignModal
          carrier={selectedCarrier}
          rates={selectedRates}
          onClose={() => setIsMultipleTerminalsAssign(false)}
          onSubmit={() => setIsMultipleTerminalsAssign(false)}
        />
      )}

      {selectedRateTerminalsAssign && (
        <RateTerminalAssignModal
          carrier={selectedCarrier}
          rate={selectedRateTerminalsAssign}
          terminals={selectedRateTerminals}
          onClose={() => setSelectedRateTerminalsAssign(null)}
        />
      )}

      {isAdjustBaseRates && (
        <MultipleBaseRateAdjustModal
          carrier={selectedCarrier}
          rates={selectedRates}
          onClose={() => setIsAdjustBaseRates(false)}
          onSubmit={() => setIsAdjustBaseRates(false)}
        />
      )}

      {isChangingShipTypes && (
        <ShipTypesModal
          rates={selectedRates}
          shipTypes={shipTypes}
          onClose={() => setIsChangingShipTypes(false)}
          onSubmit={() => setIsChangingShipTypes(false)}
        />
      )}

      {isShowingDetails && (
        <RateDetailsModal
          keyedCarriers={keyedCarriers}
          rate={rateForDetails}
          shipTypes={shipTypes}
          onClose={() => setIsShowingDetails(false)}
        />
      )}

      {rateForMap && mapCoords && (
        <MapModal
          lat={mapCoords.lat}
          lng={mapCoords.lng}
          rate={rateForMap}
          onClose={() => setRateForMap(null)}
        />
      )}
    </RateTableContext.Provider>
  );
};

export default RateTable;
