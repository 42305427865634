import './ClassList.less';

import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Form,
  Popconfirm,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useCarrierUpdate,
  useClassCreate,
  useClassRemove,
  useClassUpdate,
  useClassVehicleMapUpdate,
} from '../../hooks/apiHooks';
import { getAlphabet } from '../../library/util';
import { UserRole } from '../../models/User';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import PermissionsToggle from '../common/PermissionsToggle';
import ClassModal from '../modals/ClassModal';

/*
interface IProps {
  id: string;
  carrier: Carrier;
  userRole?: UserRole;
  vehicles: Vehicle[];
  classVehicleMap: ClassVehicles;
}
*/

const ClassList = ({ carrier, classVehicleMap, id, userRole, vehicles }) => {
  const createMutation = useClassCreate();
  const updateMutation = useClassUpdate();
  const removeMutation = useClassRemove();
  const carrierUpdateMutation = useCarrierUpdate();
  const updateCarrierVehicleMapMutation = useClassVehicleMapUpdate();

  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [editableClass, setEditableClass] = useState();
  const [deletableClass, setDeletableClass] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const classes = useMemo(() => carrier.classes || [], [carrier]);

  const onAdd = useCallback(
    (classification) => {
      classification.carrierId = carrier.id;
      createMutation.mutate(classification);
    },
    [carrier.id, createMutation]
  );

  const onUpdate = useCallback(
    (classification) => {
      updateMutation.mutate(classification);
    },
    [updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableClass) {
      removeMutation.mutate(deletableClass);
    }
  }, [deletableClass, removeMutation]);

  const onEdit = useCallback((classification) => {
    setEditableClass(classification);
    setShowEditModal(true);
  }, []);

  const onSubmit = useCallback(
    (classVehicles) => {
      updateCarrierVehicleMapMutation.mutate(classVehicles);
    },
    [updateCarrierVehicleMapMutation]
  );

  const onTogglePermission = useCallback(
    (permission) => {
      const newCarrier = {
        ...carrier,
        classPermission: permission,
      };
      carrierUpdateMutation.mutate(newCarrier);
    },
    [carrier, carrierUpdateMutation]
  );

  useEffect(() => {
    form.setFieldsValue(classVehicleMap);
  }, [classVehicleMap, form]);

  useEffect(() => {
    if (!isEditing) {
      setDeletableClass(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  return (
    <Container id={id}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Descriptions
          colon={false}
          column={2}
          layout="vertical"
          title={
            <ListHeader>
              <Space>
                <span>Rate Class</span>
                {userRole === UserRole.SUPER_ADMIN && (
                  <PermissionsToggle
                    defaultValue={carrier.classPermission}
                    submitting={carrierUpdateMutation.isLoading}
                    onChange={onTogglePermission}
                  />
                )}
              </Space>

              <Space>
                <EditButton
                  color={isEditing ? '#389e0d' : undefined}
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {isEditing ? 'Done' : 'Edit'}
                </EditButton>
                <Button
                  ghost
                  style={{ width: 120 }}
                  type="primary"
                  onClick={() => setShowAddModal(true)}
                >
                  Add Class
                </Button>
              </Space>
            </ListHeader>
          }
        >
          {classes.map((classification, index) => (
            <Descriptions.Item
              key={index}
              label={
                <Space>
                  <span>
                    {classification.name}{' '}
                    {classification.name !== `Class ${getAlphabet(index)}` && (
                      <Typography.Text type="secondary">
                        (Class {getAlphabet(index)})
                      </Typography.Text>
                    )}
                  </span>
                  {isEditing && (
                    <Button
                      icon={<EditOutlined />}
                      shape="circle"
                      size="small"
                      onClick={() => onEdit(classification)}
                    />
                  )}
                  {isEditing && (
                    <Popconfirm
                      okButtonProps={{ loading: removeMutation.isLoading }}
                      title="Are you sure to delete this class?"
                      visible={deletableClass?.id === classification.id}
                      onCancel={() => setDeletableClass(undefined)}
                      onConfirm={onDelete}
                    >
                      <Button
                        danger
                        icon={<CloseOutlined />}
                        shape="circle"
                        size="small"
                        onClick={() => setDeletableClass(classification)}
                      />
                    </Popconfirm>
                  )}
                </Space>
              }
            >
              <Form.Item
                key={classification.id}
                noStyle
                name={classification.id}
              >
                <Select
                  className="class-list__vehicle-select"
                  disabled={!isEditing}
                  mode="multiple"
                  placeholder="Vehicle Types"
                  style={{ width: '100%' }}
                >
                  {vehicles.map((vehicle) => (
                    <Select.Option key={vehicle.id} value={vehicle.id}>
                      {vehicle.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Descriptions.Item>
          ))}
          {classes.length % 2 === 1 && (
            <Descriptions.Item>&nbsp;</Descriptions.Item>
          )}
          <Descriptions.Item>
            {isEditing && (
              <Form.Item noStyle>
                <Button
                  htmlType="submit"
                  loading={
                    updateCarrierVehicleMapMutation.isLoading && !deletableClass
                  }
                  type="primary"
                >
                  SAVE
                </Button>
              </Form.Item>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Form>
      {showAddModal && (
        <ClassModal
          classes={classes}
          error={createMutation.error}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableClass && (
        <ClassModal
          classes={classes}
          error={updateMutation.error}
          selectedClass={editableClass}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default ClassList;
