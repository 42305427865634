import { Button, Col, Form, Modal, Row } from 'antd';
import { NumericDictionary } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import {
  formatCurrency,
  formatDateTime,
  getfuelExpiryString,
} from '../../library/util';
import { Carrier } from '../../models/Carrier';
import { Rate } from '../../models/Rate';
import { ShipType } from '../../models/ShipType';
import RateMap from '../common/RateMap';

interface IProps {
  keyedCarriers: NumericDictionary<Carrier>;
  onClose: () => void;
  rate: Rate;
  shipTypes: ShipType[];
}

const RateDetailsModal: React.FC<IProps> = ({
  keyedCarriers,
  onClose,
  rate,
  shipTypes,
}) => {
  const [form] = Form.useForm();
  const [selectedCarrier, setSelectedCarrier] = useState<Carrier | undefined>();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  useEffect(() => {
    if (rate) {
      form.setFieldsValue(rate);
      if (keyedCarriers && rate.carrierId) {
        const newCarrier = keyedCarriers[rate.carrierId];
        setSelectedCarrier(newCarrier);
      }
    }
  }, [form, rate, keyedCarriers]);

  const onFieldsChange = useCallback(
    (changedFields: any) => {
      if (changedFields.carrierId) {
        const newCarrier = keyedCarriers[changedFields.carrierId.toString()];
        setSelectedCarrier(newCarrier);
      }
    },
    [keyedCarriers]
  );

  const dataEntry = (label: string, value: any, span = 6, useHTML = false) => {
    return (
      <Col span={span}>
        <Form.Item label={<b>{label}</b>}>
          {useHTML ? (
            <div dangerouslySetInnerHTML={{ __html: value }} />
          ) : (
            value
          )}
        </Form.Item>
      </Col>
    );
  };

  const originShipTypeName = shipTypes.find(
    (st) => st.id === rate.shipTypeId
  )?.name;
  const destShipTypeName = shipTypes.find(
    (st) => st.id === rate.destinationShipTypeId
  )?.name;

  // collect the pricing - only where there actually is pricing
  type vehiclePrice = {
    id: number;
    name: String;
    basePrice: number;
    fuelSurcharge: number;
    fullPrice: number;
  };
  let prices: vehiclePrice[] = [];
  if (selectedCarrier && selectedCarrier.classes) {
    selectedCarrier.classes.forEach((carrierClass) => {
      let matchingRate = rate.classRate[carrierClass.id];
      if (matchingRate) {
        let newVP: vehiclePrice = {
          basePrice: matchingRate.value,
          fuelSurcharge: 0,
          fullPrice: matchingRate.value,
          id: carrierClass.id,
          name: carrierClass.name,
        };
        const surcharge = selectedCarrier.option?.fuelSurcharge || 0;
        if (newVP.basePrice) {
          if (!rate.skipFuelSurcharge) {
            newVP.fuelSurcharge = newVP.basePrice * (surcharge / 100);
            newVP.fullPrice = newVP.basePrice + newVP.fuelSurcharge;
          }
        }
        prices.push(newVP);
      }
    });
  }

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title={'Rate Details'}
      width={900}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onValuesChange={onFieldsChange}>
        <Row gutter={24}>
          {dataEntry('Carrier', selectedCarrier?.name)}
          {dataEntry('Mode', rate.modeName)}
          {dataEntry('Origin', rate.origin)}
          {dataEntry('Destination', rate.destination)}
        </Row>
        <Row gutter={24}>
          {dataEntry('Distance', `${Math.floor(rate.distance || 0)} km`)}
          {dataEntry('Transit Time', `${rate.transitDays} days`)}
          {dataEntry('Origin Ship Type', originShipTypeName)}
          {dataEntry('Dest Ship Type', destShipTypeName)}
        </Row>
        {selectedCarrier && selectedCarrier.option && (
          <Row gutter={24}>
            {dataEntry(
              'Fuel Surcharge',
              `${selectedCarrier.option?.fuelSurcharge || 0}% ${
                rate.skipFuelSurcharge ? ' (skipped)' : ''
              }`
            )}
            {selectedCarrier.last_surcharge_change && (
              <>
                {dataEntry(
                  'Surcharge Last Updated:',
                  `${formatDateTime(
                    new Date(selectedCarrier.last_surcharge_change)
                  )}`
                )}
              </>
            )}
            {selectedCarrier.option?.fuelSurchargeExpiry && (
              <>
                {dataEntry(
                  'Surcharge Valid until:',
                  `${getfuelExpiryString(
                    String(selectedCarrier?.option?.fuelSurchargeExpiry)
                  )}`,
                  6,
                  true
                )}
              </>
            )}
          </Row>
        )}
        <hr />
        {prices && prices.length > 0 && (
          <Row>
            {prices.map((nextPrice) => (
              <Col key={nextPrice.id} span={Math.ceil(24 / prices.length)}>
                <label>{<b>{nextPrice.name}</b>}</label>
                <table>
                  <tbody>
                    <tr>
                      <th>Base:</th>
                      <td style={{ textAlign: 'right' }}>
                        {formatCurrency(nextPrice.basePrice)}
                      </td>
                    </tr>
                    <tr>
                      <th>Fuel:</th>
                      <td style={{ textAlign: 'right' }}>
                        {formatCurrency(nextPrice.fuelSurcharge)}
                      </td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td style={{ textAlign: 'right' }}>
                        {formatCurrency(nextPrice.fullPrice)}
                      </td>
                    </tr>
                    <tr>
                      <th>Cost per KM:</th>
                      <td style={{ textAlign: 'right' }}>
                        {`$${(
                          nextPrice.fullPrice / Math.floor(rate.distance || 0)
                        ).toLocaleString('en-CA', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3,
                        })}`}
                        {}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            ))}
            <hr />
          </Row>
        )}
        <Row gutter={24}>
          <Col span={24}>
            <RateMap height="300px" rate={rate} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                className="modal-close-btn"
                type="primary"
                onClick={closeModal}
              >
                Done
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RateDetailsModal;
