import './App.less';

import { notification } from 'antd';
import React, { Suspense, useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Routes, { allRoutes } from './AppRoutes';
import Loader from './components/common/Loader';
import { AuthContext } from './contexts/authContext';
import LoginPage from './pages/LoginPage';

const App = () => {
  const { AUTH_LOADING, AUTH_SIGNEDIN, authStatus, authUser, authUserStatus } =
    useContext(AuthContext);

  useEffect(() => {
    if (authUserStatus === 'error') {
      notification.error({
        description: 'Sorry, something went wrong! Please try again later.',
        message: 'Login Failed!',
      });
    }
  }, [authUserStatus]);

  if (
    authStatus === AUTH_LOADING ||
    (authStatus === AUTH_SIGNEDIN && !authUser)
  ) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => {
              return authStatus !== AUTH_SIGNEDIN ? (
                <LoginPage {...props} />
              ) : (
                <Redirect to="/" />
              );
            }}
          />
          <Route
            path="/"
            render={() => {
              return authStatus === AUTH_SIGNEDIN &&
                authUser &&
                authUser.role ? (
                <Routes routes={allRoutes[authUser.role]} />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
