import axios from 'axios';

import { Class, ClassRates } from '../models/Class';
import { ClassVehicles } from '../models/Vehicle';

const uri = '/class';

const create = async (classification: Class) => {
  const { data } = await axios.post<Class>(`${uri}`, classification);
  return data;
};

const get = async (id: number) => {
  const { data } = await axios.get<Class>(`${uri}/${id}`);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<{
    classes: Class[];
    classRates: ClassRates;
  }>(`${uri}`);
  return data;
};

const update = async (classification: Class) => {
  const { data } = await axios.put<Class>(
    `${uri}/${classification.id}`,
    classification
  );
  return data;
};

const remove = async (classification: Class) => {
  const { data } = await axios.delete(`${uri}/${classification.id}`);
  return data;
};

const getClassRates = async () => {
  const { data } = await axios.get<Class>(`${uri}/map`);
  return data;
};

const getClassVehicleMap = async () => {
  const { data } = await axios.get<ClassVehicles>(`${uri}/vehicle`);
  return data;
};

const updateClassVehicleMap = async (map: ClassVehicles) => {
  const { data } = await axios.put<ClassVehicles>(`${uri}/vehicle`, map);
  return data;
};

const apis = {
  create,
  get,
  getAll,
  getClassRates,
  getClassVehicleMap,
  remove,
  update,
  updateClassVehicleMap,
};

export default apis;
