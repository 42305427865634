import axios from 'axios';

import { Insurance } from '../models/Insurance';

const uri = '/insurance';

const update = async (insurance: Insurance) => {
  const { data } = await axios.post<Insurance>(`${uri}`, insurance);
  return data;
};

const apis = {
  update,
};

export default apis;
