import { green } from '@ant-design/colors';
import { Button, Tooltip } from 'antd';
import React, { ReactNode, useState } from 'react';

interface IProps {
  value?: boolean;
  icon?: ReactNode;
  tooltip: string;
  onChange?: (value: boolean) => void;
}

const ButtonToggle: React.FC<IProps> = ({
  icon,
  onChange,
  tooltip,
  value: defaultValue,
}) => {
  const [isActive, setIsActive] = useState(defaultValue);

  const handleChange = () => {
    if (onChange) {
      onChange(!isActive);
    }
    setIsActive(!isActive);
  };

  return (
    <Tooltip title={tooltip}>
      <Button
        icon={icon}
        shape="circle"
        style={{
          background: isActive ? green[5] : '#fff',
          color: isActive ? '#fff' : '',
        }}
        onClick={handleChange}
      />
    </Tooltip>
  );
};

export default ButtonToggle;
