import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const RateMap = ({ height, rate }) => {
  const ref = useRef();
  const zoom = 8;

  useEffect(() => {
    if (rate.originLat && rate.originLng) {
      let map = new window.google.maps.Map(ref.current, {
        center: {
          lat: (rate.originLat + rate.destinationLat) / 2,
          lng: (rate.originLng + rate.destinationLng) / 2,
        },
        zoom: zoom,
      });

      var bounds = new window.google.maps.LatLngBounds();
      bounds.extend(
        new window.google.maps.LatLng(rate.originLat, rate.originLng)
      );
      bounds.extend(
        new window.google.maps.LatLng(rate.destinationLat, rate.destinationLng)
      );
      map.fitBounds(bounds);

      let originMarker = new window.google.maps.Marker({
        map: map,
        position: { lat: rate.originLat, lng: rate.originLng },
      });
      originMarker.setMap(map);

      let destinatiomMarker = new window.google.maps.Marker({
        map: map,
        position: { lat: rate.destinationLat, lng: rate.destinationLng },
      });
      destinatiomMarker.setMap(map);
    }
  }, [rate, zoom]);

  return (
    <div
      ref={ref}
      style={{ height: height ? height : '300px', width: '100%' }}
    />
  );
};

RateMap.propTypes = {
  height: PropTypes.string,
  rate: PropTypes.object.isRequired,
};

export default RateMap;
