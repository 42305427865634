import { PageHeader } from '@ant-design/pro-layout';
import styled from 'styled-components';

const PanelHeader = styled(PageHeader)`
  padding-left: 0;
  padding-right: 0;

  .ant-page-header-heading-title {
    font-size: 16px;
    font-weight: 700;
  }
`;

export default PanelHeader;
