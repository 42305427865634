import './PathBuilderPage.less';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from 'antd';
import React, { useState } from 'react';

import AddressSearchInput from '../components/common/AddressSearchInput';
import { useDefaultModes } from '../hooks/apiHooks';
import { useCarriers } from '../hooks/apiHooks/carrier';
import { useNextRates } from '../hooks/apiHooks/path';
import Layout from '../layout';

/*
export interface IRoute {
  rates: Rate[];
}

interface IFilterOptions {
  distanceCutoff: number;
  preferredOnly: boolean;
  text: string;
  text2: string;
}
*/

const PathBuilderPage = ({ routes }) => {
  const [filter, setFilter] = useState('');
  const [currentRoute, setCurrentRoute] = useState();
  const [currentOrigin, setCurrentOrigin] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    distanceCutoff: 0,
    preferredOnly: false,
    text: '',
    text2: '',
  });

  const { allCarriers } = useCarriers();
  const { defaultModes } = useDefaultModes();

  const { rates, searchingRates } = useNextRates(filter);
  const [form] = Form.useForm();
  const [freeform_form] = Form.useForm();

  const onLocationChange = (values) => {
    resetRoute();
    if (
      values.origin &&
      values.origin !== '' &&
      values.origin !== currentOrigin
    ) {
      setCurrentOrigin(values.origin);
      setFilter(values.origin);
    }
  };

  const resetRoute = () => {
    const route = {
      rates: [],
      totalPrice: 0,
    };
    setCurrentRoute(route);
  };
  const newOrCurrentRoute = () => {
    let route = {};
    if (currentRoute) {
      route = { ...currentRoute };
    } else {
      route = {
        rates: [],
        totalPrice: 0,
      };
    }
    return route;
  };

  const resetRatesFilter = () => {
    setFilterOptions({
      distanceCutoff: 0,
      preferredOnly: false,
      text: '',
      text2: '',
    });
  };

  const addToRoute = async (newRate) => {
    let route = newOrCurrentRoute();

    route.rates.push(newRate);

    setCurrentRoute(route);
    setCurrentOrigin(newRate.destination);
    setFilter(newRate.destination);
    resetRatesFilter();
  };

  const onRateSelect = (selectedRateId) => {
    const matchingRate = rates.filter(
      (nextRate) => nextRate.id === selectedRateId
    )[0];

    if (matchingRate) {
      addToRoute(matchingRate);
    }
  };

  const onRateRemove = async (selectedRateId) => {
    let route = newOrCurrentRoute();

    let oldRates = route.rates;
    let newRates = oldRates.filter((x) => x.id !== selectedRateId);

    route.rates = newRates;
    setCurrentRoute(route);

    if (route.rates.length > 0) {
      let newDestination = route.rates[route.rates.length - 1].destination;
      setFilter(newDestination);
      setCurrentOrigin(newDestination);
    } else {
      setFilter(currentOrigin);
    }
  };

  const onFreeformAdd = async (values) => {
    // get rate values
    let newRate = {};
    newRate.origin = currentOrigin;
    // get value of freeform_destination input
    console.log(values);
    newRate.destination = values.freeform_destination;
    newRate.carrierName = values.freeform_carrier;
    newRate.modeName = values.freeform_mode;
    newRate.distance = values.freeform_distance;
    newRate.transitDays = values.freeform_days;
    newRate.freeformPrice = values.freeform_price;

    // time-unique dummy rate id out of range of real rates
    newRate.id = Math.floor(Date.now() / 100);

    addToRoute(newRate);

    freeform_form.resetFields();
  };

  const totalDistance = () => {
    let result = 0;
    if (currentRoute) {
      currentRoute.rates.forEach((x) => (result += x ? x.distance || 0 : 0));
    }
    return result;
  };

  const totalDays = () => {
    let result = 0;
    if (currentRoute) {
      currentRoute.rates.forEach((x) => (result += x ? x.transitDays || 0 : 0));
    }
    return result;
  };

  const totalPrice = () => {
    let result = 0;
    if (currentRoute) {
      currentRoute.rates.forEach((x) => {
        result += x ? priceForRate(x) : 0;
      });
    }
    return result;
  };

  const hasMatch = (value, compare) => {
    let result = false;
    if (value && compare) {
      if (value.toLowerCase().indexOf(compare.toLowerCase()) >= 0) {
        result = true;
      }
    }
    return result;
  };

  const filteredRates = () => {
    let result = rates;

    if (
      filterOptions &&
      (filterOptions.text || filterOptions.text2 || filterOptions.preferredOnly)
    ) {
      result = result.filter((rate) => {
        let match1 = false;
        let match2 = false;

        if (filterOptions.preferredOnly) {
          if (!rate.preferred) {
            return false;
          }
        }

        if (filterOptions.text === '') {
          match1 = true;
        } else if (
          hasMatch(rate.origin, filterOptions.text) ||
          hasMatch(rate.destination, filterOptions.text) ||
          hasMatch(rate.carrierName || '', filterOptions.text) ||
          hasMatch(rate.modeName || '', filterOptions.text)
        ) {
          match1 = true;
        }
        if (filterOptions.text2 === '') {
          match2 = true;
        } else if (
          hasMatch(rate.origin, filterOptions.text2) ||
          hasMatch(rate.destination, filterOptions.text2) ||
          hasMatch(rate.carrierName || '', filterOptions.text2) ||
          hasMatch(rate.modeName || '', filterOptions.text2)
        ) {
          match2 = true;
        }

        return match1 && match2;
      });
    }

    return result;
  };

  const textFilter = (e) => {
    const fieldVal = e.target.value;
    let newOptions = { ...filterOptions };
    if (e.target.id === 'filter1') {
      newOptions.text = fieldVal;
    } else {
      newOptions.text2 = fieldVal;
    }
    setFilterOptions(newOptions);
  };

  const onPreferredOnlyChanged = (checked) => {
    let newOptions = { ...filterOptions };
    newOptions.preferredOnly = checked;
    setFilterOptions(newOptions);
  };

  const rateFilters = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Switch onChange={onPreferredOnlyChanged} /> Preferred only
        </div>
        <Input
          allowClear
          id="filter1"
          placeholder="Quick filter 1"
          style={{ flex: 3, marginBottom: 14, marginRight: 14 }}
          onChange={textFilter}
        />
        <Input
          allowClear
          id="filter2"
          placeholder="Quick filter 2"
          style={{ flex: 3, marginBottom: 14 }}
          onChange={textFilter}
        />
      </div>
    );
  };

  const priceForRate = (rate, vehicleType = 0) => {
    let result = 0;
    if (rate.freeformPrice) {
      // first check for freeformPrice
      result = rate.freeformPrice;
    } else {
      // otherwise look up classRates
      if (rate.classRate) {
        // for now just get the first rate - later figure out by type
        const firstKey = parseInt(Object.keys(rate.classRate)[0]);
        const firstPrice = rate.classRate[firstKey];
        if (firstPrice) {
          result = firstPrice.value;
        }
        if (rate.fuelSurcharge) {
          result += (result * rate.fuelSurcharge) / 100;
        }
      }
    }
    return result;
  };

  return (
    <Layout disableLoading showCarriersNavigation menuIndex={0} routes={routes}>
      <PageHeader className="site-page-header" title="Path Builder" />

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space align="start" direction="horizontal" style={{ width: '100%' }}>
          <Card>
            <Form form={form} onFinish={onLocationChange}>
              <div>
                <label htmlFor="origin">Origin</label>
                <Form.Item
                  name="origin"
                  rules={[{ message: 'Missing Field', required: true }]}
                >
                  <AddressSearchInput value={currentOrigin} />
                </Form.Item>
                <label htmlFor="origin">Destination</label>
                <Form.Item
                  name="destination"
                  rules={[{ message: 'Missing Field', required: true }]}
                >
                  <AddressSearchInput />
                </Form.Item>
                <br />
                <Form.Item>
                  <Button
                    disabled={searchingRates}
                    htmlType="submit"
                    loading={searchingRates}
                    type="primary"
                  >
                    Start Builder
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Card>

          {currentOrigin && (
            <Card style={{ width: '100%' }} title="Selected Path">
              <Form form={freeform_form} onFinish={onFreeformAdd}>
                <table className="routeBuilderList">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Carrier</th>
                      <th>Mode</th>
                      <th>Distance</th>
                      <th>Days</th>
                      <th>Cost</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRoute &&
                      currentRoute.rates.map((nextRate) => (
                        <tr key={nextRate.id}>
                          <td>{nextRate.origin}</td>
                          <td>{nextRate.destination}</td>
                          <td>{nextRate.carrierName}</td>
                          <td>{nextRate.modeName}</td>
                          <td className="right">
                            {Math.round(nextRate.distance || 0)} km
                          </td>
                          <td>{nextRate.transitDays}</td>
                          <td className="right">
                            ${Math.round(priceForRate(nextRate))}
                          </td>
                          <td className="center">
                            <Button
                              icon={<MinusOutlined />}
                              shape="circle"
                              size="small"
                              type="primary"
                              onClick={() => onRateRemove(nextRate.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    {currentOrigin && allCarriers && (
                      <tr key="freeform">
                        <td>
                          <Form.Item>{currentOrigin}</Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            name="freeform_destination"
                            rules={[
                              { message: 'Missing Field', required: true },
                            ]}
                          >
                            <AddressSearchInput />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            name="freeform_carrier"
                            style={{ width: 180 }}
                          >
                            <Select placeholder="Carrier">
                              {allCarriers.map((carrier) => (
                                <Select.Option
                                  key={carrier.name}
                                  value={carrier.name}
                                >
                                  {carrier.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            name="freeform_mode"
                            style={{ width: 160 }}
                          >
                            <Select placeholder="Mode">
                              {defaultModes.map((mode) => (
                                <Select.Option
                                  key={mode.name}
                                  value={mode.name}
                                >
                                  {mode.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </td>
                        <td className="right">
                          <Form.Item name="freeform_distance">
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item name="freeform_days">
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item name="freeform_price">
                            <InputNumber />
                          </Form.Item>
                        </td>
                        <td className="center">
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              icon={<PlusOutlined />}
                              shape="circle"
                              size="small"
                              type="primary"
                            ></Button>
                          </Form.Item>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={4}>TOTAL:</td>
                      <td className="right">
                        {Math.round(totalDistance())} km
                      </td>
                      <td>{totalDays()}</td>
                      <td className="right">${Math.round(totalPrice())}</td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Card>
          )}
        </Space>

        <Card title="Next Rates">
          {rates && rates.length > 0 && (
            <>
              {rateFilters()}
              <table className="routeBuilderList">
                <thead>
                  <tr>
                    <th>Add</th>
                    <th>Origin</th>
                    <th className="right">Distance from Start</th>
                    <th>Destination</th>
                    <th>Carrier</th>
                    <th>Mode</th>
                    <th className="right">Trip Distance</th>
                    <th>Transit Days</th>
                    <th className="right">Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRates().map((nextRate) => {
                    const cellClass = nextRate.preferred
                      ? 'preferred'
                      : 'nonpreferred';
                    return (
                      <tr key={nextRate.id} className={cellClass}>
                        <td>
                          <Button
                            icon={<PlusOutlined />}
                            shape="circle"
                            size="small"
                            type="primary"
                            onClick={() => onRateSelect(nextRate.id)}
                          ></Button>
                        </td>
                        <td>{nextRate.origin}</td>
                        <td className="right">
                          {Math.round((nextRate.distanceToOrigin || 0) / 1000)}{' '}
                          km
                        </td>
                        <td>{nextRate.destination}</td>
                        <td>{nextRate.carrierName}</td>
                        <td>{nextRate.modeName}</td>
                        <td className="right">
                          {Math.round(nextRate.distance || 0)} km
                        </td>
                        <td>{nextRate.transitDays}</td>
                        <td className="right">
                          ${Math.round(priceForRate(nextRate))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          {(!rates || rates.length === 0) && (
            <div>Enter origin and destination to get matching rates.</div>
          )}
        </Card>
      </Space>
    </Layout>
  );
};

export default PathBuilderPage;
