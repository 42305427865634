import { useQuery } from 'react-query';

import vinauditService from '../../services/vinauditService';

export const useVIN = (vin?: string) => {
  const { data, isLoading, status } = useQuery(['vinaudit', vin], () =>
    vinauditService.getSpecifications(vin)
  );

  return {
    isLoading,
    status,
    vinaudit: data,
  };
};
