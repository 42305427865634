import axios from 'axios';

import { PathSearchFilter, RatePath } from '../models/Path';
import { Rate } from '../models/Rate';

const uri = '/path';

const getPaths = async (filter: PathSearchFilter) => {
  const params = new URLSearchParams();
  params.append('origin', filter.origin);
  params.append('destination', filter.destination);
  params.append('radius', filter.radius?.toString() || '');

  const { data } = await axios.get<RatePath[][]>(`${uri}?${params.toString()}`);
  return data;
};

const getNextRates = async (location: string) => {
  const params = new URLSearchParams();
  params.append('location', location);
  const { data } = await axios.get<Rate[]>(`/path/next?${params.toString()}`);

  return data;
};

const apis = {
  getNextRates,
  getPaths,
};

export default apis;
