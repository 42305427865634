import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { DefaultMode, Mode } from '../../models/Mode';
import modeService from '../../services/modeService';

export const useDefaultModes = () => {
  const { data, status } = useQuery('defaultModes', () =>
    modeService.getDefault()
  );

  return {
    defaultModes: data || [],
    status,
  };
};

export const useDefaultModeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<DefaultMode, Error, DefaultMode>(
    modeService.createDefault,
    {
      onSuccess: (data) => {
        queryClient.setQueryData<DefaultMode[]>(
          'defaultModes',
          (modes: DefaultMode[] | undefined) => [...(modes || []), data]
        );
      },
    }
  );
};

export const useDefaultModeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<DefaultMode, Error, DefaultMode>(
    modeService.updateDefault,
    {
      onSuccess: (data) => {
        queryClient.setQueryData<DefaultMode[]>(
          'defaultModes',
          (modes: DefaultMode[] | undefined) =>
            modes?.map((mode) => (mode.id === data.id ? data : mode)) || []
        );
      },
    }
  );
};

export const useDefaultModeRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<DefaultMode, Error, number>(modeService.removeDefault, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<DefaultMode[]>(
        'defaultModes',
        (modes: DefaultMode[] | undefined) =>
          modes?.filter((mode) => mode.id !== variables) || []
      );
    },
  });
};

export const useModeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Mode, Error, Mode>(modeService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) => {
          if (!carrier) {
            return undefined;
          }
          return {
            ...carrier,
            modes: carrier.modes && [...carrier.modes, data],
          };
        }
      );
    },
  });
};

export const useModeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Mode, Error, Mode>(modeService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            modes: carrier.modes?.map((mode) =>
              mode.id === data.id ? data : mode
            ),
          }
      );
    },
  });
};

export const useModeRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<Mode, Error, Mode>(modeService.remove, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', variable.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            modes: carrier.modes?.filter((mode) => mode.id !== variable.id),
          }
      );
    },
  });
};
