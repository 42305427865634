import axios from 'axios';

import { FileSignedUrlRequest } from '../models/File';

const uri = '/file';

const getSignedUrl = (request: FileSignedUrlRequest) => {
  return axios.post<string>(uri, request);
};

const uploadFile = (url: string, file: any, contentType: string) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  });
};

const apis = {
  getSignedUrl,
  uploadFile,
};

export default apis;
