import axios from 'axios';

import { Carrier, Carriernote } from '../models/Carrier';
import { CrmCarrierOrder } from '../models/CrmCarrierOrder';
import { Option } from '../models/Option';
import { Rate } from '../models/Rate';
import { CarrierTerminals } from '../models/Terminal';
import { Valuehistory } from '../models/Valuehistory';
import { Vehicle } from '../models/Vehicle';

const uri = '/carrier';

const create = async (carrier: Carrier) => {
  const { data } = await axios.post<Carrier>(`${uri}`, carrier);
  return data;
};

const get = async (id: number) => {
  const { data } = await axios.get<Carrier>(`${uri}/${id}`);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<Carrier[]>(`${uri}`);
  return data;
};

const getList = async () => {
  const { data } = await axios.get<Carrier[]>(`${uri}/list`);
  return data;
};

const update = async (carrier: Carrier) => {
  const { data } = await axios.put<Carrier>(`${uri}/${carrier.id}`, carrier);
  return data;
};

const remove = async (id: number) => {
  const { data } = await axios.delete(`${uri}/${id}`);
  return data;
};

const getCrmCarrierOrders = async (carrier: Carrier) => {
  const { data } = await axios.get<CrmCarrierOrder[]>(
    `${uri}/${carrier.crmCarrierId}/crmCarrierOrders`
  );
  return data;
};

const getCarrierTerminalMap = async () => {
  const { data } = await axios.get<CarrierTerminals>(`${uri}/terminal`);
  return data;
};

const updateCarrierTerminalMap = async (carrierTerminals: CarrierTerminals) => {
  const { data } = await axios.put<CarrierTerminals>(
    `${uri}/terminal`,
    carrierTerminals
  );
  return data;
};

const getVehicles = async (id: number) => {
  const { data } = await axios.get<Vehicle[]>(`${uri}/${id}/vehicle`);
  return data;
};

const getSurchargeHistory = async (id: number) => {
  const { data } = await axios.get<Valuehistory[]>(
    `${uri}/${id}/surchargeHistory`
  );
  return data;
};

const updateOption = async (option: Option) => {
  const { data } = await axios.post<Option>(
    `${uri}/${option.carrierId}/option`,
    option
  );
  return data;
};

const getRates = async (id: number) => {
  const { data } = await axios.get<Rate[]>(`${uri}/${id}/rate`);
  return data;
};

const createNote = async (note: Carriernote) => {
  const { data } = await axios.post<Carriernote>(
    `${uri}/${note.carrierId}/note`,
    note
  );
  return data;
};

const deleteNote = async (id: number) => {
  const { data } = await axios.delete(`${uri}/note/${id}`);
  return data;
};

const apis = {
  create,
  createNote,
  deleteNote,
  get,
  getAll,
  getCarrierTerminalMap,
  getCrmCarrierOrders,
  getList,
  getRates,
  getSurchargeHistory,
  getVehicles,
  remove,
  update,
  updateCarrierTerminalMap,
  updateOption,
};

export default apis;
