import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { useRatesAdjustBaseRate } from '../../hooks/apiHooks/rate';
import { Carrier } from '../../models/Carrier';
import { Rate } from '../../models/Rate';

const OPTION_DOLLAR = 'dollar';
const OPTION_PERCENT = 'percent';

interface IProps {
  carrier?: Carrier;
  rates: Rate[];
  onSubmit: () => void;
  onClose: () => void;
}

const MultipleBaseRateAdjustModal: React.FC<IProps> = ({
  carrier,
  onClose,
  onSubmit,
  rates,
}) => {
  const { isSuccess, mutate } = useRatesAdjustBaseRate();

  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState('dollar');

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  type FormState = {
    amountDollars: number;
    amountPercent: number;
    type: string;
  };

  const submitForm = useCallback(
    (formState: FormState) => {
      let finalValue =
        formState.type === OPTION_DOLLAR
          ? formState.amountDollars
          : formState.amountPercent;
      mutate([
        rates.map((rate) => rate.id),
        finalValue,
        formState.type,
        carrier?.id,
      ]);
    },
    [carrier?.id, mutate, rates]
  );

  const onTypeSelect = (e: RadioChangeEvent) => {
    setSelectedType(e.target.value);
    form.setFieldsValue({ amountDollars: '$', amountPercent: '%' });
  };

  useEffect(() => {
    if (isSuccess) {
      onSubmit();
    }
  }, [isSuccess, onSubmit]);

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title="Adjust Base Rates"
      width={400}
      onCancel={closeModal}
    >
      <Form
        form={form}
        initialValues={{
          amountDollars: '$',
          amountPercent: '',
          type: OPTION_DOLLAR,
        }}
        layout="vertical"
        onFinish={submitForm}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Type" name="type">
              <Radio.Group value={selectedType} onChange={onTypeSelect}>
                <Radio.Button value={OPTION_DOLLAR}>$ Amount</Radio.Button>
                <Radio.Button value={OPTION_PERCENT}>% Rate</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {selectedType === OPTION_DOLLAR && (
              <Form.Item
                label="$ Amount"
                name="amountDollars"
                rules={[{ message: 'Amount is required!', required: true }]}
              >
                <InputNumber
                  formatter={(value) => `$ ${value}`}
                  parser={(value) => value!.replace('$', '')}
                />
              </Form.Item>
            )}
            {selectedType === OPTION_PERCENT && (
              <Form.Item
                label="% Rate"
                name="amountPercent"
                rules={[{ message: 'Amount is required!', required: true }]}
              >
                <InputNumber
                  formatter={(value) => `${value}%`}
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MultipleBaseRateAdjustModal;
