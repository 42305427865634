//import * as Sentry from '@sentry/react';
//import { Integrations } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import axios, { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import AuthProvider from './contexts/authContext';
import reportWebVitals from './reportWebVitals';

/*
Sentry.init({
  dsn: 'https://c6f74300ad8b4eb3946095b6cfd23fcf@o766229.ingest.sentry.io/5797649',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<Error>) => {
    if (isEmpty(error.response?.data)) {
      return Promise.reject(new Error('Server Error'));
    }
    const errorObj = error.response?.data as any;
    if (errorObj.errors?.[0]?.message) {
      return Promise.reject(new Error(errorObj.errors?.[0]?.message));
    }
    return Promise.reject(error.response?.data);
  }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider locale={enUS}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// swap favicon based on domain
let favImg = '/favicon.ico';
let pageTitle = 'MVS Canada - Carrier Portal';
if (window.location.host === 'www.carshippingrates.ca') {
  favImg = '/faviconcsr.ico';
  pageTitle = 'CarShippingRates.ca';
}

let headTitle = document.querySelector('head');
let setFavicon = document.createElement('link');
setFavicon.setAttribute('rel', 'shortcut icon');
setFavicon.setAttribute('href', favImg);
headTitle?.appendChild(setFavicon);

document.title = pageTitle;
