import './CarrierOptionsPanel.less';

import {
  ClockCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import { useCarrierUpdate, useOptionUpdate } from '../../hooks/apiHooks';
// import { Carrier, CarrierPermission } from '../../models/Carrier';
// import { Option } from '../../models/Option';
import { UserRole } from '../../models/User';
// import { Valuehistory } from '../../models/Valuehistory';
import carrierService from '../../services/carrierService';
import ConditionSelect from '../common/ConditionSelect';
import LengthUnitsSelect from '../common/LengthUnitsSelect';
import Loader from '../common/Loader';
import PanelHeader from '../common/PanelHeader';
import PermissionsToggle from '../common/PermissionsToggle';
import WeightUnitsSelect from '../common/WeightUnitsSelect';

/*
interface IProps {
  id: string;
  carrier: Carrier;
  userRole?: UserRole;
}
*/

const CarrierOptionsPanel = ({ carrier, id, userRole }) => {
  const updateMutation = useOptionUpdate();
  const carrierUpdateMutation = useCarrierUpdate();
  const [surchargeHistory, setSurchargeHistory] = useState([]);
  const [showingSurchargeHistory, setShowingSurchargeHistory] =
    React.useState(false);

  const [form] = Form.useForm();

  const onSubmit = useCallback(
    (option) => {
      option.carrierId = carrier.id;
      if (carrier.option?.id) {
        option.id = carrier.option?.id;
      }

      updateMutation.mutate(option);
    },
    [carrier.id, carrier.option?.id, updateMutation]
  );

  const getPolicyItemUnit = useCallback(
    (policyIndex, itemIndex) => {
      return form.getFieldValue([
        'itemsPolicy',
        policyIndex,
        'items',
        itemIndex,
        'unit',
      ]);
    },
    [form]
  );

  const onTogglePermission = useCallback(
    (permission) => {
      const newCarrier = {
        ...carrier,
        optionPermission: permission,
      };
      carrierUpdateMutation.mutate(newCarrier);
    },
    [carrier, carrierUpdateMutation]
  );

  const handleShowSurchargeHistory = async () => {
    if (showingSurchargeHistory) {
      handleHideSurchargeHistory();
    } else {
      const surcharges = await carrierService.getSurchargeHistory(carrier.id);
      console.log('Got surcharge history', surcharges);
      setSurchargeHistory(surcharges);
      setShowingSurchargeHistory(true);
    }
  };

  const handleHideSurchargeHistory = () => {
    setShowingSurchargeHistory(false);
  };

  const surchargeHistoryContent = () => {
    if (surchargeHistory && surchargeHistory.length > 0) {
      return (
        <div>
          <table>
            <thead>
              <tr>
                <th style={{ padding: 8 }}>Value</th>
                <th style={{ padding: 8 }}>Date</th>
                <th style={{ padding: 8 }}>User</th>
              </tr>
            </thead>
            <tbody>
              {surchargeHistory.map((surcharge) => (
                <tr key={surcharge.id}>
                  <td style={{ padding: 8 }}>{surcharge.value}</td>
                  <td style={{ padding: 8 }}>
                    {moment(surcharge.createdAt).format('YYYY-MM-DD hh:mm A')}
                  </td>
                  <td style={{ padding: 8 }}>
                    {surcharge.User?.firstName} {surcharge.User?.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ padding: 12, textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              onClick={handleHideSurchargeHistory}
            >
              Close
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ padding: 12 }}>
          <Loader />
        </div>
      );
    }
  };

  useEffect(() => {
    if (carrier.option) {
      const option = {
        ...carrier.option,
        carrierRequirements:
          typeof carrier.option.carrierRequirements === 'string'
            ? JSON.parse(carrier.option.carrierRequirements)
            : carrier.option.carrierRequirements,
        fuelSurchargeExpiry: carrier.option.fuelSurchargeExpiry
          ? dayjs(carrier.option.fuelSurchargeExpiry)
          : undefined,
        itemsPolicy:
          typeof carrier.option.itemsPolicy === 'string'
            ? JSON.parse(carrier.option.itemsPolicy)
            : carrier.option.itemsPolicy,
      };
      form.setFieldsValue(option);
    }
  }, [form, carrier.option]);

  return (
    <div id={id}>
      <PanelHeader
        title={
          <Space>
            <span>Options</span>
            {userRole === UserRole.SUPER_ADMIN && (
              <PermissionsToggle
                defaultValue={carrier.optionPermission}
                submitting={carrierUpdateMutation.isLoading}
                onChange={onTogglePermission}
              />
            )}
          </Space>
        }
      />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={24}>
          <Col>
            <Form.Item label="Fuel Surcharge" name="fuelSurcharge">
              <Input className="carrier-options-panel__fuel-input" suffix="%" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="carrier-options-panel__fuel-input__date"
              label="Expiry"
              name="fuelSurchargeExpiry"
            >
              <DatePicker />
            </Form.Item>
          </Col>
          {carrier.last_surcharge_change && (
            <span style={{ marginLeft: 12, marginTop: 30 }}>
              <Popover
                content={surchargeHistoryContent}
                title="Surcharge History"
                visible={showingSurchargeHistory}
              >
                <Button
                  icon={<ClockCircleOutlined />}
                  shape="circle"
                  style={{ marginRight: 12 }}
                  onClick={handleShowSurchargeHistory}
                />
              </Popover>
              <span style={{ color: '#777' }}>
                Updated on{' '}
                {moment(carrier.last_surcharge_change).format('YYYY-MM-DD')}
              </span>
            </span>
          )}
        </Row>
        <Row gutter={24}>
          <Col>
            <Form.Item label="Ground Clearance">
              <Input.Group compact>
                <Form.Item noStyle name="groundClearance">
                  <Input className="carrier-options-panel__unit-input" />
                </Form.Item>
                <Form.Item noStyle name="groundClearanceUnit">
                  <LengthUnitsSelect />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Height Restriction">
              <Input.Group compact>
                <Form.Item noStyle name="heightRestriction">
                  <Input className="carrier-options-panel__unit-input" />
                </Form.Item>
                <Form.Item noStyle name="heightRestrictionUnit">
                  <LengthUnitsSelect />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Age Restriction" name="ageRestriction">
              <Input prefix=">" style={{ width: 100 }} suffix="years" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          className="carrier-options-panel__items-policy"
          label="Items Policy"
        >
          <Form.List name="itemsPolicy">
            {(policies, { add: addPolicy }) => (
              <Space direction="vertical">
                {policies.map((policy) => (
                  <Space key={policy.key} align="start">
                    <Form.List name={[policy.name, 'items']}>
                      {(policyItems, { add: addItem, remove: removeItem }) => (
                        <Space>
                          {policyItems.map((policyItem) => (
                            <Space key={policyItem.key}>
                              {policyItem.name > 0 && (
                                <Form.Item
                                  noStyle
                                  name={[policyItem.name, 'condition']}
                                >
                                  <ConditionSelect placeholder="or" />
                                </Form.Item>
                              )}

                              <Form.Item
                                noStyle
                                shouldUpdate={(prev, curr) => {
                                  return !isEqual(
                                    prev.itemsPolicy,
                                    curr.itemsPolicy
                                  );
                                }}
                              >
                                {() => (
                                  <Input.Group compact>
                                    <Form.Item
                                      noStyle
                                      fieldKey={[policyItem.fieldKey, 'unit']}
                                      name={[policyItem.name, 'unit']}
                                    >
                                      <WeightUnitsSelect />
                                    </Form.Item>

                                    {['Lbs', 'Kgs'].includes(
                                      getPolicyItemUnit(
                                        policy.name,
                                        policyItem.name
                                      )
                                    ) && (
                                      <Form.Item
                                        noStyle
                                        fieldKey={[
                                          policyItem.fieldKey,
                                          'weight',
                                        ]}
                                        name={[policyItem.name, 'weight']}
                                      >
                                        <Input
                                          placeholder={getPolicyItemUnit(
                                            policy.name,
                                            policyItem.name
                                          ).toLowerCase()}
                                          style={{ width: 50 }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Input.Group>
                                )}
                              </Form.Item>

                              <Form.Item noStyle>
                                <Button
                                  danger
                                  icon={<MinusCircleOutlined />}
                                  type="link"
                                  onClick={() => {
                                    removeItem(policyItem.name);
                                  }}
                                />
                              </Form.Item>
                            </Space>
                          ))}

                          <Button
                            icon={<PlusOutlined />}
                            style={{ verticalAlign: 'bottom' }}
                            type="link" // IMPORTANT: Do not fix me!
                            onClick={() => addItem()}
                          >
                            Add Item
                          </Button>
                        </Space>
                      )}
                    </Form.List>

                    <Form.Item
                      {...policy}
                      noStyle
                      fieldKey={[policy.fieldKey, 'modes']}
                      name={[policy.name, 'modes']}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Modes"
                        style={{ minWidth: 150 }}
                      >
                        {carrier.modes?.map((mode) => (
                          <Select.Option key={mode.id} value={mode.id}>
                            {mode.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {policy.key === policies.length - 1 && (
                      <Form.Item noStyle>
                        <Button
                          icon={<PlusOutlined />}
                          style={{ verticalAlign: 'bottom' }}
                          type="link"
                          onClick={() => addPolicy()}
                        >
                          Create New Rule
                        </Button>
                      </Form.Item>
                    )}
                  </Space>
                ))}
                {!policies.length && (
                  <Form.Item noStyle>
                    <Button
                      icon={<PlusOutlined />}
                      style={{ verticalAlign: 'bottom' }}
                      type="link"
                      onClick={() => addPolicy()}
                    >
                      Create New Rule
                    </Button>
                  </Form.Item>
                )}
              </Space>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Carrier Requirements">
          <Form.List name="carrierRequirements">
            {(items, { add: addRequirement, remove: removeRequirement }) => (
              <>
                {items.map((item) => (
                  <Space key={item.key}>
                    <Form.Item {...item}>
                      <Input />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        danger
                        icon={<MinusCircleOutlined />}
                        type="link"
                        onClick={() => {
                          removeRequirement(item.name);
                        }}
                      />
                    </Form.Item>
                  </Space>
                ))}

                <Form.Item noStyle>
                  <Button
                    icon={<PlusOutlined />}
                    type="link"
                    onClick={() => {
                      addRequirement();
                    }}
                  >
                    Add New Requirement
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={updateMutation.isLoading}
            type="primary"
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CarrierOptionsPanel;
