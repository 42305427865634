import './Toggle.less';

import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import React from 'react';

const Toggle: React.FC<SwitchProps & React.RefAttributes<HTMLElement>> = (
  props
) => {
  return <Switch className="cp-switch" {...props} />;
};

export default Toggle;
