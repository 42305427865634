import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <Spin
        indicator={<LoadingOutlined style={{ color: '#e6f7ff' }} />}
        size="large"
      />
    </LoaderWrapper>
  );
};

export default Loader;
