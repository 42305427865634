import './PathTable.less';

import { Tag, Tooltip } from 'antd';
import React, { Fragment } from 'react';

import { RatePath } from '../../models/Path';

interface IPathTableProps {
  paths: RatePath[][];
}

const getFieldValue = (value?: string) => {
  return value ? value : <>&mdash;&mdash;&mdash;&mdash;</>;
};

const PathTable: React.FC<IPathTableProps> = ({ paths }) => {
  return (
    <table className="path-table">
      <thead>
        <tr>
          <th></th>
          <th>Origin</th>
          <th>Org Type</th>
          <th>Destination</th>
          <th>Dest Type</th>
          <th>Mode</th>
          <th>Items</th>
          <th>Distance (kms)</th>
          <th>Transit</th>
          <th className="text-center">Rate</th>
        </tr>
      </thead>
      {paths.map((path, index) => (
        <Fragment key={index}>
          <tbody className="path-table__body">
            {path.map((ratePath, ratePathIndex) => (
              <tr
                key={ratePathIndex}
                className={ratePath.preferred ? 'preferred' : ''}
              >
                <td>
                  {ratePathIndex === 0
                    ? ratePath.carriers.join(' > ')
                    : ratePath.carrier}
                </td>
                <td>{getFieldValue(ratePath.origin)}</td>
                <td>{getFieldValue(ratePath.shipType)}</td>
                <td>{getFieldValue(ratePath.destination)}</td>
                <td>{getFieldValue(ratePath.destinationShipType)}</td>
                <td>
                  {ratePathIndex === 0
                    ? getFieldValue(ratePath.modes.join(', '))
                    : getFieldValue(ratePath.mode)}
                </td>
                <td>{getFieldValue(ratePath.items)}</td>
                <td>
                  {getFieldValue(
                    ratePath.distance
                      ? Math.ceil(ratePath.distance).toLocaleString()
                      : undefined
                  )}
                </td>
                <td>
                  {getFieldValue(
                    ratePath.transitDays
                      ? ratePath.transitDays + ' days'
                      : undefined
                  )}
                </td>
                <td className="text-center">
                  {ratePath.rate ? (
                    <Tooltip title={ratePath.originalRate?.toFixed(2)}>
                      {ratePathIndex === 0 ? (
                        <Tag color="#358F56">{ratePath.rate.toFixed(2)}</Tag>
                      ) : (
                        ratePath.rate.toFixed(2)
                      )}
                    </Tooltip>
                  ) : (
                    <>&mdash;&mdash;&mdash;&mdash;</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tbody className="path-table__separator">
            <tr>
              <td colSpan={8}></td>
            </tr>
          </tbody>
        </Fragment>
      ))}
    </table>
  );
};

export default PathTable;
