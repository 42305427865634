import { Divider } from 'antd';

import CarrierList from '../components/lists/CarrierList';
import DefaultModeList from '../components/lists/DefaultModeList';
import DefaultVehicleList from '../components/lists/DefaultVehicleList';
import ShipTypeList from '../components/lists/ShipTypeList';
import TerminalList from '../components/lists/TerminalList';
import UserList from '../components/lists/UserList';
import PasswordChangePanel from '../components/panels/PasswordChangePanel';
import {
  useCarriers,
  useCarrierTerminalMap,
  useDefaultModes,
  useDefaultVehicles,
  useShipTypes,
  useUsers,
} from '../hooks/apiHooks';
import { useTerminals } from '../hooks/apiHooks/terminal';
import Layout from '../layout';

const AdminSettingsPage = ({ routes }) => {
  const { allCarriers } = useCarriers();
  const { defaultModes } = useDefaultModes();
  const { defaultVehicles } = useDefaultVehicles();
  const { allShipTypes } = useShipTypes();
  const { allUsers } = useUsers();
  const { allTerminals } = useTerminals();
  const { carrierTerminalMap } = useCarrierTerminalMap();

  return (
    <Layout
      showCarriersNavigation
      disableLoading={false}
      menuIndex={0}
      routes={routes}
    >
      <UserList carriers={allCarriers} id="users" users={allUsers} />
      <Divider />

      <CarrierList carriers={allCarriers} id="carriers" />
      <Divider />

      <TerminalList
        carriers={allCarriers}
        carrierTerminalMap={carrierTerminalMap}
        id="terminals"
        terminals={allTerminals}
      />
      <Divider />

      <DefaultModeList id="modes" modes={defaultModes} />
      <Divider />

      <DefaultVehicleList id="vehicles" vehicles={defaultVehicles} />
      <Divider />

      <ShipTypeList id="shipTypes" shipTypes={allShipTypes} />
      <Divider />

      <PasswordChangePanel id="password" title="Change Admin Password" />
    </Layout>
  );
};

export default AdminSettingsPage;
