import { useMutation, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { Rating } from '../../models/Rating';
import ratingService from '../../services/ratingService';

export const useRatingUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Rating, Error, Rating>(ratingService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            rating: data,
          }
      );
    },
  });
};
