import axios from 'axios';

import { ClassVehicles, Vehicle } from '../models/Vehicle';

const uri = '/vehicle';

const create = async (vehicle: Vehicle) => {
  const { data } = await axios.post<Vehicle>(`${uri}`, vehicle);
  return data;
};

const get = (id: number) => {
  return axios.get<Vehicle>(`${uri}/${id}`);
};

const getAll = async () => {
  const { data } = await axios.get<Vehicle[]>(`${uri}`);
  return data;
};

const getDefault = async () => {
  const { data } = await axios.get<Vehicle[]>(`${uri}/default`);
  return data;
};

const update = async (vehicle: Vehicle) => {
  const { data } = await axios.put<Vehicle>(`${uri}/${vehicle.id}`, vehicle);
  return data;
};

const remove = async (vehicle: Vehicle) => {
  const { data } = await axios.delete(`${uri}/${vehicle.id}`);
  return data;
};

const assignToClass = (classVehicles: ClassVehicles) => {
  return axios.post<void>(`${uri}/assign`, classVehicles);
};

const apis = {
  assignToClass,
  create,
  get,
  getAll,
  getDefault,
  remove,
  update,
};

export default apis;
