import { Button, Col, Form, Modal, Row, Select } from 'antd';
import React, { useCallback } from 'react';

import { useRatesUpdate } from '../../hooks/apiHooks/rate';
import { Rate } from '../../models/Rate';
import { ShipType } from '../../models/ShipType';

interface IProps {
  rates: Rate[];
  shipTypes: ShipType[];
  selectedCarrierId?: number;
  onSubmit: () => void;
  onClose: () => void;
}

const ModeDialog: React.FC<IProps> = ({
  onClose,
  onSubmit,
  rates,
  selectedCarrierId,
  shipTypes,
}) => {
  const { mutate } = useRatesUpdate();

  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  type FormState = {
    originShipTypeId: number;
    destinationShipTypeId: number;
  };

  const submitForm = useCallback(
    (formState: FormState) => {
      const newRates = rates.map((rate) => {
        if (formState.originShipTypeId) {
          rate.shipTypeId = formState.originShipTypeId;
        }
        if (formState.destinationShipTypeId) {
          rate.destinationShipTypeId = formState.destinationShipTypeId;
        }
        return rate;
      });
      mutate([newRates, selectedCarrierId]);
      onSubmit();
    },
    [mutate, onSubmit, rates, selectedCarrierId]
  );

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title={'Bulk Update Ship Types'}
      width={400}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Origin Ship Type" name="originShipTypeId">
              <Select>
                {shipTypes.map((shipType) => (
                  <Select.Option key={shipType.id} value={shipType.id}>
                    {shipType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Destination Ship Type"
              name="destinationShipTypeId"
            >
              <Select>
                {shipTypes.map((shipType) => (
                  <Select.Option key={shipType.id} value={shipType.id}>
                    {shipType.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModeDialog;
