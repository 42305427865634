import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { WEIGHT_UNITS } from '../../models/Constants';

const WeightUnitsSelect: React.FC<SelectProps<any>> = (props) => {
  return (
    <Select style={{ width: 120 }} {...props}>
      {WEIGHT_UNITS.map((unit) => (
        <Select.Option key={unit} value={unit}>
          {unit}
        </Select.Option>
      ))}
    </Select>
  );
};

export default WeightUnitsSelect;
