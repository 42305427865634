import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useCallback } from 'react';

import { Contactnote } from '../../models/Contact';

interface IProps {
  submitting?: boolean;
  error?: Error | null;
  onSubmit: (contactNote: Contactnote) => void;
  onClose: () => void;
}

const NoteModalContact: React.FC<IProps> = ({
  onClose,
  onSubmit,
  submitting,
}) => {
  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitForm = useCallback(
    (note: Contactnote) => {
      onSubmit(note);
    },
    [onSubmit]
  );

  /*
  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);
*/

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title={'Add Note'}
      width={400}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="content">
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NoteModalContact;
