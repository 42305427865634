import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AuthContext } from '../../contexts/authContext';
import { CarrierPermission } from '../../models/Carrier';
import { UserRole } from '../../models/User';
import userService from '../../services/userService';

export const useUsers = () => {
  const { data, status } = useQuery('allUsers', () => userService.getAll());

  return {
    allUsers: data || [],
    status,
  };
};

export const useUserCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(userService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData('allUsers', (users) => [...(users || []), data]);
    },
  });
};

export const useUserUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(userService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        'allUsers',
        (users) =>
          users?.map((user) => (user.id === data.id ? data : user)) || []
      );
    },
  });
};

export const useUserRemove = () => {
  const queryClient = useQueryClient();

  return useMutation(userService.remove, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        'allUsers',
        (users) => users?.filter((user) => user.id !== variables) || []
      );
    },
  });
};

/* permission names: 
      | 'modePermission'
      | 'classPermission'
      | 'optionPermission'
      | 'terminalPermission'
      | 'insurancePermission'
      | 'ratingPermission'
      | 'userPermission'
      | 'notesPermission'
*/
export const useUserPermission = () => {
  const { authUser } = useContext(AuthContext);
  return (carrier, permissionName) => {
    return (
      carrier &&
      (authUser?.role === UserRole.SUPER_ADMIN ||
        (authUser?.role === UserRole.ADMIN &&
          (carrier[permissionName] & CarrierPermission.admin) ===
            CarrierPermission.admin) ||
        (authUser?.role === UserRole.CARRIER &&
          (carrier[permissionName] & CarrierPermission.carrier) ===
            CarrierPermission.carrier))
    );
  };
};
