import { PageHeader } from '@ant-design/pro-layout';
import { Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import PathFilter from '../components/filters/PathFilter';
import PathTable from '../components/tables/PathTable';
import { useSearchPaths } from '../hooks/apiHooks/path';
import Layout from '../layout';

const ShortestPathSearchPage = ({ routes }) => {
  const [filter, setFilter] = useState();
  const { paths, searchingPath } = useSearchPaths(filter);
  const queryClient = useQueryClient();

  const onFilterChange = useCallback(
    (newFilter) => {
      queryClient.invalidateQueries(['paths', filter]);
      setFilter(newFilter);
    },
    [filter, queryClient]
  );

  return (
    <Layout disableLoading showCarriersNavigation menuIndex={0} routes={routes}>
      <PageHeader className="site-page-header" title="All Carriers" />

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <PathFilter
          filter={filter}
          submitting={searchingPath}
          onFilterChange={onFilterChange}
        />

        <PathTable paths={searchingPath ? [] : paths} />
      </Space>
    </Layout>
  );
};

export default ShortestPathSearchPage;
