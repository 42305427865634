import './RateTableEditableCell.less';

import { GlobalOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { keyBy, values } from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import AddressSearchInput from '../common/AddressSearchInput';
import { RateTableContext } from './RateTable';

/*
export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string | string[];
  record?: Rate;
  rowIndex: number;
  children: React.ReactNode;
  align?: 'left' | 'right' | 'center';
}
*/

const TableCell = styled.td`
  &.table-cell-editable {
    padding: 5px 1px !important;
  }
`;

const RateTableEditableCell = ({
  align,
  children,
  dataIndex,
  editing,
  record,
  rowIndex,
  ...restProps
}) => {
  const {
    carrierClassesMap,
    carrierModesMap,
    carrierOptionMap,
    carriers,
    changedRowCarrierId,
    isShowingExtraColumns,
    onShowDestinationMap,
    onShowOriginMap,
    shipTypes,
  } = useContext(RateTableContext);

  const rowCarrierId = useMemo(() => {
    if (changedRowCarrierId && editing) {
      return changedRowCarrierId;
    }
    return record ? record.carrierId : undefined;
  }, [changedRowCarrierId, editing, record]);

  const rateCarrierTerminals = useMemo(() => {
    let matchingCarrier = carriers[rowCarrierId || 0];
    if (matchingCarrier) {
      return keyBy(
        record ? matchingCarrier.terminals : [],
        (terminal) => terminal.id
      );
    }
  }, [carriers, record, rowCarrierId]);

  const isClassColumn = useMemo(
    () => Array.isArray(dataIndex) && dataIndex[0] === 'classRate',
    [dataIndex]
  );

  const modes = useMemo(() => {
    if (!rowCarrierId) return [];
    if (
      carrierModesMap &&
      carrierModesMap[rowCarrierId] &&
      carrierModesMap[rowCarrierId].modes
    ) {
      return (
        carrierModesMap[rowCarrierId].modes.filter((mode) => mode.active) || []
      );
    } else {
      return [];
    }
  }, [carrierModesMap, rowCarrierId]);

  const classes = useMemo(() => {
    if (!rowCarrierId || !carrierClassesMap || !carrierClassesMap[rowCarrierId])
      return [];
    return carrierClassesMap[rowCarrierId].classes || [];
  }, [carrierClassesMap, rowCarrierId]);

  const option = useMemo(() => {
    if (!rowCarrierId || !carrierClassesMap || !carrierClassesMap[rowCarrierId])
      return undefined;
    return carrierOptionMap[rowCarrierId].option;
  }, [carrierClassesMap, carrierOptionMap, rowCarrierId]);

  // editable field types
  const inputNode = useMemo(() => {
    if (dataIndex === 'carrierId') {
      return (
        <Select>
          {values(carriers).map((carrier) => (
            <Select.Option key={carrier.id} value={carrier.id}>
              {carrier.name}
            </Select.Option>
          ))}
        </Select>
      );
    }
    if (dataIndex === 'shipTypeId' || dataIndex === 'destinationShipTypeId') {
      return (
        <Select>
          {shipTypes.map((shipType) => (
            <Select.Option key={shipType.id} value={shipType.id}>
              {shipType.name}
            </Select.Option>
          ))}
        </Select>
      );
    }
    if (dataIndex === 'modeId') {
      return (
        <Select>
          {modes.map(
            (mode) =>
              mode.id !== null && (
                <Select.Option key={mode.id} value={mode.id}>
                  {mode.name}
                </Select.Option>
              )
          )}
        </Select>
      );
    }
    if (dataIndex === 'origin' || dataIndex === 'destination') {
      return <AddressSearchInput />;
    }
    if (dataIndex === 'transitDays') {
      return <InputNumber />;
    }
    if (isClassColumn) {
      const classIndex = Number(dataIndex[1]);
      if (classIndex >= classes.length) {
        return 'N/A';
      }
      return <Input style={{ color: 'blue', textAlign: 'center' }} />;
    }
    return children;
  }, [
    carriers,
    children,
    classes.length,
    dataIndex,
    isClassColumn,
    modes,
    shipTypes,
  ]);

  const staticNode = useMemo(() => {
    if (dataIndex === 'carrierId') {
      return carriers[record.carrierId || 0].name || '';
    }
    if (dataIndex === 'shipTypeId' || dataIndex === 'destinationShipTypeId') {
      const shipType = shipTypes.find(
        (shipType) => record && shipType.id === record[dataIndex]
      ).name;
      if (shipType !== 'Terminal') {
        return shipType;
      }
      let rateTerminals = null;
      if (record) {
        if (dataIndex === 'shipTypeId') {
          rateTerminals = record.originTerminals;
        } else {
          rateTerminals = record.destinationTerminals;
        }
      }

      let terminalsTooltip = '';
      if (rateTerminals) {
        terminalsTooltip = rateTerminals
          .map(
            (t) =>
              rateCarrierTerminals &&
              rateCarrierTerminals[t] &&
              rateCarrierTerminals[t].name
          )
          .filter(Boolean)
          .join(', ');
      }
      return (
        <Tooltip title={terminalsTooltip}>
          {shipType}
          {terminalsTooltip && <div className="terminal-indicator" />}
        </Tooltip>
      );
    }
    if (dataIndex === 'modeId') {
      const recordMode = modes.find((mode) => mode.id === record.modeId);
      return recordMode ? recordMode.name : '';
    }
    if (dataIndex === 'origin') {
      return (
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {record.origin}
          <Button
            icon={<GlobalOutlined />}
            size="small"
            style={{ color: 'green', marginRight: '5px' }}
            type="link"
            onClick={() => onShowOriginMap(record)}
          />
        </div>
      );
    }
    if (dataIndex === 'destination') {
      return (
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {record.destination}
          <Button
            icon={<GlobalOutlined />}
            size="small"
            style={{ color: 'green', marginRight: '5px' }}
            type="link"
            onClick={() => onShowDestinationMap(record)}
          />
        </div>
      );
    }

    if (isClassColumn) {
      let result = '';
      // this used to use the second number as an index to look up the classRates and get the proper key
      // instead we can just get the key directly
      const classIndex = Number(dataIndex[1]);

      if (classIndex >= classes.length) {
        return 'N/A';
      }
      if (!record) {
        return '';
      }
      const classification = classes[classIndex];

      let matchingClassRate = record.classRate[classification.id];
      let rateValue = matchingClassRate.value;

      let lastUpdated = '';
      if (isShowingExtraColumns) {
        lastUpdated = matchingClassRate.updatedAt
          ? new Date(matchingClassRate.updatedAt)
              .toLocaleDateString('en-US')
              .replaceAll('/', '-')
          : '';
      }

      // check if matchingClassRate.updatedAt is more than a year ago
      let isOld = false;
      if (matchingClassRate.updatedAt) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if (new Date(matchingClassRate.updatedAt) < oneYearAgo) {
          isOld = true;
        }
      }

      let opacity = isOld ? 0.5 : 1.0;

      if (
        option &&
        option.fuelSurcharge &&
        rateValue &&
        !record.skipFuelSurcharge
      ) {
        const surchargeAppliedRate =
          rateValue + (rateValue * option.fuelSurcharge) / 100;

        const surchargeAppliedValue = surchargeAppliedRate.toFixed(2);
        let costPerKm = 0;
        if (record.distance && record.distance > 0) {
          costPerKm = surchargeAppliedRate / record.distance;
        }

        const toolTipContents = `$${rateValue.toFixed(2)} (${costPerKm.toFixed(
          3
        )}$/km)`;

        result = (
          <Tooltip title={toolTipContents}>
            <div style={{ opacity: opacity }}>${surchargeAppliedValue}</div>
            <div
              style={{ fontSize: '90%', fontStyle: 'italic', opacity: opacity }}
            >
              {lastUpdated}
            </div>
          </Tooltip>
        );
      } else if (rateValue) {
        // this sort of duplicates the above but it's messy enough to not be worth refactoring to save 4 lines
        let costPerKm = 0;
        if (record.distance && record.distance > 0) {
          costPerKm = rateValue / record.distance;
        }
        const toolTipContents = `${costPerKm.toFixed(3)}$/km`;

        result = (
          <Tooltip title={toolTipContents}>
            <div style={{ opacity: opacity }}>${rateValue.toFixed(2)}</div>
            <div
              style={{ fontSize: '90%', fontStyle: 'italic', opacity: opacity }}
            >
              {lastUpdated}
            </div>
          </Tooltip>
        );
      }
      return result || '';
    }
    return children;
  }, [
    dataIndex,
    isClassColumn,
    children,
    carriers,
    record,
    shipTypes,
    rateCarrierTerminals,
    modes,
    onShowOriginMap,
    onShowDestinationMap,
    classes,
    isShowingExtraColumns,
    option,
  ]);

  const tableCellStyle = useMemo(() => {
    const style = {
      textAlign: align,
    };
    if (editing) {
      style.padding = '5px 1px';
    } else {
      style.padding = '10px 13px';
    }
    if (isClassColumn) {
      style.width = 120;
      style.textAlign = 'center';
    }
    return style;
  }, [align, editing, isClassColumn]);

  const formItemName = useMemo(() => {
    if (isClassColumn) {
      const classIndex = Number(dataIndex[1]);

      if (classIndex >= classes.length) {
        return undefined;
      }

      return [dataIndex[0], classes[classIndex].id, 'value'];
    }
    return dataIndex;
  }, [classes, dataIndex, isClassColumn]);

  return (
    <TableCell {...restProps} style={tableCellStyle}>
      {editing ? (
        <Form.Item name={formItemName} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        staticNode
      )}
    </TableCell>
  );
};

export default RateTableEditableCell;
