import './LoginPage.less';

import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../contexts/authContext';

const LoginPage = () => {
  const { failedLogin, isLoading, signInWithEmail } = useContext(AuthContext);

  const onFinish = (values) => {
    signInWithEmail && signInWithEmail(values);
  };

  const [form] = Form.useForm();

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (failedLogin) {
      form.resetFields();
    }
  }, [failedLogin, form]);

  const domainLogo = () => {
    if (window.location.host === 'www.carshippingrates.ca') {
      return '/assets/images/logo_csr.png';
    } else {
      return '/assets/images/logo_blue.png';
    }
  };

  return (
    <div className="login-page">
      <div className="login-page__header">
        <img alt="Logo" src={domainLogo()} />
      </div>

      <Form
        form={form}
        initialValues={{ remember: true }}
        name="login-form"
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ message: 'Please input your username!', required: true }]}
        >
          <Input placeholder="Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ message: 'Please input your password!', required: true }]}
        >
          <Input.Password placeholder="Password" prefix={<KeyOutlined />} />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        {failedLogin && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            Username / password combination not found.
          </div>
        )}
        <Form.Item {...tailLayout}>
          <Button
            htmlType="submit"
            loading={isLoading}
            style={{ width: '100%' }}
            type="primary"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
