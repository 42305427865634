import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

const getUserPool = () => {
  return new CognitoUserPool({
    ClientId: process.env.REACT_APP_COGNITO_USER_CLIENT_ID || '',
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  });
};

const getCurrentUser = () => {
  const userPool = getUserPool();
  return userPool.getCurrentUser();
};

export type UserSession = {
  username: string;
  tokens: {
    IdToken: string;
    AccessToken: string;
    RefreshToken: string;
  };
};

export type NewPasswordRequired = {
  newPasswordRequired: boolean;
  username: string;
  userAttributes: UserAttributes;
  cognitoUser: CognitoUser;
};

export type AuthenticateProps = {
  username?: string;
  password?: string;
  remember?: boolean;
  oldPassword?: string;
};

export type UserAttributes = {
  [key: string]: string;
};

export const login = (props: AuthenticateProps) => {
  const userPool = new CognitoUserPool({
    ClientId: process.env.REACT_APP_COGNITO_USER_CLIENT_ID || '',
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  });

  return new Promise<UserSession | NewPasswordRequired>((resolve, reject) => {
    const authenticateDetails = new AuthenticationDetails({
      Password: props.password,
      Username: props.username!.toLowerCase(),
    });
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: props.username!.toLowerCase(),
    });

    cognitoUser.authenticateUser(authenticateDetails, {
      newPasswordRequired: (userAttributes) => {
        delete userAttributes.email_verified;

        resolve({
          cognitoUser,
          newPasswordRequired: true,
          userAttributes,
          username: props.username?.toLowerCase(),
        } as NewPasswordRequired);
      },
      onFailure: (err) => {
        reject(err);
      },
      onSuccess: (session) => {
        const userSession: UserSession = {
          tokens: {
            AccessToken: session.getAccessToken().getJwtToken(),
            IdToken: session.getIdToken().getJwtToken(),
            RefreshToken: session.getRefreshToken().getToken(),
          },
          username: cognitoUser.getUsername(),
        };
        resolve(userSession);
      },
    });
  });
};

export const logout = () => {
  const cognitoUser = getCurrentUser();
  cognitoUser?.signOut();
};

export const getUserSessionAttributes = () => {
  return new Promise<[UserSession, UserAttributes]>((resolve, reject) => {
    const cognitoUser = getCurrentUser();
    if (!cognitoUser) {
      reject(new Error("Can't retrieve the current user"));
      return;
    }

    cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err) {
        reject(err);
        return;
      }
      const userSession: UserSession = {
        tokens: {
          AccessToken: session!.getAccessToken().getJwtToken(),
          IdToken: session!.getIdToken().getJwtToken(),
          RefreshToken: session!.getRefreshToken().getToken(),
        },
        username: cognitoUser.getUsername(),
      };

      cognitoUser.getUserAttributes(
        (err?: Error, result?: CognitoUserAttribute[]) => {
          if (err) {
            reject(err);
          }
          const attributes: UserAttributes = {};

          if (result) {
            for (let i = 0; i < result.length; i++) {
              attributes[result[i].getName()] = result[i].getValue();
            }
          }
          resolve([userSession, attributes]);
        }
      );
    });
  });
};

export const resetPassword = (
  props: AuthenticateProps,
  newPasswordRequired: NewPasswordRequired
) => {
  return new Promise<UserSession>((resolve, reject) => {
    newPasswordRequired.cognitoUser.completeNewPasswordChallenge(
      props.password!,
      newPasswordRequired.userAttributes,
      {
        onFailure: (err) => {
          reject(err);
        },
        onSuccess: (session) => {
          const userSession: UserSession = {
            tokens: {
              AccessToken: session.getAccessToken().getJwtToken(),
              IdToken: session.getIdToken().getJwtToken(),
              RefreshToken: session.getRefreshToken().getToken(),
            },
            username: newPasswordRequired.cognitoUser.getUsername(),
          };
          resolve(userSession);
        },
      }
    );
  });
};

export const changePassword = (props: AuthenticateProps) => {
  return new Promise<'SUCCESS' | undefined>((resolve, reject) => {
    const cognitoUser = getCurrentUser();
    if (!cognitoUser) {
      reject(new Error("Can't retrieve the current user"));
      return;
    }

    cognitoUser.getSession((err: Error) => {
      if (err) {
        reject(err);
        return;
      }

      cognitoUser.changePassword(
        props.oldPassword || '',
        props.password || '',
        (err, result) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(result);
        }
      );
    });
  });
};
