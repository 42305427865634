import axios from 'axios';

import { Option } from '../models/Option';

const uri = '/option';

const getAll = async () => {
  const { data } = await axios.get<Option[]>(`${uri}`);
  return data;
};

const update = async (option: Option) => {
  const { data } = await axios.post<Option>(`${uri}`, option);
  return data;
};

const apis = {
  getAll,
  update,
};

export default apis;
