import styled from 'styled-components';

const Container = styled.div`
  .ant-descriptions-item {
    padding-right: 20px;
  }

  .ant-descriptions-item-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      float: right;
    }

    &.ant-descriptions-item-no-colon::after {
      position: absolute;
    }
  }
`;

export default Container;
