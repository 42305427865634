import axios from 'axios';

import { DefaultMode, Mode } from '../models/Mode';

const uri = '/mode';

const create = async (mode: Mode) => {
  const { data } = await axios.post<Mode>(`${uri}`, mode);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<Mode[]>(`${uri}`);
  return data;
};

const update = async (mode: Mode) => {
  const { data } = await axios.put<Mode>(`${uri}/${mode.id}`, mode);
  return data;
};

const remove = async (mode: Mode) => {
  const { data } = await axios.delete(`${uri}/${mode.id}`);
  return data;
};

const getDefault = async () => {
  const { data } = await axios.get<DefaultMode[]>(`${uri}/default`);
  return data;
};

const createDefault = async (mode: DefaultMode) => {
  const { data } = await axios.post<DefaultMode>(`${uri}/default`, mode);
  return data;
};

const updateDefault = async (mode: DefaultMode) => {
  const { data } = await axios.put<DefaultMode>(
    `${uri}/default/${mode.id}`,
    mode
  );
  return data;
};

const removeDefault = async (id: number) => {
  const { data } = await axios.delete<DefaultMode>(`${uri}/default/${id}`);
  return data;
};

const apis = {
  create,
  createDefault,
  getAll,
  getDefault,
  remove,
  removeDefault,
  update,
  updateDefault,
};

export default apis;
