import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { CONDITIONS } from '../../models/Constants';

const ConditionSelect: React.FC<SelectProps<any>> = (props) => {
  return (
    <Select style={{ width: 70 }} {...props}>
      {CONDITIONS.map((condition) => (
        <Select.Option key={condition} value={condition}>
          {condition}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ConditionSelect;
