import { LoadingOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Space, Spin } from 'antd';
import React, { useState } from 'react';

import ContactsTable from '../components/tables/ContactsTable';
import { useContacts } from '../hooks/apiHooks';
import Layout from '../layout';
import ContactPage from './ContactPage';

const ContactsPage = ({ routes }) => {
  const { allContacts, isLoading } = useContacts();
  const [currentContactId, setCurrentContactId] = useState(0);

  const _contactSelected = (contactId) => {
    setCurrentContactId(contactId);
  };

  const _contactDeselected = () => {
    setCurrentContactId(0);
  };

  if (isLoading) {
    return (
      <div style={{ height: '100%', textAlign: 'center' }}>
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 20 }} />} />
      </div>
    );
  } else {
    if (currentContactId > 0) {
      return (
        <Layout
          disableLoading
          menuIndex={0}
          routes={routes}
          showCarriersNavigation={false}
        >
          <PageHeader className="site-page-header" />
          <ContactPage
            contactId={currentContactId}
            goBack={_contactDeselected}
          />
        </Layout>
      );
    } else {
      return (
        <Layout
          disableLoading
          menuIndex={0}
          routes={routes}
          showCarriersNavigation={false}
        >
          <PageHeader className="site-page-header" title="All Contacts" />
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <ContactsTable
              contacts={allContacts}
              loading={isLoading}
              onContactSelect={_contactSelected}
            />
            <p style={{ textAlign: 'right' }}>{allContacts.length} contacts</p>
          </Space>
        </Layout>
      );
    }
  }
};

export default ContactsPage;
