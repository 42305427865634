import './UserList.less';

import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  notification,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import {
  useUserCreate,
  useUserRemove,
  useUserUpdate,
} from '../../hooks/apiHooks';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import UserModal from '../modals/UserModal';

/*
interface IUserListProps {
  id?: string;
  carriers: Carrier[];
  users: User[];
}
*/

const UserList = ({ carriers, id, users }) => {
  const createMutation = useUserCreate();
  const updateMutation = useUserUpdate();
  const removeMutation = useUserRemove();

  const [isEditing, setIsEditing] = useState(false);
  const [editableUser, setEditableUser] = useState();
  const [deletableUser, setDeletableUser] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const onAdd = useCallback(
    (user) => {
      createMutation.mutate(user);
    },
    [createMutation]
  );

  const onUpdate = useCallback(
    (user) => {
      updateMutation.mutate(user);
    },
    [updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableUser) {
      removeMutation.mutate(deletableUser.id);
    }
  }, [deletableUser, removeMutation]);

  const onEdit = useCallback((user) => {
    setEditableUser(user);
    setShowEditModal(true);
  }, []);

  useEffect(() => {
    if (!isEditing) {
      setDeletableUser(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  useEffect(() => {
    if (removeMutation.error) {
      notification.error({
        description: removeMutation.error.message,
        message: 'Failed to delete the user!',
      });
      setDeletableUser(undefined);
    }
  }, [removeMutation.error]);

  return (
    <Container id={id}>
      <Descriptions
        colon={false}
        column={3}
        layout="vertical"
        title={
          <ListHeader>
            <Space direction="horizontal">
              <span>Users</span>
            </Space>

            <Space>
              <EditButton
                color={isEditing ? '#389e0d' : undefined}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Done' : 'Edit'}
              </EditButton>
              <Button
                ghost
                style={{ width: 120 }}
                type="primary"
                onClick={() => setShowAddModal(true)}
              >
                Add User
              </Button>
            </Space>
          </ListHeader>
        }
      >
        {users.map((user, index) => (
          <Descriptions.Item
            key={index}
            label={
              <>
                <Typography.Text strong>
                  {user.firstName} {user.lastName}
                </Typography.Text>
                {isEditing && (
                  <Space>
                    <Button
                      icon={<EditOutlined />}
                      shape="circle"
                      size="small"
                      onClick={() => onEdit(user)}
                    />
                    <Popconfirm
                      okButtonProps={{ loading: removeMutation.isLoading }}
                      placement="leftBottom"
                      title="Are you sure to delete this user?"
                      visible={deletableUser?.id === user.id}
                      onCancel={() => setDeletableUser(undefined)}
                      onConfirm={onDelete}
                    >
                      <Button
                        danger
                        icon={<CloseOutlined />}
                        shape="circle"
                        size="small"
                        onClick={() => setDeletableUser(user)}
                      />
                    </Popconfirm>
                  </Space>
                )}
              </>
            }
          >
            <Space direction="vertical">
              <Typography.Text className="user-list__info">
                {user.email}
              </Typography.Text>
            </Space>
          </Descriptions.Item>
        ))}
        {users.length % 3 === 2 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
        {users.length % 3 === 1 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
      </Descriptions>

      {showAddModal && (
        <UserModal
          carriers={carriers}
          error={createMutation.error}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableUser && (
        <UserModal
          carriers={carriers}
          error={updateMutation.error}
          selectedUser={editableUser}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default UserList;
