import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { Class } from '../../models/Class';
import { ClassVehicles } from '../../models/Vehicle';
import classService from '../../services/classService';

export const useClasses = () => {
  const { data, status } = useQuery('allClasses', () => classService.getAll());

  return {
    allClasses: data?.classes || [],
    classRates: data?.classRates || {},
    status,
  };
};

export const useClassCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Class, Error, Class>(classService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            classes: carrier.classes && [...carrier.classes, data],
          }
      );
    },
  });
};

export const useClassUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Class, Error, Class>(classService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            classes: carrier.classes?.map((c) => (c.id === data.id ? data : c)),
          }
      );
    },
  });
};

export const useClassRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<Class, Error, Class>(classService.remove, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', variable.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            classes: carrier.classes?.filter((c) => c.id !== variable.id),
          }
      );
    },
  });
};

export const useClassVehicleMap = () => {
  const { data, status } = useQuery('classVehicleMap', () =>
    classService.getClassVehicleMap()
  );

  return {
    classVehicleMap: data || [],
    status,
  };
};

export const useClassVehicleMapUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<ClassVehicles, Error, ClassVehicles>(
    classService.updateClassVehicleMap,
    {
      onSuccess: (data, variable) => {
        queryClient.setQueryData<ClassVehicles | undefined>(
          'classVehicleMap',
          (map?: ClassVehicles) => variable
        );
      },
    }
  );
};
