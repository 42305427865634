import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { Vehicle } from '../../models/Vehicle';
import vehicleService from '../../services/vehicleService';

export const useDefaultVehicles = () => {
  const { data, status } = useQuery('defaultVehicles', () =>
    vehicleService.getDefault()
  );

  return {
    defaultVehicles: data || [],
    status,
  };
};

export const useVehicleCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Vehicle, Error, Vehicle>(vehicleService.create, {
    onSuccess: (data) => {
      if (data.default) {
        queryClient.setQueryData<Vehicle[]>(
          'defaultVehicles',
          (vehicles: Vehicle[] | undefined) => [...(vehicles || []), data]
        );
      } else {
        queryClient.setQueryData<Carrier | undefined>(
          ['carrier', data.carrierId],
          (carrier?: Carrier) =>
            carrier && {
              ...carrier,
              vehicles: carrier.vehicles && [...carrier.vehicles, data],
            }
        );
      }
    },
  });
};

export const useVehicleUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Vehicle, Error, Vehicle>(vehicleService.update, {
    onSuccess: (data) => {
      if (data.default) {
        queryClient.setQueryData<Vehicle[]>(
          'defaultVehicles',
          (vehicles: Vehicle[] | undefined) =>
            vehicles?.map((vehicle) =>
              vehicle.id === data.id ? data : vehicle
            ) || []
        );
      } else {
        queryClient.setQueryData<Carrier | undefined>(
          ['carrier', data.carrierId],
          (carrier?: Carrier) =>
            carrier && {
              ...carrier,
              vehicles: carrier.vehicles?.map((vehicle) =>
                vehicle.id === data.id ? data : vehicle
              ),
            }
        );
      }
    },
  });
};

export const useVehicleRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<Vehicle, Error, Vehicle>(vehicleService.remove, {
    onSuccess: (data, variables) => {
      if (variables.default) {
        queryClient.setQueryData<Vehicle[]>(
          'defaultVehicles',
          (vehicles: Vehicle[] | undefined) =>
            vehicles?.filter((vehicle) => vehicle.id !== variables.id) || []
        );
      } else {
        queryClient.setQueryData<Carrier | undefined>(
          ['carrier', variables.carrierId],
          (carrier?: Carrier) =>
            carrier && {
              ...carrier,
              vehicles:
                carrier.vehicles?.filter(
                  (vehicle) => vehicle.id !== variables.id
                ) || [],
            }
        );
      }
    },
  });
};
