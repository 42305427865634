export const PROVINCES = [
  {
    text: 'Alberta',
    value: 'AB',
  },
  {
    text: 'British Columbia',
    value: 'BC',
  },
  {
    text: 'Manitoba',
    value: 'MB',
  },
  {
    text: 'New Brunswick',
    value: 'NB',
  },
  {
    text: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    text: 'Nova Scotia',
    value: 'NS',
  },
  {
    text: 'Ontario',
    value: 'ON',
  },
  {
    text: 'Prince Edward Island',
    value: 'PE',
  },
  {
    text: 'Quebec',
    value: 'QC',
  },
  {
    text: 'Saskatchewan',
    value: 'SK',
  },
  {
    text: 'Northwest Territories',
    value: 'NT',
  },
  {
    text: 'Nunavut',
    value: 'NU',
  },
  {
    text: 'Yukon',
    value: 'YT',
  },
];

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const LENGTH_UNITS = ['mm', 'cm', 'in', 'ft'];

export const WEIGHT_UNITS = ['Lbs', 'Kgs', 'Tires', 'Baby Seats'];

export const CONDITIONS = ['or', 'and'];
