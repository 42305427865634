import {
  DeleteOutlined,
  GlobalOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';

import NoteModalContact from '../components/modals/NoteModalContact';
import { AuthContext } from '../contexts/authContext';
import { useContact } from '../hooks/apiHooks';
import { useCreateNote, useDeleteNote } from '../hooks/apiHooks';
import { Contact, Contactnote } from '../models/Contact';

interface iContactPageProps {
  contactId: number;
  goBack: Function;
}

const ContactPage: React.FC<iContactPageProps> = ({ contactId, goBack }) => {
  const [isShowingNoteModal, setIsShowingNoteModal] = useState(false);

  const { contact, isLoading } = useContact(contactId);
  const createNoteMutation = useCreateNote();
  const deleteNoteMutation = useDeleteNote();

  const { authUser } = useContext(AuthContext);

  const showNoteModal = () => {
    setIsShowingNoteModal(true);
  };

  const handleNoteSubmit = async (note: Contactnote) => {
    if (contact) {
      note.contactId = contact.id;
      if (authUser) {
        note.author = authUser;
      }
      // author is officially set on the server side but for display we can add current user here
      createNoteMutation.mutate(note);
      contact.notes?.unshift(note);
      setIsShowingNoteModal(false);
    }
  };

  const handleNoteDelete = async (noteId: number) => {
    deleteNoteMutation.mutate(noteId);
    if (contact && contact.notes) {
      // delete note with matching ID from list
      let newNotes = contact.notes.filter(
        (note: Contactnote) => note.id !== noteId
      );
      contact.notes = newNotes;
    }
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: 60, textAlign: 'center' }}>
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 20 }} />} />
      </div>
    );
  } else if (contact != null) {
    const currentContact: Contact = contact;
    const primaryEmailLink = `mailto:${currentContact.primary_email}`;
    const secondaryEmailLink = `mailto:${currentContact.secondary_email}`;

    const primaryPhoneLink = `tel:${currentContact.primary_phone}`;
    const secondaryPhoneLink = `tel:${currentContact.secondary_phone}`;

    return (
      <>
        <Button onClick={() => goBack()}>&larr; Back to Contacts</Button>
        <h1>{currentContact.name}</h1>
        <Row gutter={16}>
          <Col span={16}>
            <Card style={{ marginTop: 16 }} title="Contact Details">
              <Row gutter={12}>
                <Col span={12}>
                  <Row gutter={8}>
                    <Col>
                      <MailOutlined />
                    </Col>
                    <Col>
                      <a href={primaryEmailLink}>
                        {currentContact.primary_email}
                      </a>
                      <br />
                      {currentContact.secondary_email && (
                        <a href={secondaryEmailLink}>
                          {currentContact.secondary_email}
                        </a>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col>
                      <PhoneOutlined />
                    </Col>
                    <Col>
                      <a href={primaryPhoneLink}>
                        {currentContact.primary_phone}
                      </a>
                      <br />
                      {currentContact.secondary_phone && (
                        <a href={secondaryPhoneLink}>
                          {currentContact.secondary_phone}
                        </a>
                      )}
                    </Col>
                  </Row>
                  {currentContact.web && (
                    <Row gutter={8}>
                      <Col>
                        <GlobalOutlined />
                      </Col>
                      <Col>
                        <a href={currentContact.web}>{currentContact.web}</a>
                        <br />
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={12}>
                  {currentContact.carriers &&
                    currentContact.carriers?.length > 0 && <h3>Carrier</h3>}
                  {currentContact.carriers?.map((nextCarrier) => (
                    <div key={nextCarrier.id} style={{ marginBottom: 16 }}>
                      <b>{nextCarrier.name}</b>
                      <br />
                      {nextCarrier.address1} {nextCarrier.address2}
                      <br />
                      {nextCarrier.city}, {nextCarrier.state}
                      <br />
                      {nextCarrier.zipCode}
                    </div>
                  ))}
                  {currentContact.terminals &&
                    currentContact.terminals?.length > 0 && <h3>Terminal</h3>}
                  {currentContact.terminals?.map((nextTerminal) => (
                    <div key={nextTerminal.id}>
                      <b>{nextTerminal.name}</b>
                      <br />
                      {nextTerminal.address1} {nextTerminal.address2}
                      <br />
                      {nextTerminal.city}, {nextTerminal.state}
                      <br />
                      {nextTerminal.zipCode}
                    </div>
                  ))}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              bodyStyle={{ maxHeight: 300, overflow: 'scroll' }}
              extra={
                <Button size="small" onClick={showNoteModal}>
                  Add
                </Button>
              }
              style={{ marginTop: 16 }}
              title="Notes"
            >
              {currentContact.notes?.map((nextNote) => {
                const nextName = nextNote.author
                  ? `${nextNote.author.firstName} ${nextNote.author.lastName}`
                  : '(Author)';
                let timeString = (
                  nextNote.createdAt || new Date().toString()
                ).toString();

                let newTimeString =
                  moment(timeString).format('YYYY-MM-DD HH:mm');

                const formattedTime = newTimeString;

                return (
                  <div
                    key={nextNote.id || 'new'}
                    style={{ borderBottom: 'solid 1px grey', margin: 12 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ flex: 3 }}>
                        <b>{nextName}</b>
                        <span style={{ color: 'grey' }}> {formattedTime} </span>
                      </div>
                      <div style={{ flex: 1, textAlign: 'right' }}>
                        <Button
                          icon={<DeleteOutlined />}
                          shape="circle"
                          size="small"
                          onClick={() => handleNoteDelete(nextNote.id)}
                        />
                      </div>
                    </div>
                    <p>{nextNote.content}</p>
                  </div>
                );
              })}
            </Card>
          </Col>
        </Row>

        {isShowingNoteModal && (
          <NoteModalContact
            onClose={() => setIsShowingNoteModal(false)}
            onSubmit={handleNoteSubmit}
          />
        )}
      </>
    );
  } else {
    return <h1>Trouble loading contact</h1>;
  }
};

export default ContactPage;
