import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { Vehicle } from '../../models/Vehicle';
interface IProps {
  selectedVehicle?: Vehicle;
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (vehicle: Vehicle) => void;
}

const VehicleModal: React.FC<IProps> = ({
  error,
  onClose,
  onSubmit,
  selectedVehicle,
  submitting,
}) => {
  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (vehicle: Vehicle) => {
      if (selectedVehicle) {
        vehicle.id = selectedVehicle.id;
      }
      onSubmit(vehicle);
    },
    [onSubmit, selectedVehicle]
  );

  useEffect(() => {
    if (selectedVehicle) {
      form.setFieldsValue(selectedVehicle);
    }
  }, [form, selectedVehicle]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title={!selectedVehicle ? 'Add Type' : 'Update Type'}
      width={400}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Vehicle Type Name"
              name="name"
              rules={[{ message: 'Vehicle name is required!', required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VehicleModal;
