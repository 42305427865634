import './CarrierInsurancePanel.less';

import { FilePdfOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { uniqueId } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useCarrierUpdate, useInsuranceUpdate } from '../../hooks/apiHooks';
import { UserRole } from '../../models/User';
import fileService from '../../services/fileService';
import PanelHeader from '../common/PanelHeader';
import PermissionsToggle from '../common/PermissionsToggle';

/*
interface IProps {
  id: string;
  carrier: Carrier;
  userRole?: UserRole;
}
*/

const toLocaleNumber = (value) => {
  return (value.toString() || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const CarrierInsurancePanel = ({ carrier, id, userRole }) => {
  const updateMutation = useInsuranceUpdate();
  const carrierUpdateMutation = useCarrierUpdate();

  const [form] = Form.useForm();
  const [insuranceFile, setInsuranceFile] = useState();

  const onSubmit = useCallback(
    (insurance) => {
      insurance.carrierId = carrier.id;
      if (carrier.insurance?.id) {
        insurance.id = carrier.insurance?.id;
      }

      updateMutation.mutate(insurance);
    },
    [carrier.id, carrier.insurance?.id, updateMutation]
  );

  const uploadProps = useMemo(
    () => ({
      accept: '.pdf',
      customRequest: ({ file, onError, onSuccess }) => {
        const rcFile = file;
        const extension = rcFile.name.split('.').pop() || '';
        const key = `insurance/${uuid()}.${extension}`;
        const contentType = rcFile.type;

        fileService
          .getSignedUrl({ contentType, key })
          .then((res) => res.data)
          .then((url) => {
            fileService
              .uploadFile(url, file, contentType)
              .then((response) => {
                const staticFileUrl = `${process.env.REACT_APP_STATIC_URL}/${key}`;
                const responseFile = {
                  ...rcFile,
                  url: staticFileUrl,
                };
                if (carrier.insurance) {
                  carrier.insurance.link = staticFileUrl;
                  carrier.insurance.uploadedAt = new Date().toISOString();
                  onSubmit(carrier.insurance);
                } else {
                  const newInsurance = {
                    carrierId: 0,
                    id: 0,
                    link: staticFileUrl,
                    uploadedAt: new Date().toISOString(),
                  };
                  onSubmit(newInsurance);
                }
                if (onSuccess) {
                  onSuccess(response, responseFile);
                }
              })
              .catch((err) => {
                if (onError) {
                  onError(err);
                }
              });
          });
      },
      fileList: insuranceFile ? [insuranceFile] : [],
      itemRender: (originNode, file) => {
        if (file.status === 'uploading' || file.status === 'error') {
          return originNode;
        }
        if ((file.status === 'done' && file.url) || carrier.insurance?.link) {
          return (
            <div className="carrier-insurance-panel__insurance-file-info">
              <FilePdfOutlined />
              <a
                href={
                  file.status === 'done' ? file.url : carrier.insurance?.link
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                {file.status === 'done' ? file.name : carrier.insurance?.link}
              </a>
              <span>
                {file.status === 'done'
                  ? file.lastModifiedDate?.toLocaleDateString()
                  : new Date(
                      carrier.insurance?.uploadedAt || ''
                    ).toLocaleDateString()}
              </span>
            </div>
          );
        }
      },
      onChange: (info) => {
        if (info.fileList.length) {
          const lastUploadedFile = info.fileList[info.fileList.length - 1];
          if (lastUploadedFile.status === 'done') {
            setInsuranceFile({
              ...info.fileList[0],
              name: info.fileList[0].xhr.url,
              url: info.fileList[0].xhr.url,
            });
          } else {
            setInsuranceFile(lastUploadedFile);
          }
        }
      },
      showUploadList: {
        showRemoveIcon: false,
      },
    }),
    [carrier.insurance, insuranceFile, onSubmit]
  );

  const handleSubmit = useCallback(
    (formData) => {
      const newInsurance = {
        ...formData,
        automobileDeductible: parseInt(formData.automobileDeductible),
        automobileValue: parseInt(formData.automobileValue),
        cargoDeductible: parseInt(formData.cargoDeductible),
        cargoValue: parseInt(formData.cargoValue),
        commercialGeneralDeductible: parseInt(
          formData.commercialGeneralDeductible
        ),
        commercialGeneralValue: parseInt(formData.commercialGeneralValue),
        garageDeductible: parseInt(formData.garageDeductible),
        garageValue: parseInt(formData.garageValue),
      };
      onSubmit(newInsurance);
    },
    [onSubmit]
  );

  const onTogglePermission = useCallback(
    (permission) => {
      const newCarrier = {
        ...carrier,
        insurancePermission: permission,
      };
      carrierUpdateMutation.mutate(newCarrier);
    },
    [carrier, carrierUpdateMutation]
  );

  useEffect(() => {
    if (carrier.insurance) {
      form.setFieldsValue({
        ...carrier.insurance,
        automobileExpiry: carrier.insurance.automobileExpiry
          ? dayjs(carrier.insurance.automobileExpiry)
          : undefined,
        cargoExpiry: carrier.insurance.cargoExpiry
          ? dayjs(carrier.insurance.cargoExpiry)
          : undefined,
        commercialGeneralExpiry: carrier.insurance.commercialGeneralExpiry
          ? dayjs(carrier.insurance.commercialGeneralExpiry)
          : undefined,
        garageExpiry: carrier.insurance.garageExpiry
          ? dayjs(carrier.insurance.garageExpiry)
          : undefined,
      });
      if (carrier.insurance.link) {
        setInsuranceFile({
          name: carrier.insurance.link,
          size: 0,
          type: 'application/pdf',
          uid: uniqueId('rc-upload-'),
        });
      }
    }
  }, [form, carrier.insurance]);

  return (
    <div id={id}>
      <PanelHeader
        title={
          <Space>
            <span>Insurance</span>
            {userRole === UserRole.SUPER_ADMIN && (
              <PermissionsToggle
                defaultValue={carrier.insurancePermission}
                submitting={carrierUpdateMutation.isLoading}
                onChange={onTogglePermission}
              />
            )}
          </Space>
        }
      />
      <Upload
        className="carrier-insurance-panel__upload-section"
        {...uploadProps}
      >
        <Button>{!insuranceFile ? 'Upload' : 'Replace'}</Button>
      </Upload>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <h4>Commercial General</h4>
        <Row gutter={12}>
          <Col span={3}>
            <Form.Item label="Value" name="commercialGeneralValue">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Deductible" name="commercialGeneralDeductible">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Expiry" name="commercialGeneralExpiry">
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Insurer" name="commercialGeneralInsurer">
              <Input />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label="Notes" name="commercialGeneralNotes">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h4>Automobile</h4>
        <Row gutter={12}>
          <Col span={3}>
            <Form.Item label="Value" name="automobileValue">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Deductible" name="automobileDeductible">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Expiry" name="automobileExpiry">
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Insurer" name="automobileInsurer">
              <Input />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label="Notes" name="automobileNotes">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h4>Cargo</h4>
        <Row gutter={12}>
          <Col span={3}>
            <Form.Item label="Value" name="cargoValue">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Deductible" name="cargoDeductible">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Expiry" name="cargoExpiry">
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Insurer" name="cargoInsurer">
              <Input />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label="Notes" name="cargoNotes">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h4>Garage</h4>
        <Row gutter={12}>
          <Col span={3}>
            <Form.Item label="Value" name="garageValue">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Deductible" name="garageDeductible">
              <InputNumber
                className="carrier-insurance-panel__input-number"
                formatter={toLocaleNumber}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Expiry" name="garageExpiry">
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Insurer" name="garageInsurer">
              <Input />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Form.Item label="Notes" name="garageNotes">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={updateMutation.isLoading}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CarrierInsurancePanel;
