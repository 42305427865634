import './AddRate.less';

import Icon from '@ant-design/icons';
import { Button, Card, Form, Input, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRateCreate } from '../hooks/apiHooks/rate';
import { getInAddressTerminals } from '../library/util';
import { Carrier } from '../models/Carrier';
import { Rate } from '../models/Rate';
import { ShipType } from '../models/ShipType';
import { AddressType } from '../models/Terminal';
import AddressSearchInput from './common/AddressSearchInput';
import ButtonToggle from './common/ButtonToggle';
import AddBusinessSvg from './icons/AddBusinessSvg';
import FuelSurchargeSvg from './icons/FuelSurchargeSvg';
import SettingsEthernetSvg from './icons/SettingsEthernetSvg';
import OrgDestTerminalAssignModal from './modals/OrgDestTerminalAssignModal';

interface IProps {
  showAllCarriers?: boolean;
  carriers?: Carrier[];
  selectedCarrier?: Carrier;
  shipTypes: ShipType[];
}

const AddRate: React.FC<IProps> = ({
  carriers,
  selectedCarrier,
  shipTypes,
  showAllCarriers,
}) => {
  const createMutation = useRateCreate();
  const [form] = Form.useForm();
  const [currentCarrier, setCurrentCarrier] =
    useState<Carrier | undefined>(selectedCarrier);
  const [isTerminalAssignOpened, setIsTerminalAssignOpened] =
    useState<AddressType | null>(null);
  const [originTerminals, setOriginTerminals] = useState<Array<number>>();
  const [destinationTerminals, setDestinationTerminals] =
    useState<Array<number>>();
  const [currentOrigin, setCurrentOrigin] = useState<string>('');
  const [currentDestination, setCurrentDestination] = useState<string>('');

  const onSubmit = useCallback(
    (input: Rate) => {
      if (currentCarrier) {
        input.carrierId = currentCarrier.id;
      }
      input.active = true;
      input.originTerminals = originTerminals;
      input.destinationTerminals = destinationTerminals;
      createMutation.mutate(input);
    },
    [createMutation, currentCarrier, destinationTerminals, originTerminals]
  );

  const onFieldsChange = useCallback(
    (changedFields: any) => {
      if (changedFields.carrierId) {
        setCurrentCarrier(
          carriers?.find((c) => c.id === changedFields.carrierId)
        );
      }
      if (changedFields.origin) {
        setCurrentOrigin(changedFields.origin);
      }
      if (changedFields.destination) {
        setCurrentDestination(changedFields.destination);
      }
    },
    [carriers]
  );

  const onTerminalsAssign = useCallback(
    (type: AddressType) => {
      const origin = form.getFieldValue('origin');
      const destination = form.getFieldValue('destination');

      if (
        !currentCarrier ||
        (type === 'origin' && !origin) ||
        (type === 'destination' && !destination)
      ) {
        return;
      }

      setIsTerminalAssignOpened(type);
    },
    [currentCarrier, form]
  );

  const getShipTypesSelect = useCallback(
    (type: AddressType) => {
      let loc = '';
      if (type === 'origin') {
        loc = currentOrigin;
      } else {
        loc = currentDestination;
      }
      let currentTerminals = getInAddressTerminals(
        currentCarrier?.terminals,
        loc
      );
      return (
        <Select optionLabelProp="label" style={{ width: 150 }}>
          {shipTypes.map((shipType) => (
            <React.Fragment key={shipType.id}>
              <Select.Option
                key={shipType.id}
                label={shipType.name}
                value={shipType.id}
              >
                <div className="add-rate__ship-type">
                  <span>{shipType.name}</span>
                  {shipType.name === 'Terminal' &&
                    currentTerminals.length > 0 && (
                      <Button
                        icon={<Icon component={AddBusinessSvg} />}
                        type="link"
                        onClick={() => onTerminalsAssign(type)}
                      />
                    )}
                </div>
              </Select.Option>
            </React.Fragment>
          ))}
        </Select>
      );
    },
    [
      onTerminalsAssign,
      currentCarrier,
      shipTypes,
      currentOrigin,
      currentDestination,
    ]
  );

  const handleTerminalsAssign = useCallback(
    (terminals: Array<number>) => {
      if (isTerminalAssignOpened === 'origin') {
        setOriginTerminals(terminals);
      } else {
        setDestinationTerminals(terminals);
      }
      setIsTerminalAssignOpened(null);
    },
    [isTerminalAssignOpened]
  );

  useEffect(() => {
    setCurrentCarrier(selectedCarrier);
  }, [selectedCarrier]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      form.resetFields();
      setOriginTerminals(undefined);
      setDestinationTerminals(undefined);
    }
  }, [createMutation.isSuccess, form]);

  const availableTerminals = useMemo(() => {
    if (!isTerminalAssignOpened) {
      return [];
    }
    return getInAddressTerminals(
      currentCarrier?.terminals,
      form.getFieldValue(isTerminalAssignOpened)
    );
  }, [currentCarrier?.terminals, form, isTerminalAssignOpened]);

  return (
    <>
      <Form
        className="add-rate"
        form={form}
        onFinish={onSubmit}
        onValuesChange={onFieldsChange}
      >
        <Card style={{ marginTop: 16 }} title="Add Rate" type="inner">
          <table>
            <thead>
              <tr>
                {showAllCarriers && <th>Carrier</th>}
                <th>Origin</th>
                <th>Org Type</th>
                <th>Destination</th>
                <th>Dest Type</th>
                {currentCarrier && (
                  <>
                    <th>Mode</th>
                    {currentCarrier.classes?.map((classification) => (
                      <th key={classification.id}>{classification.name}</th>
                    ))}
                  </>
                )}
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {showAllCarriers && (
                  <td>
                    <Form.Item
                      name="carrierId"
                      rules={[{ message: 'Missing Field', required: true }]}
                    >
                      <Select style={{ width: 150 }}>
                        {carriers?.map((carrier) => (
                          <Select.Option key={carrier.id} value={carrier.id}>
                            {carrier.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                )}
                <td>
                  <Form.Item
                    name="origin"
                    rules={[{ message: 'Missing Field', required: true }]}
                  >
                    <AddressSearchInput />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item
                    name="shipTypeId"
                    rules={[{ message: 'Missing Field', required: true }]}
                  >
                    {getShipTypesSelect('origin')}
                  </Form.Item>
                </td>
                <td>
                  <Form.Item
                    name="destination"
                    rules={[{ message: 'Missing Field', required: true }]}
                  >
                    <AddressSearchInput />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item
                    name="destinationShipTypeId"
                    rules={[{ message: 'Missing Field', required: true }]}
                  >
                    {getShipTypesSelect('destination')}
                  </Form.Item>
                </td>
                {currentCarrier && (
                  <>
                    <td>
                      <Form.Item
                        name="modeId"
                        rules={[{ message: 'Missing Field', required: true }]}
                      >
                        <Select style={{ width: 150 }}>
                          {currentCarrier.modes
                            ?.filter((mode) => mode.active)
                            .map((mode) => (
                              <Select.Option key={mode.id} value={mode.id}>
                                {mode.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </td>
                    {currentCarrier.classes?.map((classification) => (
                      <td key={classification.id}>
                        <Form.Item
                          name={['classRate', classification.id.toString()]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                    ))}
                  </>
                )}
                <td>
                  <Form.Item name="transitional">
                    <ButtonToggle
                      icon={<Icon component={SettingsEthernetSvg} />}
                      tooltip="Transitional"
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item name="skipFuelSurcharge">
                    <ButtonToggle
                      icon={<Icon component={FuelSurchargeSvg} />}
                      tooltip="Skip Fuel Surcharge"
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      loading={createMutation.isLoading}
                      type="primary"
                    >
                      Insert
                    </Button>
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </Form>

      {isTerminalAssignOpened && availableTerminals.length > 0 && (
        <OrgDestTerminalAssignModal
          terminals={availableTerminals}
          type={isTerminalAssignOpened}
          onClose={() => setIsTerminalAssignOpened(null)}
          onSubmit={handleTerminalsAssign}
        />
      )}
    </>
  );
};

export default AddRate;
