import './PathFilter.less';

import { Button, Form, Input, Select, Space } from 'antd';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';

import { useMakes, useModels, useYears } from '../../hooks/apiHooks/shipCars';
import { useVIN } from '../../hooks/apiHooks/vinaudit';
import { getCapitalizedSentence } from '../../library/util';
import { PathSearchFilter } from '../../models/Path';
import AddressSearchInput from '../common/AddressSearchInput';
import PanelHeader from '../common/PanelHeader';

interface IPathFilterProps {
  filter?: PathSearchFilter;
  submitting?: boolean;
  onFilterChange: (filter?: PathSearchFilter) => void;
}

const PathFilter: React.FC<IPathFilterProps> = memo(
  ({ filter, onFilterChange, submitting }) => {
    const [form] = Form.useForm();
    const [year, setYear] = useState<number>();
    const [make, setMake] = useState<string>();
    const [vin, setVin] = useState<string>();
    const { isLoading: isYearsLoading, years } = useYears();
    const { isLoading: isMakesLoading, makes } = useMakes(year);
    const { isLoading: isModelsLoading, models } = useModels(year, make);
    const { isLoading: isVinLoading, vinaudit } = useVIN(vin);

    const onClear = useCallback(() => {
      form.resetFields();
      onFilterChange();
    }, [form, onFilterChange]);

    useEffect(() => {
      const formValues = {
        ...(filter || {}),
        make: filter?.make ? filter.make : '',
        model: filter?.model ? filter.model : '',
        type: filter?.type ? filter.type : '',
        year: filter?.year ? filter.year : 0,
      };
      form.setFieldsValue(formValues);
    }, [filter, form]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onVinChanged = useCallback(
      debounce(() => {
        setVin(form.getFieldValue('vin'));
      }, 500),
      [form]
    );

    const onFieldsChange = useCallback(
      (changedFields: any) => {
        if (changedFields[0].name[0] === 'year') {
          setYear(changedFields[0].value ? changedFields[0].value : undefined);
          form.setFieldsValue({
            make: '',
            model: '',
            type: '',
            vin: '',
          });
        }

        if (changedFields[0].name[0] === 'make') {
          setMake(changedFields[0].value);
          form.setFieldsValue({
            model: '',
            type: '',
            vin: '',
          });
        }

        if (changedFields[0].name[0] === 'model') {
          form.setFieldsValue({
            type: getCapitalizedSentence(
              models.find((m) => m.model === changedFields[0].value)?.type
            ),
            vin: '',
          });
        }

        if (changedFields[0].name[0] === 'vin') {
          onVinChanged();
        }
      },
      [form, models, onVinChanged]
    );

    useEffect(() => {
      form.setFieldsValue({
        make: vinaudit?.attributes.make || '',
        model: vinaudit?.attributes.model || '',
        type: vinaudit?.attributes.type || '',
        year: vinaudit?.attributes.year || 0,
      });
    }, [form, vinaudit]);

    return (
      <div className="path-filter">
        <PanelHeader title="Search: Shortest Path" />

        <Form
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={onFilterChange}
        >
          <div className="path-filter__form">
            <table>
              <thead>
                <tr>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th></th>
                  <th>Year</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Type</th>
                  <th></th>
                  <th>VIN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Item
                      name="origin"
                      rules={[{ message: 'Missing Field', required: true }]}
                    >
                      <AddressSearchInput />
                    </Form.Item>
                  </td>

                  <td>
                    <Form.Item
                      name="destination"
                      rules={[{ message: 'Missing Field', required: true }]}
                    >
                      <AddressSearchInput />
                    </Form.Item>
                  </td>

                  <td style={{ width: '20px' }}>&nbsp;</td>
                  <td>
                    <Form.Item name="year">
                      <Select
                        disabled={isYearsLoading}
                        loading={isYearsLoading || isVinLoading}
                        style={{ width: '90px' }}
                      >
                        <Select.Option key={0} value={0}>
                          &mdash;&mdash;&mdash;&mdash;
                        </Select.Option>
                        {years.map((year) => (
                          <Select.Option key={year.year} value={year.year}>
                            {year.year}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name="make">
                      <Select
                        disabled={isMakesLoading}
                        loading={isMakesLoading || isVinLoading}
                        style={{ minWidth: '120px' }}
                      >
                        <Select.Option value="">
                          &mdash;&mdash;&mdash;&mdash;
                        </Select.Option>
                        {makes.map((make) => (
                          <Select.Option key={make.make} value={make.make}>
                            {make.make}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name="model">
                      <Select
                        disabled={isMakesLoading || isModelsLoading}
                        loading={
                          isMakesLoading || isModelsLoading || isVinLoading
                        }
                        style={{ minWidth: '220px' }}
                      >
                        <Select.Option value="">
                          &mdash;&mdash;&mdash;&mdash;
                        </Select.Option>
                        {models.map((model) => (
                          <Select.Option key={model.model} value={model.model}>
                            {model.model}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name="type">
                      <Input disabled style={{ minWidth: '120px' }} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item>
                      <span>&nbsp;or&nbsp;</span>
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item name="vin">
                      <Input />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>

            <Space size="small">
              <Form.Item>
                <Button htmlType="button" type="default" onClick={onClear}>
                  Clear
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={submitting}
                  htmlType="submit"
                  loading={submitting}
                  type="primary"
                >
                  Gather Rates
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Form>
      </div>
    );
  }
);

export default PathFilter;
