import axios from 'axios';

import { Rating } from '../models/Rating';

const uri = '/rating';

const update = async (rating: Rating) => {
  const { data } = await axios.post<Rating>(`${uri}`, rating);
  return data;
};

const apis = {
  update,
};

export default apis;
