import { useMutation, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { Option } from '../../models/Option';
import optionService from '../../services/optionService';

export const useOptionUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Option, Error, Option>(optionService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            option: data,
          }
      );
    },
  });
};
