import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ShipType } from '../../models/ShipType';
import shipTypeService from '../../services/shipTypeService';

export const useShipTypes = () => {
  const { data: allShipTypes, status } = useQuery('allShipTypes', () =>
    shipTypeService.getAll()
  );

  return {
    allShipTypes: allShipTypes || [],
    status,
  };
};

export const useShipTypeCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<ShipType, Error, ShipType>(shipTypeService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData<ShipType[]>(
        'allShipTypes',
        (shipTypes: ShipType[] | undefined) => [...(shipTypes || []), data]
      );
    },
  });
};

export const useShipTypeUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<ShipType, Error, ShipType>(shipTypeService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<ShipType[]>(
        'allShipTypes',
        (shipTypes: ShipType[] | undefined) =>
          shipTypes?.map((shipType) =>
            shipType.id === data.id ? data : shipType
          ) || []
      );
    },
  });
};

export const useShipTypeRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>(shipTypeService.remove, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<ShipType[]>(
        'allShipTypes',
        (shipTypes: ShipType[] | undefined) =>
          shipTypes?.filter((shipType) => shipType.id !== variables) || []
      );
    },
  });
};
