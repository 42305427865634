import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { getAlphabet } from '../../library/util';
import { Class } from '../../models/Class';

const ButtonSave = styled(Button)`
  width: 100%;
`;

const ButtonCancel = styled(Button)`
  float: right;
`;

interface IProps {
  classes: Class[];
  selectedClass?: Class;
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (classification: Class) => void;
}

const ClassModal: React.FC<IProps> = ({
  classes,
  error,
  onClose,
  onSubmit,
  selectedClass,
  submitting,
}) => {
  const [form] = Form.useForm();

  const placeholder = useMemo(() => {
    const classIndex = selectedClass
      ? classes.findIndex((c) => c.id === selectedClass.id)
      : classes.length;
    return `Class ${getAlphabet(classIndex)}`;
  }, [classes, selectedClass]);

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (classification: Class) => {
      if (selectedClass) {
        classification.id = selectedClass.id;
      }
      onSubmit(classification);
    },
    [onSubmit, selectedClass]
  );

  useEffect(() => {
    if (selectedClass) {
      form.setFieldsValue(selectedClass);
    } else {
      form.setFieldsValue({
        name: placeholder,
      });
    }
  }, [form, placeholder, selectedClass]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      visible
      footer={null}
      maskClosable={false}
      title={!selectedClass ? 'Add Class' : 'Update Class'}
      width={400}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Vehicle Classification Name"
              name="name"
              rules={[{ message: 'Class name is required!', required: true }]}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <ButtonCancel onClick={closeModal}>Cancel</ButtonCancel>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <ButtonSave htmlType="submit" loading={submitting} type="primary">
                SAVE
              </ButtonSave>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ClassModal;
