import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu, Typography } from 'antd';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../contexts/authContext';

/*
Keeping this from the TSX version so we know which props are passed
interface ISidebarFooterProps {
  showCarriersNavigation?: boolean;
  selectedCarrier?: Carrier;
  carriers?: Carrier[];
}
*/

const SidebarFooter = ({
  carriers,
  selectedCarrier,
  showCarriersNavigation,
}) => {
  const history = useHistory();
  const { authUser, signOut } = useContext(AuthContext);

  const username = useMemo(() => {
    if (!authUser) {
      return '';
    }
    return `${authUser.firstName || ''} ${authUser.lastName || ''}`;
  }, [authUser]);

  const handleCarrierChange = useCallback(
    ({ key }) => {
      if (key === 'all') {
        history.push('/');
      } else {
        history.push(`/carrier/${key}/`);
      }
    },
    [history]
  );

  return (
    <>
      {showCarriersNavigation ? (
        <Menu
          className="carriers-navigation"
          mode="vertical"
          selectedKeys={
            selectedCarrier ? [selectedCarrier.id.toString()] : undefined
          }
          onSelect={handleCarrierChange}
        >
          <Menu.SubMenu
            key="carriers-menu"
            title={
              !selectedCarrier ? (
                <span>&mdash;&mdash;&mdash;&mdash;</span>
              ) : (
                <span>{selectedCarrier.name}</span>
              )
            }
          >
            {/* cp-111: Added an empty one to prevent Show All going off screen*/}
            <Menu.Item key="">&nbsp;</Menu.Item>
            <Menu.Item key="all">Show All</Menu.Item>
            {carriers?.map((carrier) => (
              <Menu.Item key={carrier.id}>{carrier.name}</Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
      ) : null}

      <div className="user-profile-section">
        <Avatar
          icon={<UserOutlined />}
          size={54}
          style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
        />
        <div className="user-profile-section__user-info">
          <Typography.Title level={5}>{username}</Typography.Title>
          <Typography.Text>
            <span>{authUser?.role}</span>
            <Button type="link" onClick={signOut}>
              Log out
            </Button>
          </Typography.Text>
        </div>
      </div>

      <div className="version-section">
        <Typography.Text type="secondary">v1.3.1</Typography.Text>
        <Typography.Text type="secondary">(2024-02-28 18:55)</Typography.Text>
      </div>
    </>
  );
};

export default SidebarFooter;
