import { keyBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Rate } from '../../models/Rate';
import { AddressType } from '../../models/Terminal';
import rateService from '../../services/rateService';

export const useRates = (carrierId?: number) => {
  const { data, isLoading, status } = useQuery(
    carrierId ? ['rates', carrierId] : 'rates',
    () => rateService.getAll(carrierId),
    {
      // reload the rates list on a schedule. This used to be every 5 seconds, but that's a lot of server load
      refetchInterval: 60000,
    }
  );

  return {
    isLoading,
    rates: data || [],
    status,
  };
};

export const useRateCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Rate, Error, Rate>(rateService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData<Rate[] | undefined>(
        'rates',
        (rates?: Rate[]) => [...(rates || []), data]
      );

      queryClient.setQueryData<Rate[] | undefined>(
        ['rates', data.carrierId],
        (rates?: Rate[]) => [...(rates || []), data]
      );
    },
  });
};

export const useRatesCreate = () => {
  return useMutation<Rate[], Error, Rate[]>(rateService.bulkAdd);
};

export const useRateUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Rate, Error, Rate>(rateService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Rate[] | undefined>('rates', (rates?: Rate[]) =>
        rates?.map((rate) => (rate.id === data.id ? data : rate))
      );

      queryClient.setQueryData<Rate[] | undefined>(
        ['rates', data.carrierId],
        (rates?: Rate[]) =>
          rates?.map((rate) => (rate.id === data.id ? data : rate))
      );
    },
  });
};

export const useRatesRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, [number[], number | undefined]>(
    ([ids]) => rateService.bulkRemove(ids),
    {
      onSuccess: (data, [variables, carrierId]) => {
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) =>
              rates?.filter((rate) => !variables.includes(rate.id))
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) =>
              rates?.filter((rate) => !variables.includes(rate.id))
          );
        }
      },
    }
  );
};

export const useRatesReverse = () => {
  const queryClient = useQueryClient();

  return useMutation<Rate[], Error, [number[], number | undefined]>(
    ([ids]) => rateService.bulkReverse(ids),
    {
      onSuccess: (data, [variables, carrierId]) => {
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) => [...(rates || []), ...data]
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) => [...(rates || []), ...data]
          );
        }
      },
    }
  );
};

export const useRatesDuplicate = () => {
  const queryClient = useQueryClient();

  return useMutation<Rate[], Error, [number[], number | undefined]>(
    ([ids]) => rateService.bulkDuplicate(ids),
    {
      onSuccess: (data, [variables, carrierId]) => {
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) => [...(rates || []), ...data]
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) => [...(rates || []), ...data]
          );
        }
      },
    }
  );
};

export const useRatesUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Rate[], Error, [Rate[], number | undefined]>(
    ([rates]) => rateService.bulkUpdate(rates),
    {
      onSuccess: (data, [variables, carrierId]) => {
        const keydData = keyBy(data, (rate) => rate.id);
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) =>
              rates?.map((rate) =>
                keydData[rate.id] ? keydData[rate.id] : rate
              )
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) =>
              rates?.map((rate) =>
                keydData[rate.id] ? keydData[rate.id] : rate
              )
          );
        }
      },
    }
  );
};

export const useRatesAssignTerminals = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Rate[],
    Error,
    [number[], number[], AddressType, number | undefined]
  >(
    ([ids, terminals, type, carrierId]) =>
      rateService.assignTerminals(ids, terminals, type),
    {
      onSuccess: (data, [ids, terminals, type, carrierId]) => {
        const keydData = keyBy(data, (rate) => rate.id);
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) =>
              rates?.map((rate) =>
                keydData[rate.id] ? keydData[rate.id] : rate
              )
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) =>
              rates?.map((rate) =>
                keydData[rate.id] ? keydData[rate.id] : rate
              )
          );
        }
      },
    }
  );
};

export const useRateAssignTerminals = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Rate,
    Error,
    [number, number[], number[], number | undefined]
  >(
    ([id, originTerminals, destinationTerminals, carrierId]) =>
      rateService.singleAssignTerminals(
        id,
        originTerminals,
        destinationTerminals
      ),
    {
      onSuccess: (
        data,
        [id, originTerminals, destinationTerminals, carrierId]
      ) => {
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) =>
              rates?.map((rate) => (data.id === rate.id ? data : rate))
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) =>
              rates?.map((rate) => (data.id === rate.id ? data : rate))
          );
        }
      },
    }
  );
};

export const useRatesAdjustBaseRate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Rate[],
    Error,
    [number[], number, string, number | undefined]
  >(
    ([ids, amount, type, carrierId]) =>
      rateService.adjustBaseRates(ids, amount, type),
    {
      onSuccess: (data, [variables, carrierId]) => {
        if (!carrierId) {
          queryClient.setQueryData<Rate[] | undefined>(
            'rates',
            (rates?: Rate[]) =>
              rates?.filter((rate) => !variables.includes(rate.id))
          );
        } else {
          queryClient.setQueryData<Rate[] | undefined>(
            ['rates', carrierId],
            (rates?: Rate[]) =>
              rates?.filter((rate) => !variables.includes(rate.id))
          );
        }
      },
    }
  );
};
