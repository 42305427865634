import { ExportToCsv, Options as CsvOptions } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useCallback, useState } from 'react';
import * as XLSX from 'xlsx';

export interface IFileExportConfig {
  title: string;
  filename: string;
  data: any[];
  columns: {
    selector: string;
    title: string;
  }[];
  type?: 'XLS' | 'XLSX';
}

export const useFileExport = () => {
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const exportToCsv = useCallback(
    ({ columns, data, filename, title }: IFileExportConfig) => {
      setIsExporting(true);
      const options: CsvOptions = {
        decimalSeparator: '.',
        fieldSeparator: ',',
        filename,
        headers: columns.map((column) => column.title),
        quoteStrings: '"',
        showLabels: true,
        showTitle: true,
        title,
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
      setIsExporting(false);
    },
    []
  );

  const exportToExcel = useCallback(
    ({ columns, data, filename, title, type }: IFileExportConfig) => {
      setIsExporting(true);
      const exportData = data.map((row) => {
        const record: any = {};
        columns.forEach((column) => {
          record[column.title] = row[column.selector];
        });
        return record;
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, title);

      /* save to file */
      XLSX.writeFile(wb, `${filename}.${type ? type.toLowerCase() : '.xlsx'}`);
      setIsExporting(false);
    },
    []
  );

  const exportToPdf = useCallback(
    ({ columns, data, filename, title }: IFileExportConfig) => {
      const doc = new jsPDF({ orientation: 'l' });
      doc.text(title, 14, 20);

      autoTable(doc, {
        body: data,
        columns: columns.map((column) => ({
          dataKey: column.selector,
          header: column.title,
        })),
        startY: 25,
        styles: {
          cellPadding: 0,
          fontSize: 8,
        },
      });
      doc.save(`${filename}.pdf`);
    },
    []
  );

  return {
    exportToCsv,
    exportToExcel,
    exportToPdf,
    isExporting,
  };
};
