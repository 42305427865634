import { Class } from './Class';
import { Insurance } from './Insurance';
import { Mode } from './Mode';
import { Option } from './Option';
import { Rate } from './Rate';
import { Rating } from './Rating';
import { Terminal } from './Terminal';
import { User } from './User';
import { Vehicle } from './Vehicle';

export enum CarrierPermission {
  none = 0,
  admin = 1,
  carrier = 2,
  all = 3,
}

export type Carrier = {
  id: number;
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  primaryContactName: string;
  primaryEmail: string;
  primaryPhone: string;
  primaryExt?: number;
  secondaryContactName?: string;
  secondaryEmail?: string;
  secondaryPhone?: string;
  secondaryExt?: number;
  active: boolean;
  modePermission: CarrierPermission;
  classPermission: CarrierPermission;
  optionPermission: CarrierPermission;
  terminalPermission: CarrierPermission;
  insurancePermission: CarrierPermission;
  ratingPermission: CarrierPermission;
  userPermission: CarrierPermission;
  rates?: Rate[];
  classes?: Class[];
  vehicles?: Vehicle[];
  rating?: Rating;
  insurance?: Insurance;
  option?: Option;
  terminals?: Terminal[];
  modes?: Mode[];
  users?: User[];
  website?: string;
  fax?: string;
  isTerminal?: boolean;
  crmCarrierId: number;
  last_surcharge_change?: string;
  notes?: Carriernote[];
};

export type Carriernote = {
  id: number;
  carrierId: number;
  content: string;
  author: User;
  createdAt: Date;
};
