import './TerminalsServicedList.less';

import { Descriptions, Space, Typography } from 'antd';
import React from 'react';
import { PatternFormat } from 'react-number-format';

import { Terminal } from '../../models/Terminal';
import Container from '../common/Container';

interface IProps {
  id: string;
  terminals: Terminal[];
}

const TerminalsServicedList: React.FC<IProps> = ({ id, terminals }) => {
  return (
    <Container id={id}>
      <Descriptions
        colon={false}
        column={3}
        layout="vertical"
        title="Terminals Serviced"
      >
        {terminals.map((terminal, index) => (
          <Descriptions.Item
            key={index}
            label={<Typography.Text strong>{terminal.name}</Typography.Text>}
          >
            <Space direction="vertical">
              <Typography.Text className="terminals-serviced-list__terminal-info">
                {terminal.address1} {terminal.address2}, {terminal.city}{' '}
                {terminal.state}, {terminal.zipCode}
              </Typography.Text>
              <Typography.Text className="terminals-serviced-list__terminal-info">
                {terminal.contactName}
              </Typography.Text>
              <Typography.Text className="terminals-serviced-list__terminal-info">
                <PatternFormat
                  displayType="text"
                  format="###-###-####"
                  value={terminal.phone}
                />
                , {terminal.email}
              </Typography.Text>
            </Space>
          </Descriptions.Item>
        ))}
        {terminals.length % 3 === 2 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
        {terminals.length % 3 === 1 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
      </Descriptions>
    </Container>
  );
};

export default TerminalsServicedList;
