import { CloseOutlined, EditFilled } from '@ant-design/icons';
import { Button, Descriptions, Popconfirm, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import {
  useShipTypeCreate,
  useShipTypeRemove,
  useShipTypeUpdate,
} from '../../hooks/apiHooks';
import { ShipType } from '../../models/ShipType';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import ShipTypeModal from '../modals/ShipTypeModal';

interface IShipTypeListProps {
  id?: string;
  shipTypes: ShipType[];
}

const ShipTypeList: React.FC<IShipTypeListProps> = ({ id, shipTypes }) => {
  const createMutation = useShipTypeCreate();
  const updateMutation = useShipTypeUpdate();
  const removeMutation = useShipTypeRemove();

  const [isEditing, setIsEditing] = useState(false);
  const [editableShipType, setEditableShipType] = useState<ShipType>();
  const [deletableShipType, setDeletableShipType] = useState<ShipType>();
  const [showAddModal, setShowAddModal] = useState<boolean>();
  const [showEditModal, setShowEditModal] = useState<boolean>();

  const onAdd = useCallback(
    (shipType: ShipType) => {
      createMutation.mutate(shipType);
    },
    [createMutation]
  );

  const onUpdate = useCallback(
    (shipType: ShipType) => {
      updateMutation.mutate(shipType);
    },
    [updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableShipType) {
      removeMutation.mutate(deletableShipType.id);
    }
  }, [deletableShipType, removeMutation]);

  const onEdit = useCallback((shipType: ShipType) => {
    setEditableShipType(shipType);
    setShowEditModal(true);
  }, []);

  useEffect(() => {
    if (!isEditing) {
      setDeletableShipType(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  return (
    <Container id={id}>
      <Descriptions
        colon={false}
        column={4}
        layout="vertical"
        title={
          <ListHeader>
            <Space direction="horizontal">
              <span>Default Ship Types</span>
            </Space>

            <Space>
              <EditButton
                color={isEditing ? '#389e0d' : undefined}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Done' : 'Edit'}
              </EditButton>
              <Button
                ghost
                style={{ width: 120 }}
                type="primary"
                onClick={() => setShowAddModal(true)}
              >
                Add Ship Type
              </Button>
            </Space>
          </ListHeader>
        }
      >
        {shipTypes.length && (
          <Descriptions.Item>
            <Space direction="horizontal">
              {shipTypes.map((shipType) => (
                <Tag key={shipType.id} color="geekblue">
                  <Space>
                    {shipType.name}
                    {isEditing && (
                      <>
                        <Button
                          icon={<EditFilled />}
                          shape="circle"
                          size="small"
                          onClick={() => onEdit(shipType)}
                        />
                        <Popconfirm
                          okButtonProps={{ loading: removeMutation.isLoading }}
                          title="Are you sure to delete this shipType?"
                          visible={deletableShipType?.id === shipType.id}
                          onCancel={() => setDeletableShipType(undefined)}
                          onConfirm={onDelete}
                        >
                          <Button
                            danger
                            icon={<CloseOutlined />}
                            shape="circle"
                            size="small"
                            onClick={() => setDeletableShipType(shipType)}
                          />
                        </Popconfirm>
                      </>
                    )}
                  </Space>
                </Tag>
              ))}
            </Space>
          </Descriptions.Item>
        )}
      </Descriptions>

      {showAddModal && (
        <ShipTypeModal
          error={createMutation.error}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableShipType && (
        <ShipTypeModal
          error={updateMutation.error}
          selectedShipType={editableShipType}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default ShipTypeList;
