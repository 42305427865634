import './CarrierDetail.less';

import { Descriptions, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { useCarrierUpdate } from '../../hooks/apiHooks';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import CarrierModal from '../modals/CarrierModal';

/*
interface IProps {
  id: string;
  carrier: Carrier;
}
*/

const CarrierDetail = ({ carrier, id }) => {
  const updateMutation = useCarrierUpdate();

  const [showEditModal, setShowEditModal] = useState(false);

  const onUpdate = useCallback(
    (updated) => {
      updateMutation.mutate(updated);
    },
    [updateMutation]
  );

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  return (
    <Container id={id}>
      <Space direction="vertical" size="middle">
        <Descriptions
          colon={false}
          column={4}
          layout="vertical"
          title={
            <ListHeader>
              <Space direction="horizontal">
                <span>Profile</span>
              </Space>

              <Space>
                <EditButton onClick={() => setShowEditModal(true)}>
                  Edit
                </EditButton>
              </Space>
            </ListHeader>
          }
        >
          <Descriptions.Item
            contentStyle={{ flexDirection: 'column' }}
            label="Address"
          >
            <Typography.Text>{carrier.address1}</Typography.Text>
            {carrier.address2 && (
              <>
                <Typography.Text>{carrier.address2}</Typography.Text>
              </>
            )}
            <Typography.Text>
              {carrier.city}, {carrier.state}
            </Typography.Text>
            <Typography.Text>{carrier.zipCode}</Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            contentStyle={{ flexDirection: 'column' }}
            label="Primary Contact"
          >
            <Typography.Text>{carrier.primaryContactName}</Typography.Text>
            <Typography.Text>{carrier.primaryEmail}</Typography.Text>
            <Typography.Text>
              <PatternFormat
                displayType="text"
                format="###-###-####"
                value={carrier.primaryPhone}
              />
              {carrier.primaryExt && <> ext {carrier.primaryExt}</>}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            contentStyle={{ flexDirection: 'column' }}
            label="Secondary Contact"
          >
            <Typography.Text>{carrier.secondaryContactName}</Typography.Text>
            <Typography.Text>{carrier.secondaryEmail}</Typography.Text>
            <Typography.Text>
              <PatternFormat
                displayType="text"
                format="###-###-####"
                value={carrier.secondaryPhone}
              />
              {carrier.secondaryExt && <> ext {carrier.secondaryExt}</>}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        </Descriptions>
      </Space>
      {showEditModal && (
        <CarrierModal
          error={updateMutation.error}
          selectedCarrier={carrier}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default CarrierDetail;
