import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// FIXME: this could be refactored a bit to support more options or be smarter

// doing this in JS instead of TS because Google Maps is a pain to set up in TS

const MapModal = ({ lat, lng, onClose, rate }) => {
  const ref = useRef();
  const zoom = 8;

  useEffect(() => {
    let map = new window.google.maps.Map(ref.current, {
      center: { lat, lng },
      zoom: zoom,
    });
    let marker = new window.google.maps.Marker({
      map: map,
      position: { lat, lng },
    });
    marker.setMap(map);
  }, [lat, lng, zoom]);

  return (
    <Modal
      visible
      footer={null}
      title="Map"
      width={800}
      onCancel={onClose}
      onOk={onClose}
    >
      <div ref={ref} id="map" style={{ height: '600px', width: '100%' }} />
    </Modal>
  );
};

MapModal.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  onClose: PropTypes.func,
  rate: PropTypes.object.isRequired,
};

export default MapModal;
