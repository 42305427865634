import './RatesFilter.less';

import { CloseOutlined, SearchOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { Carrier } from '../../models/Carrier';
import { DefaultMode } from '../../models/Mode';
import { RateFilter } from '../../models/Rate';
import Toggle from '../common/Toggle';

interface IProps {
  showAllCarriers?: boolean;
  filter: RateFilter | null;
  carriers?: Carrier[];
  modes?: DefaultMode[];
  onFilterChange: (filter: RateFilter | null) => void;
}

const RatesFilter: React.FC<IProps> = ({
  carriers,
  filter,
  modes,
  onFilterChange,
  showAllCarriers,
}) => {
  const [form] = Form.useForm();

  const [isSeparateSearch, setIsSeparateSearch] = React.useState(false);

  useEffect(() => {
    form.setFieldsValue({
      carrierIds: filter?.carrierIds,
      destination: filter?.destination,
      modeName: filter?.modeName,
      origin: filter?.origin,
      searchType: filter?.searchType,
    });
  }, [
    filter?.searchType,
    filter?.carrierIds,
    filter?.modeName,
    filter?.destination,
    filter?.origin,
    form,
  ]);

  const handleSearchTypeChange = (value: string) => {
    const isUnifiedSearch = value === 'unified';
    setIsSeparateSearch(!isUnifiedSearch);
    // this used to be a field but now it's a toggle
    form.setFieldValue('searchType', value);
  };

  const filterOption = (
    input: string,
    option?: { children: string; value: string }
  ) => {
    const lowercaseInput = input.toLowerCase();
    const lowercaseLabel = (option?.children ?? '').toLowerCase();
    return lowercaseLabel.includes(lowercaseInput);
  };

  return (
    <Form form={form} name="rates-filter-form" onFinish={onFilterChange}>
      <Form.Item label="Search Rates" style={{ marginBottom: 0 }}>
        {showAllCarriers && (
          <Form.Item
            className="rates-filter__filter-item"
            name="carrierIds"
            style={{ minWidth: '200px' }}
          >
            <Select
              allowClear
              filterOption={filterOption}
              mode="multiple"
              optionFilterProp="children"
              placeholder="Carrier"
            >
              {carriers?.map((carrier) => (
                <Select.Option key={carrier.id} value={carrier.id}>
                  {carrier.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          className="rates-filter__filter-item"
          name="modeName"
          style={{ minWidth: '200px' }}
        >
          <Select allowClear placeholder="Mode">
            {modes?.map((mode) => (
              <Select.Option key={mode.id} value={mode.name}>
                {mode.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="rates-filter__filter-item"
          name="origin"
          style={{
            margin: '0 8px',
            width: 'calc(28% - 8px)',
          }}
        >
          <Input placeholder={isSeparateSearch ? 'Origin' : 'Location'} />
        </Form.Item>

        <Form.Item
          className="rates-filter__filter-item"
          name="searchType"
          style={{
            marginBottom: '20px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <Tooltip title="Origin/Dest Search">
            <Toggle
              onChange={(checked) => {
                handleSearchTypeChange(checked ? 'separate' : 'unified');
              }}
            />
          </Tooltip>
        </Form.Item>

        {isSeparateSearch && (
          <>
            <SwapOutlined id="dest-swap" style={{ lineHeight: '32px' }} />

            <Form.Item
              className="rates-filter__filter-item destination-container"
              dependencies={['searchType']}
              name="destination"
              style={{
                margin: '0 8px',
                width: 'calc(28% - 8px)',
              }}
            >
              <Input placeholder="Destination" />
            </Form.Item>
          </>
        )}

        <Form.Item className="rates-filter__filter-item">
          <Button htmlType="submit" icon={<SearchOutlined />} type="primary" />
        </Form.Item>

        <Form.Item
          className="rates-filter__filter-item"
          style={{ marginLeft: 5 }}
        >
          <Tooltip title="Reset">
            <Button
              icon={<CloseOutlined />}
              style={{ visibility: isEmpty(filter) ? 'hidden' : 'visible' }}
              type="text"
              onClick={() => onFilterChange(null)}
            />
          </Tooltip>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

export default RatesFilter;
