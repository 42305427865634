import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../contexts/authContext';
import carrierService from '../../services/carrierService';

export const useCarriers = (disabled) => {
  const { data, isLoading, status } = useQuery(
    'allCarriers',
    () => carrierService.getAll(),
    {
      enabled: !disabled,
    }
  );

  return {
    allCarriers: data || [],
    isLoading,
    status,
  };
};

export const useCarriersList = (enabled) => {
  const { data, status } = useQuery(
    'carriersList',
    () => carrierService.getList(),
    {
      enabled: !!enabled,
    }
  );

  return {
    carriersList: data || [],
    status,
  };
};

export const useCarrierOrders = (carrier) => {
  const { data, error, isLoading } = useQuery('carrierOrdersList', () =>
    carrierService.getCrmCarrierOrders(carrier)
  );

  if (error) {
    return {
      carrierOrdersList: [],
      error,
      isLoading: false,
    };
  }

  return {
    carrierOrdersList: data || [],
    error: null,
    isLoading,
  };
};

export const useSelectedCarrier = () => {
  const { authUser } = useContext(AuthContext);
  const { carrierId } = useParams();

  const currentCarrierId = Number(
    authUser?.carrierId ? authUser?.carrierId : carrierId
  );

  const { data: selectedCarrier, status } = useQuery(
    ['carrier', currentCarrierId],
    () => carrierService.get(currentCarrierId),
    {
      enabled: !!currentCarrierId,
      retry: 0,
    }
  );

  return {
    carrierId: currentCarrierId,
    selectedCarrier,
    status,
  };
};

export const useCarrier = (carrierId) => {
  const { data, isLoading, status } = useQuery(['carrier', carrierId], () =>
    carrierService.get(carrierId)
  );

  return {
    carrier: data,
    isLoading,
    status,
  };
};

export const useCarrierCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData('allCarriers', (carriers) => [
        ...(carriers || []),
        data,
      ]);

      queryClient.setQueryData('carriersList', (carriers) => [
        ...(carriers || []),
        data,
      ]);
    },
  });
};

export const useCarrierUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        'allCarriers',
        (carriers) =>
          carriers?.map((carrier) =>
            carrier.id === data.id ? { ...carrier, ...data } : carrier
          ) || []
      );

      queryClient.setQueryData(['carrier', data.id], (carrier) => data);
    },
  });
};

export const useCarrierRemove = () => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.remove, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        'allCarriers',
        (carriers) =>
          carriers?.filter((carrier) => carrier.id !== variables) || []
      );
    },
  });
};

export const useCarrierTerminalMap = (disabled) => {
  const { data, status } = useQuery(
    'carrierTerminalMap',
    () => carrierService.getCarrierTerminalMap(),
    {
      enabled: !disabled,
    }
  );

  return {
    carrierTerminalMap: data || [],
    status,
  };
};

export const useCarrierTerminalMapUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.updateCarrierTerminalMap, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData('carrierTerminalMap', () => data);
    },
  });
};

export const useCreateCarrierNote = (noteData) => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.createNote, {
    onSuccess: (data) => {
      queryClient.setQueryData('carriernote', noteData);
      return data;
    },
  });
};

export const useDeleteCarrierNote = (noteId) => {
  const queryClient = useQueryClient();

  return useMutation(carrierService.deleteNote, {
    onSuccess: (data) => {
      queryClient.setQueryData('id', noteId);
    },
  });
};
