import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import {
  useCarrierCreate,
  useCarrierRemove,
  useCarrierUpdate,
  useTerminalCreate,
} from '../../hooks/apiHooks';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import PanelHeader from '../common/PanelHeader';
import Toggle from '../common/Toggle';
import CarrierModal from '../modals/CarrierModal';
import CPTable from '../tables/CPTable';

/*
interface ICarrierListProps {
  id?: string;
  carriers: Carrier[];
}
*/

const CarrierList = ({ carriers, id, onCarrierSelect }) => {
  const createMutation = useCarrierCreate();
  const updateMutation = useCarrierUpdate();
  const removeMutation = useCarrierRemove();
  const createTerminalMutation = useTerminalCreate();

  const [isEditing, setIsEditing] = useState(false);
  const [editableCarrier, setEditableCarrier] = useState();
  const [deletableCarrier, setDeletableCarrier] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const addTerminalFromCarrier = useCallback(
    (carrier) => {
      const newTerminal = {
        active: true,
        address1: carrier.address1,
        address2: carrier.address2,
        city: carrier.city,
        contactName: carrier.primaryContactName,
        email: carrier.primaryEmail,
        ext: carrier.primaryExt,
        id: 0,
        name: carrier.name,
        phone: carrier.primaryPhone,
        state: carrier.state,
        zipCode: carrier.zipCode,
      };
      createTerminalMutation.mutate(newTerminal);
    },
    [createTerminalMutation]
  );

  const onAdd = useCallback(
    (carrier) => {
      carrier.active = true;
      createMutation.mutate(carrier);

      if (carrier.isTerminal) {
        addTerminalFromCarrier(carrier);
      }
    },
    [addTerminalFromCarrier, createMutation]
  );

  const onUpdate = useCallback(
    (carrier) => {
      updateMutation.mutate(carrier);
      if (carrier.isTerminal) {
        addTerminalFromCarrier(carrier);
      }
    },
    [addTerminalFromCarrier, updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableCarrier) {
      removeMutation.mutate(deletableCarrier.id);
    }
  }, [deletableCarrier, removeMutation]);

  const onEdit = useCallback((carrier) => {
    setEditableCarrier(carrier);
    setShowEditModal(true);
  }, []);

  const onToggleActive = useCallback(
    (checked, carrier) => {
      carrier.active = checked;
      onUpdate(carrier);
      setEditableCarrier(carrier);
    },
    [onUpdate]
  );

  useEffect(() => {
    if (!isEditing) {
      setDeletableCarrier(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        render: (_id, carrier) => <>{carrier.name}</>,
        title: 'Carrier',
      },
      {
        dataIndex: 'address1',
        render: (_id, carrier) => {
          return (
            <>
              <span>{carrier.address1}</span>
              <br />
              {carrier.address2 && (
                <>
                  <span>{carrier.address2}</span>
                  <br />
                </>
              )}
              <span>
                {carrier.city}, {carrier.state} {carrier.zipCode}
              </span>
            </>
          );
        },
        title: 'Address',
      },
      {
        dataIndex: 'primaryContactName',
        render: (_id, carrier) => {
          return (
            <>
              <span>{carrier.primaryContactName}</span>
              <br />
              <PatternFormat
                displayType="text"
                format="###-###-####"
                value={carrier.primaryPhone}
              />
              <br />
              <span>{carrier.primaryEmail}</span>
            </>
          );
        },
        title: 'Primary Contact',
      },
      {
        dataIndex: 'secondaryContactName',
        render: (_id, carrier) => {
          return (
            <>
              <span>{carrier.secondaryContactName}</span>
              <br />
              <PatternFormat
                displayType="text"
                format="###-###-####"
                value={carrier.secondaryPhone}
              />
              <br />
              <span>{carrier.secondaryEmail}</span>
            </>
          );
        },
        title: 'Secondary Contact',
      },
      {
        dataIndex: 'id',
        render: (_id, carrier) => {
          if (isEditing) {
            return (
              <Space>
                <Toggle
                  key="toggle-btn"
                  defaultChecked={carrier.active ?? false}
                  disabled={
                    !isEditing ||
                    (updateMutation.isLoading && !!editableCarrier)
                  }
                  loading={
                    updateMutation.isLoading &&
                    editableCarrier?.id === carrier.id
                  }
                  size="small"
                  onChange={(checked) => onToggleActive(checked, carrier)}
                />
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  size="small"
                  onClick={() => onEdit(carrier)}
                />
                <Popconfirm
                  okButtonProps={{ loading: removeMutation.isLoading }}
                  placement="leftBottom"
                  title="Are you sure to delete this carrier?"
                  visible={deletableCarrier?.id === carrier.id}
                  onCancel={() => setDeletableCarrier(undefined)}
                  onConfirm={onDelete}
                >
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    shape="circle"
                    size="small"
                    onClick={() => setDeletableCarrier(carrier)}
                  />
                </Popconfirm>
              </Space>
            );
          }
          return null;
        },
        title: '',
        width: 125,
      },
    ],
    [
      deletableCarrier?.id,
      editableCarrier,
      isEditing,
      onDelete,
      onEdit,
      onToggleActive,
      removeMutation.isLoading,
      updateMutation.isLoading,
    ]
  );

  return (
    <Container id={id}>
      <PanelHeader
        extra={[
          <EditButton
            key="edit-btn"
            color={isEditing ? '#389e0d' : undefined}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'Done' : 'Edit'}
          </EditButton>,
          <Button
            key="add-btn"
            ghost
            style={{ width: 120 }}
            type="primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Carrier
          </Button>,
        ]}
        ghost={false}
        title="Carriers"
      />

      {carriers.length ? (
        <CPTable
          columns={columns}
          dataSource={carriers}
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: true,
          }}
          rowKey={(carrier) => carrier.id}
        />
      ) : null}

      {showAddModal && (
        <CarrierModal
          error={createMutation.error}
          selectedCarrier={null}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableCarrier && (
        <CarrierModal
          error={updateMutation.error}
          selectedCarrier={editableCarrier}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default CarrierList;
