import axios from 'axios';

import { Rate } from '../models/Rate';
import { AddressType } from '../models/Terminal';
import carrierService from './carrierService';

const uri = '/rate';

const create = async (rate: Rate) => {
  const { data } = await axios.post<Rate>(`${uri}`, rate);
  return data;
};

const getAll = async (carrierId?: number) => {
  if (!carrierId) {
    const { data } = await axios.get<Rate[]>(`${uri}`);
    return data;
  }

  const rates = await carrierService.getRates(carrierId);
  return rates;
};

const update = async (rate: Rate) => {
  const { data } = await axios.put<Rate>(`${uri}/${rate.id}`, rate);
  return data;
};

const bulkAdd = async (rates: Rate[]) => {
  const { data } = await axios.post<Rate[]>(`${uri}/bulk/add`, rates);
  return data;
};

const bulkRemove = async (ids: number[]) => {
  await axios.post(`${uri}/bulk/remove`, ids);
};

const bulkReverse = async (ids: number[]) => {
  const { data } = await axios.post<Rate[]>(`${uri}/bulk/reverse`, ids);
  return data;
};

const bulkDuplicate = async (ids: number[]) => {
  const { data } = await axios.post<Rate[]>(`${uri}/bulk/duplicate`, ids);
  return data;
};

const bulkUpdate = async (rates: Rate[]) => {
  const { data } = await axios.post<Rate[]>(`${uri}/bulk/update`, rates);
  return data;
};

const bulkUpdatePreferred = async (ids: number[], preferred: number) => {
  const { data } = await axios.post(`${uri}/bulk/update/preferred`, {
    preferred,
    rates: ids,
  });
  return data;
};

const assignTerminals = async (
  ids: number[],
  terminals: number[],
  type: AddressType
) => {
  const { data } = await axios.post<Rate[]>(`${uri}/terminal`, {
    destinationTerminals: type === 'destination' ? terminals : null,
    originTerminals: type === 'origin' ? terminals : null,
    rates: ids,
  });
  return data;
};

const singleAssignTerminals = async (
  id: number,
  originTerminals: number[],
  destinationTerminals: number[]
) => {
  const { data } = await axios.post<Rate>(`${uri}/terminal/single`, {
    destinationTerminals,
    id,
    originTerminals,
  });
  return data;
};

const adjustBaseRates = async (
  ids: number[],
  adjustBy: number,
  adjustType: string
) => {
  const { data } = await axios.post<Rate[]>(`${uri}/bulk/baseadjust`, {
    amount: adjustBy,
    rates: ids,
    type: adjustType,
  });
  return data;
};

const apis = {
  adjustBaseRates,
  assignTerminals,
  bulkAdd,
  bulkDuplicate,
  bulkRemove,
  bulkReverse,
  bulkUpdate,
  bulkUpdatePreferred,
  create,
  getAll,
  singleAssignTerminals,
  update,
};

export default apis;
