import { capitalize } from 'lodash';

import { Terminal } from '../models/Terminal';

export const getAlphabet = (index: number) => {
  return String.fromCharCode('A'.charCodeAt(0) + index);
};

export const getCapitalizedSentence = (sentence?: string) => {
  if (!sentence) {
    return '';
  }
  return sentence
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

export const getInAddressTerminals = (
  terminals?: Terminal[],
  address?: string
) => {
  return (
    terminals?.filter(
      (terminal) =>
        `${terminal.city}, ${terminal.state}`.toLowerCase() ===
        address?.toLowerCase()
    ) || []
  );
};

export const formatCurrency = (value: number) => {
  return `$${value.toLocaleString('en-CA', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};

export const formatDateTime = (date: Date) => {
  return date.toLocaleString('en-US', {
    day: '2-digit',
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? '+' : '-';
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, '0');
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, '0');

  return `UTC${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

export const getfuelExpiryString = (fuelSurchargeExpiry: string) => {
  const currentDate = new Date();
  const targetTimeZone = getTimeZone();
  const fuelSurchargeExpiryDate = new Date(
    `${fuelSurchargeExpiry} ${targetTimeZone}`
  );
  const timeDifference =
    fuelSurchargeExpiryDate.valueOf() - currentDate.valueOf();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  // Determine the color style based on the number of days remaining
  const color =
    daysDifference < 2
      ? 'style="color:red"'
      : daysDifference < 10
      ? 'style="color:orange"'
      : ''; // No style if not less than 10 days
  return `<span ${color}>${fuelSurchargeExpiry.replaceAll('/', '-')}</span>`;
};
