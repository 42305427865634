import { Button } from 'antd';
import styled from 'styled-components';

const EditButton = styled(Button)<{ color?: string }>`
  background-color: ${(props) => props.color || '#FFFFFF'};
  color: ${(props) => (props.color ? '#FFFFFF' : 'rgba(0, 0, 0, .85)')};

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.color || '#FFFFFF'};
    color: ${(props) => (props.color ? '#FFFFFF' : 'rgba(0, 0, 0, .85)')};
  }
`;

export default EditButton;
