import './TerminalModal.less';

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useEffect } from 'react';

import { PROVINCES } from '../../models/Constants';
//import { Terminal } from '../../models/Terminal';

/*
interface IProps {
  selectedTerminal?: Terminal;
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (terminal: Terminal) => void;
}
*/

const TerminalModal = ({
  error,
  onClose,
  onSubmit,
  selectedTerminal,
  submitting,
}) => {
  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (terminalInput) => {
      const terminal = {
        ...terminalInput,
        hoursOfOperationTime: terminalInput.hoursOfOperationTime
          ?.map((time) => time.format('HH:mm A'))
          ?.join(','),
      };
      if (selectedTerminal) {
        terminal.id = selectedTerminal.id;
      } else {
        terminal.active = true;
      }
      onSubmit(terminal);
    },
    [onSubmit, selectedTerminal]
  );

  useEffect(() => {
    if (selectedTerminal) {
      form.setFieldsValue(selectedTerminal);
    } else {
      form.setFieldsValue({
        dailyStorageFeeDueDateType: 'days',
      });
    }
  }, [selectedTerminal, form]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title={!selectedTerminal ? 'Add Terminal' : 'Edit Terminal'}
      visible={true}
      width={1400}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col lg={8} span={12} xl={5}>
            <Typography.Paragraph strong>Terminal Info</Typography.Paragraph>
            <Form.Item
              required
              label="Company Name"
              name="name"
              rules={[
                { message: 'Please input company name!', required: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address 1"
              name="address1"
              rules={[{ message: 'Please input address!', required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="address2">
              <Input />
            </Form.Item>
            <Form.Item
              label="Terminal City & Prov"
              name="city"
              rules={[{ message: 'Please input city!', required: true }]}
            >
              <Input
                addonAfter={
                  <Form.Item
                    noStyle
                    name="state"
                    rules={[{ message: 'Select province!', required: true }]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        // The passed option only has the two-letter code as the value, but filtering by the full name is also useful.
                        // We have to poke around in the react structure to find the full text value.
                        // This is a bit of a hack and might break if the structure underneath changes or antd is updated etc.
                        let provinceAbbrev = option?.value.toLowerCase();
                        let provinceName =
                          option?.children.props.title.toLowerCase();
                        let abbrevMatch =
                          provinceAbbrev.indexOf(input.toLowerCase()) >= 0;
                        let nameMatch =
                          provinceName.indexOf(input.toLowerCase()) >= 0;
                        return abbrevMatch || nameMatch;
                      }}
                    >
                      {PROVINCES.map((province, index) => (
                        <Select.Option key={index} value={province.value}>
                          <Tooltip placement="left" title={province.text}>
                            {province.text}
                          </Tooltip>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                className="city-state-input"
              />
            </Form.Item>
            <Form.Item
              label="Postal Code"
              name="zipCode"
              rules={[{ message: 'Please input postal code!', required: true }]}
            >
              <Input style={{ maxWidth: 120 }} />
            </Form.Item>
          </Col>
          <Col lg={8} span={12} xl={5}>
            <Typography.Paragraph strong>&nbsp;</Typography.Paragraph>
            <Form.Item
              label="Contact Name"
              name="contactName"
              rules={[
                { message: 'Please input contact name!', required: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Contact Email"
              name="email"
              rules={[
                {
                  message: 'Please input email!',
                  required: true,
                },
                {
                  message: 'Please input a valid E-mail!',
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row>
              <Col span={14}>
                <Form.Item label="Contact Phone" name="phone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Ext" name="ext">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={8} span={12} xl={7}>
            <Typography.Paragraph strong>Terminal Options</Typography.Paragraph>

            <Form.Item label="Daily Storage Fee" style={{ marginBottom: 0 }}>
              <Input.Group compact>
                <Form.Item name="dailyStorageFee">
                  <Input prefix="$" style={{ width: 73 }} />
                </Form.Item>
                <Typography.Text
                  style={{
                    lineHeight: '32px',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  After
                </Typography.Text>
                <Form.Item name="dailyStorageFeeDueDate">
                  <Input style={{ width: 55 }} />
                </Form.Item>
                <Form.Item name="dailyStorageFeeDueDateType">
                  <Select style={{ width: 92 }}>
                    <Select.Option value="days">Days</Select.Option>
                    <Select.Option value="weeks">Weeks</Select.Option>
                    <Select.Option value="months">Months</Select.Option>
                    <Select.Option value="years">Years</Select.Option>
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item>
              <Input.Group compact>
                <Form.Item label="Usage Fee" name="usageFee">
                  <Input prefix="$" style={{ width: 100 }} />
                </Form.Item>

                <Form.Item
                  label="Wait Time in Days"
                  name="waitTimeInDays"
                  style={{ marginLeft: 20 }}
                >
                  <Input style={{ width: 100 }} suffix="days" />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  name="afterHoursDropOff"
                  style={{ marginBottom: 0 }}
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
                <Typography.Text className="terminal-modal__switch-label">
                  After-hours Drop Off
                </Typography.Text>
              </Input.Group>
            </Form.Item>

            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  name="afterHoursPickUp"
                  style={{ marginBottom: 0 }}
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
                <Typography.Text className="terminal-modal__switch-label">
                  After-hours Pick Up
                </Typography.Text>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24} xl={7}>
            <Typography.Paragraph strong>Terminal Notes</Typography.Paragraph>

            <Form.Item label="Drop Off Notes" name="noteDropOff">
              <Input.TextArea className="terminal-modal__textarea" rows={4} />
            </Form.Item>

            <Form.Item label="Pick Up Notes" name="notePickup">
              <Input.TextArea className="terminal-modal__textarea" rows={4} />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.afterHoursDropOff !== curr.afterHoursDropOff;
              }}
            >
              {() => {
                if (form.getFieldValue('afterHoursDropOff')) {
                  return (
                    <Form.Item
                      label="After-hours Drop Off Notes"
                      name="noteAfterHoursDropOff"
                    >
                      <Input.TextArea
                        className="terminal-modal__textarea"
                        rows={4}
                      />
                    </Form.Item>
                  );
                }
                return <></>;
              }}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.afterHoursPickUp !== curr.afterHoursPickUp;
              }}
            >
              {() => {
                if (form.getFieldValue('afterHoursPickUp')) {
                  return (
                    <Form.Item
                      label="After-hours Pick Up Notes"
                      name="noteAfterHoursPickup"
                    >
                      <Input.TextArea
                        className="terminal-modal__textarea"
                        rows={4}
                      />
                    </Form.Item>
                  );
                }
                return <></>;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TerminalModal;
