import './UserModal.less';

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd';
import React, { useCallback, useEffect } from 'react';

import { Carrier } from '../../models/Carrier';
import { User, UserRole } from '../../models/User';

interface IProps {
  carriers: Carrier[];
  selectedUser?: User;
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (user: User) => void;
}

const UserModal: React.FC<IProps> = ({
  carriers,
  error,
  onClose,
  onSubmit,
  selectedUser,
  submitting,
}) => {
  const [form] = Form.useForm<User>();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = (values: User) => {
    if (selectedUser) {
      values.id = selectedUser.id;
    }
    onSubmit(values);
  };

  useEffect(() => {
    if (selectedUser) {
      // set the password confirm value to match the password on initial load so we don't have to type it again
      selectedUser.confirmPassword = selectedUser.password;
      form.setFieldsValue(selectedUser);
    }
  }, [form, selectedUser]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title={!selectedUser ? 'Add User' : 'Update User'}
      visible={true}
      width={1000}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  className="user-modal__half-width-item"
                  label="First Name"
                  name="firstName"
                  rules={[
                    { message: 'First Name is required!', required: true },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="user-modal__half-width-item"
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { message: 'Last Name is required!', required: true },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  className="user-modal__half-width-item"
                  label="Phone"
                  name="phone"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="user-modal__quarter-width-item"
                  label="Ext"
                  name="ext"
                >
                  <Input />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              hasFeedback
              label="Password"
              name="password"
              rules={[
                { message: 'Please input your password!', required: true },
                () => ({
                  validator(rule, value: string) {
                    if (!value || value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The password should be at least 8 characters!'
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Company" name="company">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ message: 'Email is required!', required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              hasFeedback
              dependencies={['password']}
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { message: 'Please confirm your password!', required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => {
                return prev.role !== curr.role;
              }}
            >
              {() => (
                <Input.Group compact>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      { message: 'Please select a user role!', required: true },
                    ]}
                    style={{ width: '40%' }}
                  >
                    <Select style={{ width: '100%' }}>
                      <Select.Option value={UserRole.SUPER_ADMIN}>
                        {UserRole.SUPER_ADMIN}
                      </Select.Option>
                      <Select.Option value={UserRole.ADMIN}>
                        {UserRole.ADMIN}
                      </Select.Option>
                      <Select.Option value={UserRole.CARRIER}>
                        {UserRole.CARRIER}
                      </Select.Option>
                      <Select.Option value={UserRole.CUSTOMER}>
                        {UserRole.CUSTOMER}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {[UserRole.CARRIER, UserRole.CUSTOMER].includes(
                    form.getFieldValue('role')
                  ) && (
                    <Form.Item
                      label="Carrier"
                      name="carrierId"
                      style={{ width: '60%' }}
                    >
                      <Select style={{ width: '100%' }}>
                        {carriers.map((carrier) => (
                          <Select.Option key={carrier.id} value={carrier.id}>
                            {carrier.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Input.Group>
              )}
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              name="sendEmail"
              style={{ marginTop: 30 }}
              valuePropName="checked"
            >
              <Checkbox>
                Send the new user an email about their account.
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col offset={8} span={8}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserModal;
