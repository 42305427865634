import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useRatesAssignTerminals } from '../../hooks/apiHooks/rate';
import { getInAddressTerminals } from '../../library/util';
import { Carrier } from '../../models/Carrier';
import { Rate } from '../../models/Rate';

interface IProps {
  carrier?: Carrier;
  rates: Rate[];
  onClose: () => void;
  onSubmit: () => void;
}

type FormState = {
  originTerminals: Array<number>;
  destinationTerminals: Array<number>;
};

const MutipleRateTerminalAssignModal: React.FC<IProps> = ({
  carrier,
  onClose,
  onSubmit,
  rates,
}) => {
  const { isLoading, isSuccess, mutate } = useRatesAssignTerminals();

  const [form] = Form.useForm<FormState>();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const isOriginSame = useMemo(
    () =>
      rates.every(
        (rate, index, arr) =>
          rate.origin === arr[0].origin && rate.shipTypeId === 1
      ),
    [rates]
  );
  const isDestinationSame = useMemo(
    () =>
      rates.every(
        (rate, index, arr) =>
          rate.destination === arr[0].destination &&
          rate.destinationShipTypeId === 1
      ),
    [rates]
  );

  const submitModal = useCallback(
    (formState: FormState) => {
      if (isOriginSame) {
        mutate([
          rates.map((rate) => rate.id),
          formState.originTerminals,
          'origin',
          carrier?.id,
        ]);
      }
      if (isDestinationSame) {
        mutate([
          rates.map((rate) => rate.id),
          formState.destinationTerminals,
          'destination',
          carrier?.id,
        ]);
      }
    },
    [carrier?.id, isOriginSame, isDestinationSame, mutate, rates]
  );

  useEffect(() => {
    if (isSuccess) {
      onSubmit();
    }
  }, [isSuccess, onSubmit]);

  const multiplesField = () => {
    return (
      <Col span={8}>
        <Form.Item label="Multiple">
          <Select disabled placeholder="Multiple"></Select>
        </Form.Item>
      </Col>
    );
  };

  const setTerminalsField = (isOrigin: boolean) => {
    return (
      <Col span={12}>
        <Form.Item
          label={`${
            isOrigin
              ? 'Origin: ' + rates[0].origin
              : 'Destination: ' + rates[0].destination
          } (${rates.length})`}
          name={`${isOrigin ? 'originTerminals' : 'destinationTerminals'}`}
        >
          <Select
            mode="multiple"
            optionLabelProp="label"
            placeholder="Select Terminals"
            style={{ width: '100%' }}
          >
            {getInAddressTerminals(
              carrier?.terminals,
              isOrigin ? rates[0].origin : rates[0].destination
            ).map((terminal) => (
              <Select.Option
                key={terminal.id}
                label={terminal.name}
                value={terminal.id}
              >
                <Typography.Text className="terminal-assign-modal__terminal-name">
                  {terminal.name}
                </Typography.Text>
                <Typography.Text
                  className="terminal-assign-modal__terminal-info"
                  type="secondary"
                >
                  {terminal.address1} {terminal.address2}, {terminal.city}{' '}
                  {terminal.state}, {terminal.zipCode}
                </Typography.Text>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    );
  };

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title="Assign Terminal"
      visible={true}
      width={800}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          {isOriginSame && setTerminalsField(true)}
          {!isOriginSame && multiplesField()}
          {isDestinationSame && setTerminalsField(false)}
          {!isDestinationSame && multiplesField()}
        </Row>

        <Row gutter={24}>
          <Col span={20}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={isLoading}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MutipleRateTerminalAssignModal;
