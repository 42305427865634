import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useCallback, useEffect } from 'react';

// import { Carrier } from '../../models/Carrier';
import { PROVINCES } from '../../models/Constants';

/*
interface IProps {
  selectedCarrier?: Carrier;
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (carrier: Carrier) => void;
}
*/

const CarrierModal = ({
  error,
  onClose,
  onSubmit,
  selectedCarrier,
  submitting,
}) => {
  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (carrier) => {
      if (selectedCarrier) {
        carrier.id = selectedCarrier.id;
      } else {
        carrier.active = true;
      }
      onSubmit(carrier);
    },
    [onSubmit, selectedCarrier]
  );

  useEffect(() => {
    if (selectedCarrier) {
      form.setFieldsValue(selectedCarrier);
    }
  }, [form, selectedCarrier]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title={!selectedCarrier ? 'Add Carrier' : 'Update Carrier'}
      visible={true}
      width={900}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col span={8}>
            <Typography.Paragraph strong>Company Info</Typography.Paragraph>
            <Form.Item
              required
              label="Company Name"
              name="name"
              rules={[
                { message: 'Please input company name!', required: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address 1"
              name="address1"
              rules={[{ message: 'Please input address!', required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Address 2" name="address2">
              <Input />
            </Form.Item>
            <Form.Item
              label="Carrier City & Prov"
              name="city"
              rules={[{ message: 'Please input city!', required: true }]}
            >
              <Input
                addonAfter={
                  <Form.Item
                    noStyle
                    name="state"
                    rules={[{ message: 'Select province!', required: true }]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        // The passed option only has the two-letter code as the value, but filtering by the full name is also useful.
                        // We have to poke around in the react structure to find the full text value.
                        // This is a bit of a hack and might break if the structure underneath changes or antd is updated etc.
                        let provinceAbbrev = option?.value.toLowerCase();
                        let provinceName =
                          option?.children.props.title.toLowerCase();
                        let abbrevMatch =
                          provinceAbbrev.indexOf(input.toLowerCase()) >= 0;
                        let nameMatch =
                          provinceName.indexOf(input.toLowerCase()) >= 0;
                        return abbrevMatch || nameMatch;
                      }}
                    >
                      {PROVINCES.map((province, index) => (
                        <Select.Option key={index} value={province.value}>
                          <Tooltip placement="left" title={province.text}>
                            {province.text}
                          </Tooltip>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                className="city-state-input"
              />
            </Form.Item>
            <Form.Item
              label="Postal Code"
              name="zipCode"
              rules={[{ message: 'Please input postal code!', required: true }]}
            >
              <Input style={{ maxWidth: 120 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Typography.Paragraph strong>Contact Info</Typography.Paragraph>
            <Form.Item
              label="Primary Contact Name"
              name="primaryContactName"
              rules={[
                { message: 'Please input contact name!', required: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Primary Email"
              name="primaryEmail"
              rules={[
                {
                  message: 'Please input email!',
                  required: true,
                },
                {
                  message: 'Please input a valid E-mail!',
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row>
              <Col span={14}>
                <Form.Item label="Primary Phone" name="primaryPhone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Ext" name="primaryExt">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Website" name="website">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Typography.Paragraph strong>&nbsp;</Typography.Paragraph>
            <Form.Item
              label="Secondary Contact Name"
              name="secondaryContactName"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Secondary Email" name="secondaryEmail">
              <Input />
            </Form.Item>
            <Row>
              <Col span={14}>
                <Form.Item label="Secondary Phone" name="secondaryPhone">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Ext" name="secondaryExt">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Fax" name="fax">
              <Input />
            </Form.Item>
            <Form.Item name="isTerminal" valuePropName="checked">
              <Checkbox>Is terminal?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CarrierModal;
