import './CPTable.less';

import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

const CPTable: React.FC<TableProps<any>> = (props) => {
  return <Table className="cp-app-list" {...props} />;
};

export default CPTable;
