import { Divider, Skeleton } from 'antd';
import React from 'react';

const PageLoader: React.FC = () => {
  return (
    <>
      <Skeleton active />
      <Divider />
      <Skeleton active />
      <Divider />
      <Skeleton active />
      <Divider />
      <Skeleton active />
    </>
  );
};

export default PageLoader;
