import './CarrierRatingsPanel.less';

import { Button, Col, Form, Row, Select, Slider, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { useCarrierUpdate } from '../../hooks/apiHooks';
import { useRatingUpdate } from '../../hooks/apiHooks/rating';
import { CarrierTier } from '../../models/Rating';
import { UserRole } from '../../models/User';
import PanelHeader from '../common/PanelHeader';
import PermissionsToggle from '../common/PermissionsToggle';

/*
interface IProps {
  id: string;
  carrier: Carrier;
  userRole?: UserRole;
}
*/

const marks = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
};

const CarrierRatingsPanel = ({ carrier, id, userRole }) => {
  const updateMutation = useRatingUpdate();
  const carrierUpdateMutation = useCarrierUpdate();

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (rating) => {
      rating.carrierId = carrier.id;
      if (carrier.rating?.id) {
        rating.id = carrier.rating?.id;
      }
      updateMutation.mutate(rating);
    },
    [carrier.id, carrier.rating?.id, updateMutation]
  );

  const onTogglePermission = useCallback(
    (permission) => {
      const newCarrier = {
        ...carrier,
        ratingPermission: permission,
      };
      carrierUpdateMutation.mutate(newCarrier);
    },
    [carrier, carrierUpdateMutation]
  );

  useEffect(() => {
    if (carrier.rating) {
      form.setFieldsValue(carrier.rating);
    } else {
      form.setFieldsValue({
        carrierTier: CarrierTier.PREFERRED,
        cost: 0,
        damageClaims: 0,
        honesty: 0,
        pastPerformance: 0,
        reliability: 0,
      });
    }
  }, [form, carrier.rating]);

  return (
    <div id={id}>
      <PanelHeader
        title={
          <Space>
            <span>Ratings</span>
            {userRole === UserRole.SUPER_ADMIN && (
              <PermissionsToggle
                adminOnly
                defaultValue={carrier.ratingPermission}
                submitting={carrierUpdateMutation.isLoading}
                onChange={onTogglePermission}
              />
            )}
          </Space>
        }
      />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12} xl={8}>
            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Damage Claims"
              name="damageClaims"
              style={{ maxWidth: 400 }}
            >
              <Slider marks={marks} max={5} min={0} />
            </Form.Item>

            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Past Performance"
              name="pastPerformance"
            >
              <Slider marks={marks} max={5} min={0} />
            </Form.Item>

            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Honesty"
              name="honesty"
            >
              <Slider marks={marks} max={5} min={0} />
            </Form.Item>
          </Col>

          <Col span={12} xl={8}>
            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Reliability"
              name="reliability"
            >
              <Slider marks={marks} max={5} min={0} />
            </Form.Item>

            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Cost"
              name="cost"
            >
              <Slider marks={marks} max={5} min={0} />
            </Form.Item>

            <Form.Item
              className="carrier-ratings-panel__form-item"
              label="Carrier Tier"
              name="carrierTier"
            >
              <Select>
                <Select.Option value={CarrierTier.PREFERRED}>
                  Preferred
                </Select.Option>
                <Select.Option value={CarrierTier.SECOND}>
                  2nd Class
                </Select.Option>
                <Select.Option value={CarrierTier.THIRD}>
                  3rd Class
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={updateMutation.isLoading}
            type="primary"
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CarrierRatingsPanel;
