import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Tag } from 'antd';
import { keys } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import {
  useCarrierTerminalMapUpdate,
  useTerminalCreate,
  useTerminalRemove,
  useTerminalUpdate,
} from '../../hooks/apiHooks';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import PanelHeader from '../common/PanelHeader';
import Toggle from '../common/Toggle';
import TerminalAssignModal from '../modals/TerminalAssignModal';
import TerminalModal from '../modals/TerminalModal';
import CPTable from '../tables/CPTable';

/*
interface ITerminalListProps {
  id?: string;
  terminals: Terminal[];
  carriers: Carrier[];
  carrierTerminalMap: CarrierTerminals;
}
*/

const TerminalList = ({ carrierTerminalMap, carriers, id, terminals }) => {
  const createMutation = useTerminalCreate();
  const updateMutation = useTerminalUpdate();
  const removeMutation = useTerminalRemove();
  const assignMutation = useCarrierTerminalMapUpdate();

  const [isEditing, setIsEditing] = useState(false);
  const [editableTerminal, setEditableTerminal] = useState();
  const [deletableTerminal, setDeletableTerminal] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const onAdd = useCallback(
    (terminal) => {
      createMutation.mutate(terminal);
    },
    [createMutation]
  );

  const onUpdate = useCallback(
    (terminal) => {
      updateMutation.mutate(terminal);
    },
    [updateMutation]
  );

  const onTerminalAssign = useCallback(
    (carrierTerminals) => {
      assignMutation.mutate(carrierTerminals);
    },
    [assignMutation]
  );

  const onToggleActive = useCallback(
    (terminal) => {
      terminal.active = !terminal.active;
      onUpdate(terminal);
      setEditableTerminal(terminal);
    },
    [onUpdate]
  );

  const onDelete = useCallback(() => {
    if (deletableTerminal) {
      removeMutation.mutate(deletableTerminal.id);
    }
  }, [deletableTerminal, removeMutation]);

  const onEdit = useCallback(
    (terminal) => {
      setEditableTerminal(terminal);
      setShowEditModal(true);
    },
    [setEditableTerminal]
  );

  const getTerminalCarriers = useCallback(
    (terminal) => {
      const terminalCarriers = [];
      keys(carrierTerminalMap).forEach((carrierId) => {
        if (carrierTerminalMap[parseInt(carrierId)].includes(terminal.id)) {
          const carrier = carriers.find((c) => c.id === parseInt(carrierId));
          if (carrier) {
            terminalCarriers.push(carrier);
          }
        }
      });
      return terminalCarriers;
    },
    [carrierTerminalMap, carriers]
  );

  useEffect(() => {
    if (!isEditing) {
      setDeletableTerminal(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  useEffect(() => {
    if (assignMutation.isSuccess) {
      setShowAssignModal(false);
    }
  }, [assignMutation.isSuccess]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        render: (_id, terminal) => {
          return (
            <>
              <span>{terminal.name}</span>
              <br />
              {getTerminalCarriers(terminal).map((carrier) => (
                <Tag key={carrier.id} color="magenta" style={{ marginTop: 10 }}>
                  {carrier.name}
                </Tag>
              ))}
            </>
          );
        },
        title: 'Terminal',
      },
      {
        dataIndex: 'address1',
        render: (_id, terminal) => {
          return (
            <>
              <span>{terminal.address1}</span>
              <br />
              {terminal.address2 && (
                <>
                  <span>{terminal.address2}</span>
                  <br />
                </>
              )}
              <span>
                {terminal.city}, {terminal.state} {terminal.zipCode}
              </span>
            </>
          );
        },
        title: 'Address',
      },
      {
        dataIndex: 'contactName',
        render: (_id, terminal) => {
          return (
            <>
              <span>{terminal.contactName}</span>
              <br />
              <PatternFormat
                displayType="text"
                format="###-###-####"
                value={terminal.phone}
              />
              <br />
              <span>{terminal.email}</span>
            </>
          );
        },
        title: 'Contact',
      },
      {
        dataIndex: 'id',
        render: (_id, terminal) => {
          if (isEditing) {
            return (
              <Space>
                <Toggle
                  key="toggle-btn"
                  defaultChecked={terminal.active}
                  disabled={
                    !isEditing ||
                    (updateMutation.isLoading && !!editableTerminal)
                  }
                  loading={
                    updateMutation.isLoading &&
                    editableTerminal?.id === terminal.id
                  }
                  size="small"
                  onChange={() => onToggleActive(terminal)}
                />
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  size="small"
                  onClick={() => onEdit(terminal)}
                />
                <Popconfirm
                  okButtonProps={{ loading: removeMutation.isLoading }}
                  placement="leftBottom"
                  title="Are you sure to delete this terminal?"
                  visible={deletableTerminal?.id === terminal.id}
                  onCancel={() => setDeletableTerminal(undefined)}
                  onConfirm={onDelete}
                >
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    shape="circle"
                    size="small"
                    onClick={() => setDeletableTerminal(terminal)}
                  />
                </Popconfirm>
              </Space>
            );
          }
          return null;
        },
        title: '',
        width: 125,
      },
    ],
    [
      deletableTerminal?.id,
      editableTerminal,
      getTerminalCarriers,
      isEditing,
      onDelete,
      onEdit,
      onToggleActive,
      removeMutation.isLoading,
      updateMutation.isLoading,
    ]
  );

  return (
    <Container id={id}>
      <PanelHeader
        extra={[
          <EditButton
            key="edit-btn"
            color={isEditing ? '#389e0d' : undefined}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'Done' : 'Edit'}
          </EditButton>,
          <EditButton key="assign-btn" onClick={() => setShowAssignModal(true)}>
            Assign
          </EditButton>,
          <Button
            key="add-btn"
            ghost
            style={{ width: 120 }}
            type="primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Terminal
          </Button>,
        ]}
        ghost={false}
        title="Terminals"
      />

      {terminals.length ? (
        <CPTable
          columns={columns}
          dataSource={terminals}
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: true,
          }}
          rowKey={(terminal) => terminal.id}
        />
      ) : null}

      {showAddModal && (
        <TerminalModal
          error={createMutation.error}
          selectedTerminal={undefined}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableTerminal && (
        <TerminalModal
          error={updateMutation.error}
          selectedTerminal={editableTerminal}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
      {showAssignModal && (
        <TerminalAssignModal
          carriers={carriers}
          carrierTerminalMap={carrierTerminalMap}
          error={assignMutation.error}
          submitting={assignMutation.isLoading}
          terminals={terminals}
          onClose={() => setShowAssignModal(false)}
          onSubmit={onTerminalAssign}
        />
      )}
    </Container>
  );
};

export default TerminalList;
