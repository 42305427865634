import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { LENGTH_UNITS } from '../../models/Constants';

const LengthUnitsSelect: React.FC<SelectProps<any>> = (props) => {
  return (
    <Select style={{ width: 70 }} {...props}>
      {LENGTH_UNITS.map((unit) => (
        <Select.Option key={unit} value={unit}>
          {unit}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LengthUnitsSelect;
