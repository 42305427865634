import { Button, Form, Input, notification } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { AuthContext } from '../../contexts/authContext';
// import { AuthenticateProps } from '../../library/cognito';
import ListHeader from '../common/PanelHeader';

/*
interface IProps {
  id?: string;
  title: string;
}
*/

const Panel = styled.div`
  max-width: 350px;
`;

const PasswordChangePanel = ({ id, title }) => {
  const { changePassword } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (changePasswordRequest) => {
      if (changePassword) {
        try {
          setLoading(true);

          await changePassword(changePasswordRequest);

          notification.success({
            message: 'Password Updated Successfully',
          });

          form.resetFields();
        } catch (err) {
          notification.error({
            description: err.message,
            message: 'Password Update Failed',
          });
        }
        setLoading(false);
      }
    },
    [changePassword, form]
  );

  return (
    <Panel id={id}>
      <ListHeader title={title} />
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          hasFeedback
          label="Old Password"
          name="oldPassword"
          rules={[
            { message: 'Please input your old password!', required: true },
            () => ({
              validator(rule, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The password should be at least 8 characters!'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Password"
          name="password"
          rules={[
            { message: 'Please input your password!', required: true },
            () => ({
              validator(rule, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The password should be at least 8 characters!'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          hasFeedback
          dependencies={['password']}
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            { message: 'Please confirm your password!', required: true },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loading} type="primary">
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </Panel>
  );
};

export default PasswordChangePanel;
