import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useCallback } from 'react';

import { AddressType, Terminal } from '../../models/Terminal';

interface IProps {
  terminals: Terminal[];
  type: AddressType;
  onClose: () => void;
  onSubmit: (terminals: Array<number>) => void;
}

const OrgDestTerminalAssignModal: React.FC<IProps> = ({
  onClose,
  onSubmit,
  terminals,
  type,
}) => {
  const [form] = Form.useForm<{ terminals: Array<number> }>();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (formState: { terminals: Array<number> }) => {
      onSubmit(formState.terminals);
    },
    [onSubmit]
  );

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title="Assign Terminal"
      visible={true}
      width={600}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Form.Item label={capitalize(type)} name="terminals">
          <Select
            mode="multiple"
            optionLabelProp="label"
            placeholder="Select Terminals"
            style={{ width: '100%' }}
          >
            {terminals.map((terminal) => (
              <Select.Option
                key={terminal.id}
                label={terminal.name}
                value={terminal.id}
              >
                <Typography.Text className="terminal-assign-modal__terminal-name">
                  {terminal.name}
                </Typography.Text>
                <Typography.Text
                  className="terminal-assign-modal__terminal-info"
                  type="secondary"
                >
                  {terminal.address1} {terminal.address2}, {terminal.city}{' '}
                  {terminal.state}, {terminal.zipCode}
                </Typography.Text>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={24}>
          <Col span={20}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default OrgDestTerminalAssignModal;
