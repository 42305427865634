import './RatesUpload.less';

import { DeleteFilled, InboxOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Result, Steps, Table } from 'antd';
import { flatMap, uniqueId } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { AuthContext } from '../contexts/authContext';
import { useRatesCreate } from '../hooks/apiHooks/rate';
import { getAlphabet } from '../library/util';
import { UserRole } from '../models/User';

/*
interface IProps {
  selectedCarrier: Carrier;
  shipTypes: ShipType[];
}
*/

const RatesUpload = ({ selectedCarrier, shipTypes }) => {
  const { authUser } = useContext(AuthContext);
  const createRatesMutation = useRatesCreate();

  const history = useHistory();
  const modes = useMemo(
    () => selectedCarrier.modes?.filter((mode) => mode.active) || [],
    [selectedCarrier.modes]
  );
  const classes = useMemo(
    () => selectedCarrier.classes || [],
    [selectedCarrier.classes]
  );
  const [step, setStep] = useState(0);
  const [excelFile, setExcelFile] = useState();
  const [rates, setRates] = useState();
  const [reading, setReading] = useState(false);
  const [fileValid, setFileValid] = useState(false);

  const ratesTableLink = useMemo(() => {
    if (authUser?.role !== UserRole.CARRIER) {
      return `/carrier/${selectedCarrier.id}/`;
    }
    return '/';
  }, [authUser?.role, selectedCarrier.id]);

  const reset = useCallback(() => {
    setFileValid(false);
    setReading(false);
    setExcelFile(undefined);
    setRates(undefined);
    setStep(0);
  }, []);

  useEffect(() => {
    if (step === 1 && excelFile && selectedCarrier) {
      setReading(true);

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const fileData = new Uint8Array(e.target?.result);
        const workbook = XLSX.read(fileData, { type: 'array' });

        const data = flatMap(Object.values(workbook.Sheets), (sheet) =>
          XLSX.utils.sheet_to_json(sheet)
        );

        const rateInputs = [];

        if (data.length) {
          const valid = data.every((fileRecord) => {
            const keys = Object.keys(fileRecord);
            if (keys[0] === Object.keys(data[0])[0]) {
              const origin = fileRecord[keys[0]];
              const shipTypeId = shipTypes.find(
                (shipType) =>
                  shipType.name.toLowerCase() ===
                  fileRecord[keys[1]]?.toLowerCase()
              )?.id;
              if (!shipTypeId) {
                return false;
              }
              const destination = fileRecord[keys[2]];
              const csvDestinationShipTypes = fileRecord[keys[3]]?.split('/');
              const modeId = modes.find(
                (mode) =>
                  mode.name.toLowerCase() === fileRecord[keys[4]]?.toLowerCase()
              )?.id;

              if (!modeId) {
                return false;
              }

              for (let i = 0; i < csvDestinationShipTypes.length; i++) {
                const destinationShipTypeId = shipTypes.find(
                  (shipType) =>
                    shipType.name.toLowerCase() ===
                    csvDestinationShipTypes[i].toLowerCase()
                )?.id;
                if (!destinationShipTypeId) {
                  return false;
                }

                const classRate = {};
                classes.forEach((c, index) => {
                  classRate[c.id] = { value: fileRecord[keys[5 + index]] };
                });
                const rateInput = {
                  active: true,
                  carrierId: selectedCarrier.id,
                  classRate,
                  destination,
                  destinationShipTypeId,
                  id: 0,
                  modeId,
                  origin,
                  shipTypeId,
                };
                rateInputs.push(rateInput);
              }
            }
            return true;
          });
          setFileValid(valid);
        }
        setRates(rateInputs);
        setReading(false);
      };

      fileReader.readAsArrayBuffer(excelFile);
    }
  }, [step, excelFile, selectedCarrier, shipTypes, modes, classes]);

  const columns = useMemo(() => {
    const tableColumns = [
      {
        dataIndex: 'origin',
        title: 'Origin',
      },
      {
        dataIndex: 'shipTypeId',
        render: (shipTypeId) => {
          return shipTypes.find((shipType) => shipType.id === shipTypeId)?.name;
        },
        title: 'Org Type',
      },
      {
        align: 'center',
        dataIndex: 'swapIcon',
        render: () => <SwapOutlined />,
        title: '',
        width: 20,
      },
      {
        dataIndex: 'destination',
        title: 'Destination',
      },
      {
        dataIndex: 'destinationShipTypeId',
        render: (shipTypeId) => {
          return shipTypes.find((shipType) => shipType.id === shipTypeId)?.name;
        },
        title: 'Dest Type',
      },
      {
        dataIndex: 'modeId',
        render: (modeId) => {
          return modes.find((mode) => mode.id === modeId)?.name;
        },
        title: 'Mode',
      },
    ];
    classes.forEach((c, index) => {
      tableColumns.push({
        dataIndex: ['classRate', index],
        render: (value, record) => {
          return record.classRate[Object.keys(record.classRate)[index]];
        },
        title: `Class ${getAlphabet(index)}`,
      });
    });

    return tableColumns;
  }, [classes, shipTypes, modes]);

  const onUpload = useCallback(() => {
    if (!reading && rates?.length) {
      createRatesMutation.mutate(rates);
    }
  }, [createRatesMutation, rates, reading]);

  useEffect(() => {
    if (createRatesMutation.isSuccess) {
      setStep(2);
    }
  }, [createRatesMutation.isSuccess]);

  const steps = useMemo(
    () => [
      {
        content: (
          <>
            <Dropzone
              onDrop={(acceptedFiles) => setExcelFile(acceptedFiles[0])}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()} className="ant-upload ant-upload-drag">
                  <input
                    {...getInputProps()}
                    accept=".csv,.xls,.xlsx"
                    multiple={false}
                  />
                  <span
                    className="ant-upload ant-upload-btn"
                    style={{ padding: '76px 0' }}
                  >
                    <div className="ant-upload-drag-container">
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag files to this area to upload rates
                      </p>
                      <p className="ant-upload-hint">.csv, .xls, .xlsx</p>
                    </div>
                  </span>
                </div>
              )}
            </Dropzone>
            {excelFile && (
              <div>
                {excelFile.name}
                <Button
                  danger
                  icon={<DeleteFilled />}
                  size="small"
                  type="text"
                  onClick={() => setExcelFile(undefined)}
                />
              </div>
            )}
            <div className="steps-action">
              <Button
                className="rates-upload__step-button"
                disabled={!excelFile}
                type="primary"
                onClick={() => setStep(1)}
              >
                Next
              </Button>
            </div>
          </>
        ),
        title: 'Select File',
      },
      {
        content: (
          <>
            {!reading && !fileValid ? (
              <Result
                extra={
                  <Button key="console" type="primary" onClick={reset}>
                    Go Back
                  </Button>
                }
                status="warning"
                title="There are some problems with the file."
              />
            ) : (
              <>
                <Table
                  columns={columns}
                  dataSource={rates}
                  rowKey={() => uniqueId()}
                />
                <div className="steps-action">
                  <Button
                    className="rates-upload__step-button"
                    disabled={!excelFile}
                    loading={createRatesMutation.isLoading}
                    type="primary"
                    onClick={onUpload}
                  >
                    Confirm Upload
                  </Button>
                  <Button
                    className="rates-upload__step-button"
                    style={{ margin: '0 8px' }}
                    onClick={() => setStep(0)}
                  >
                    Go Back
                  </Button>
                </div>
              </>
            )}
          </>
        ),
        title: 'File Verification',
      },
      {
        content: (
          <Result
            extra={[
              <Button
                key={1}
                type="primary"
                onClick={() => history.push(ratesTableLink)}
              >
                Check Rates Table
              </Button>,
              <Button key={2} onClick={reset}>
                Upload Again
              </Button>,
            ]}
            status="success"
            title="Successfully Uploaded Carrier Rates!"
          />
        ),
        title: 'Upload',
      },
    ],
    [
      columns,
      createRatesMutation.isLoading,
      excelFile,
      fileValid,
      history,
      onUpload,
      rates,
      ratesTableLink,
      reading,
      reset,
    ]
  );

  if (!selectedCarrier) {
    return null;
  }

  return (
    <>
      <Steps current={step} style={{ marginTop: 20 }}>
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className="rates-upload__step-content">{steps[step].content}</div>
    </>
  );
};

export default RatesUpload;
