import axios from 'axios';

import { ShipType } from '../models/ShipType';

const uri = '/shipType';

const create = async (shipType: ShipType) => {
  const { data } = await axios.post<ShipType>(`${uri}`, shipType);
  return data;
};

const get = (id: number) => {
  return axios.get<ShipType>(`${uri}/${id}`);
};

const getAll = async () => {
  const { data } = await axios.get<ShipType[]>(`${uri}`);
  return data;
};

const update = async (shipType: ShipType) => {
  const { data } = await axios.put<ShipType>(`${uri}/${shipType.id}`, shipType);
  return data;
};

const remove = async (id: number) => {
  await axios.delete(`${uri}/${id}`);
};

const apis = {
  create,
  get,
  getAll,
  remove,
  update,
};

export default apis;
