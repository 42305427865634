import { Fragment } from 'react';

const MultilineText = ({ text }) => {
  const splitContent = text.split('\n').map((item, key) => {
    return (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    );
  });

  return <p>{splitContent}</p>;
};

export default MultilineText;
