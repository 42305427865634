import { DownOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Dropdown, Menu, Row, Space } from 'antd';
import { keyBy, keys } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';

import AddRate from '../components/AddRate';
import RatesFilter from '../components/filters/RatesFilter';
import RateTable from '../components/tables/RateTable';
import { AuthContext } from '../contexts/authContext';
import { useFileExport } from '../hooks';
import {
  useDefaultModes,
  useSelectedCarrier,
  useShipTypes,
} from '../hooks/apiHooks';
import { useRates } from '../hooks/apiHooks/rate';
import Layout from '../layout';
import { isLocationMatch } from '../models/Rate';
import { UserRole } from '../models/User';

const CarrierRatesPage = ({ routes }) => {
  const queryClient = useQueryClient();
  const { authUser } = useContext(AuthContext);
  const { carrierId, selectedCarrier, status } = useSelectedCarrier();
  const { allShipTypes } = useShipTypes();
  const { defaultModes } = useDefaultModes();
  const { isLoading, rates } = useRates(carrierId);
  const { exportToCsv, exportToExcel, exportToPdf, isExporting } =
    useFileExport();

  const [filter, setFilter] = useState(null);

  const isAdmin = useMemo(
    () => [UserRole.SUPER_ADMIN, UserRole.ADMIN].includes(authUser.role),
    [authUser.role]
  );

  const filteredRates = useMemo(() => {
    if (!filter) return rates;
    // Check if it's a unified search
    const isUnifiedSearch = filter.searchType === 'unified';
    return rates.filter(
      (rate) =>
        (!filter.carrierId || rate.carrierId === filter.carrierId) &&
        (!filter.modeName || rate.modeName === filter.modeName) &&
        (!filter.origin ||
          isLocationMatch(rate, filter.origin, true, isUnifiedSearch)) &&
        (!filter.destination ||
          isLocationMatch(rate, filter.destination, false, false))
    );
  }, [filter, rates]);

  const keyedShipTypes = useMemo(
    () => keyBy(allShipTypes, (shipType) => shipType.id),
    [allShipTypes]
  );
  const keyedCarrierModes = useMemo(
    () => keyBy(selectedCarrier?.modes, (mode) => mode.id),
    [selectedCarrier?.modes]
  );

  const exportData = useMemo(() => {
    return rates
      .map((rate) => {
        const classRates = {};
        if (selectedCarrier && selectedCarrier.classes) {
          // csv export requires blank entry for every possible rate class, so we need to add a matching number or a blank
          selectedCarrier.classes.forEach((k) => {
            let rateMatch = rate.classRate[k.id] || '';
            rate.classRate[Number(k.id)] = rateMatch;
          });

          keys(rate.classRate || {}).forEach((k) => {
            classRates[`class-${k}`] = rate.classRate[Number(k)];
          });
        }

        return {
          destType: keyedShipTypes[rate.destinationShipTypeId]?.name,
          destination: rate.destination,
          mode: keyedCarrierModes[rate.modeId]?.name,
          orgType: keyedShipTypes[rate.shipTypeId]?.name,
          origin: rate.origin,
          ...classRates,
          updatedAt: rate.updatedAt
            ? new Date(rate.updatedAt).toLocaleDateString('en-US')
            : '',
        };
      })
      .sort((a, b) =>
        `${a.origin} ${a.destination}` < `${b.origin} ${b.destination}` ? -1 : 1
      );
  }, [keyedCarrierModes, keyedShipTypes, rates, selectedCarrier]);

  const exportColumns = useMemo(() => {
    if (selectedCarrier && selectedCarrier.classes) {
      return [
        {
          selector: 'origin',
          title: 'Origin',
        },
        {
          selector: 'orgType',
          title: 'Org Type',
        },
        {
          selector: 'destination',
          title: 'Destination',
        },
        {
          selector: 'destType',
          title: 'Dest Type',
        },
        {
          selector: 'mode',
          title: 'Mode',
        },
        ...selectedCarrier.classes.map((k) => ({
          selector: `class-${k.id}`,
          title: k.name,
        })),
      ];
    }
  }, [selectedCarrier]);

  const onExport = useCallback(
    (type) => {
      const config = {
        columns: exportColumns,
        data: exportData,
        filename: selectedCarrier?.name || 'Carrier Rates',
        title: selectedCarrier?.name || '',
      };
      if (type === 'CSV') {
        exportToCsv(config);
      }

      if (type === 'XLSX' || type === 'XLS') {
        exportToExcel({
          ...config,
          type,
        });
      }
      if (type === 'PDF') {
        exportToPdf(config);
      }
    },
    [
      exportColumns,
      exportData,
      exportToCsv,
      exportToExcel,
      exportToPdf,
      selectedCarrier?.name,
    ]
  );

  useEffect(() => {
    queryClient.invalidateQueries(['rates', carrierId]);
  }, [carrierId, queryClient]);

  if (status === 'error') {
    return <Redirect to="/" />;
  }

  return (
    <Layout
      disableLoading
      menuIndex={isAdmin ? 1 : 0}
      routes={routes}
      showCarriersNavigation={isAdmin}
    >
      <PageHeader
        className="site-page-header"
        extra={
          <Row>
            {authUser?.role !== UserRole.CUSTOMER && (
              <Dropdown
                overlay={
                  <Menu onClick={(e) => onExport(e.key)}>
                    <Menu.Item key="CSV">CSV</Menu.Item>
                    <Menu.Item key="XLS">XLS</Menu.Item>
                    <Menu.Item key="XLSX">XLSX</Menu.Item>
                    <Menu.Item key="PDF">PDF</Menu.Item>
                  </Menu>
                }
                placement="bottomRight"
                trigger={['click']}
              >
                <Button loading={isExporting}>
                  Export <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </Row>
        }
        title={selectedCarrier ? `Rates for ${selectedCarrier.name}` : ''}
      />

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {authUser?.role !== UserRole.CUSTOMER && (
          <AddRate selectedCarrier={selectedCarrier} shipTypes={allShipTypes} />
        )}
        <RatesFilter
          filter={filter}
          modes={defaultModes}
          onFilterChange={setFilter}
        />
        <RateTable
          isEditable={authUser?.role !== UserRole.CUSTOMER}
          loading={isLoading && !rates.length}
          rates={filteredRates}
          selectedCarrier={selectedCarrier}
          shipTypes={allShipTypes}
        />
        <p style={{ textAlign: 'right' }}>{filteredRates.length} rates</p>
      </Space>
    </Layout>
  );
};

export default CarrierRatesPage;
