import './CarrierDetail.less';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Space } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../contexts/authContext';
import {
  useCarrierUpdate,
  useCreateCarrierNote,
  useDeleteCarrierNote,
} from '../../hooks/apiHooks';
import Container from '../common/Container';
import ListHeader from '../common/ListHeader';
import MultilineText from '../common/MultilineText';
import NoteModalCarrier from '../modals/NoteModalCarrier';

/*
interface IProps {
  id: string;
  carrier: Carrier; 
}
*/

const CarrierNotesPanel = ({ carrier, id }) => {
  const updateMutation = useCarrierUpdate();

  const [showingNoteModal, setShowingNoteModal] = useState(false);

  const { authUser } = useContext(AuthContext);

  const createNoteMutation = useCreateCarrierNote();
  const deleteNoteMutation = useDeleteCarrierNote();

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowingNoteModal(false);
    }
  }, [updateMutation.isSuccess]);

  const handleNoteSubmit = async (note) => {
    if (carrier) {
      note.carrierId = carrier.id;
      if (authUser) {
        note.author = authUser;
      }
      // author is officially set on the server side but for display we can add current user here
      createNoteMutation.mutate(note);
      carrier.notes?.unshift(note);
      setShowingNoteModal(false);
    }
  };

  const handleNoteDelete = async (noteId) => {
    deleteNoteMutation.mutate(noteId);
    if (carrier && carrier.notes) {
      // delete note with matching ID from list
      let newNotes = carrier.notes.filter((note) => note.id !== noteId);
      carrier.notes = newNotes;
    }
  };
  return (
    <Container id={id}>
      <Space direction="vertical" size="middle">
        <Descriptions
          colon={false}
          column={4}
          layout="vertical"
          title={
            <ListHeader>
              <Space direction="horizontal">
                <span>Notes</span>
              </Space>

              <Space>
                <Button
                  ghost
                  style={{ width: 120 }}
                  type="primary"
                  onClick={() => setShowingNoteModal(true)}
                >
                  Add Note
                </Button>
              </Space>
            </ListHeader>
          }
        >
          <Card style={{ flexDirection: 'column' }}>
            {carrier.notes?.map((nextNote) => {
              const nextName = nextNote.author
                ? `${nextNote.author.firstName} ${nextNote.author.lastName}`
                : '(Author)';
              let timeString = (
                nextNote.createdAt || new Date().toString()
              ).toString();

              let newTimeString = moment(timeString).format('YYYY-MM-DD HH:mm');

              const formattedTime = newTimeString;
              return (
                <div
                  key={nextNote.id || 'new'}
                  style={{
                    borderBottom: 'solid 1px grey',
                    margin: 12,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ flex: 3 }}>
                      <b>{nextName}</b>
                      <span style={{ color: 'grey' }}> {formattedTime} </span>
                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>
                      <Button
                        icon={<DeleteOutlined />}
                        shape="circle"
                        size="small"
                        onClick={() => handleNoteDelete(nextNote.id)}
                      />
                    </div>
                  </div>
                  <MultilineText text={nextNote.content} />
                </div>
              );
            })}
          </Card>
        </Descriptions>
      </Space>
      {showingNoteModal && (
        <NoteModalCarrier
          onClose={() => setShowingNoteModal(false)}
          onSubmit={handleNoteSubmit}
        />
      )}
    </Container>
  );
};

export default CarrierNotesPanel;
