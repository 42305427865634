import { useMutation, useQuery, useQueryClient } from 'react-query';

import contactService from '../../services/contactService';

export const useContacts = () => {
  const { data, isLoading, status } = useQuery('allContacts', () =>
    contactService.getAll()
  );

  return {
    allContacts: data || [],
    isLoading,
    status,
  };
};

export const useContact = (contactId) => {
  const { data, isLoading, status } = useQuery(
    ['contact', contactId],
    () => contactService.getFull(contactId)
  );

  return {
    contact: data,
    isLoading,
    status
  }
}

export const useCreateNote = (noteData) => {
  const queryClient = useQueryClient();

  return useMutation(contactService.createNote, {
    onSuccess: (data) => {
      queryClient.setQueryData('contactnote', noteData);
      return data;
    }
  });
};

export const useDeleteNote = (noteId) => {
  const queryClient = useQueryClient();

  return useMutation(contactService.deleteNote, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        'id',
        noteId
      );
    },
  });
};
