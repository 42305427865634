import './TerminalAssignModal.less';

import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useEffect } from 'react';

import { Carrier } from '../../models/Carrier';
import { CarrierTerminals, Terminal } from '../../models/Terminal';

interface IProps {
  carriers: Carrier[];
  carrierTerminalMap: CarrierTerminals;
  terminals: Terminal[];
  submitting?: boolean;
  error: Error | null;
  onClose: () => void;
  onSubmit: (carrierTerminals: CarrierTerminals) => void;
}

const TerminalAssignModal: React.FC<IProps> = ({
  carrierTerminalMap,
  carriers,
  error,
  onClose,
  onSubmit,
  submitting,
  terminals,
}) => {
  const [form] = Form.useForm<CarrierTerminals>();

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (carrierTerminals: CarrierTerminals) => {
      onSubmit(carrierTerminals);
    },
    [onSubmit]
  );

  useEffect(() => {
    form.setFieldsValue(carrierTerminalMap);
  }, [carrierTerminalMap, form]);

  useEffect(() => {
    if (error) {
      notification.error({
        description: error.message,
        message: error.name,
      });
    }
  }, [error]);

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title="Assign Terminal"
      visible={true}
      width={1200}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        {carriers.map((carrier) => (
          <Form.Item key={carrier.id} label={carrier.name} name={carrier.id}>
            <Select
              mode="multiple"
              optionLabelProp="label"
              placeholder="Select Terminals"
              style={{ width: '100%' }}
            >
              {terminals.map((terminal) => (
                <Select.Option
                  key={terminal.id}
                  label={terminal.name}
                  value={terminal.id}
                >
                  <Typography.Text className="terminal-assign-modal__terminal-name">
                    {terminal.name}
                  </Typography.Text>
                  <Typography.Text
                    className="terminal-assign-modal__terminal-info"
                    type="secondary"
                  >
                    {terminal.address1} {terminal.address2}, {terminal.city}{' '}
                    {terminal.state}, {terminal.zipCode}
                  </Typography.Text>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ))}
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={submitting}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TerminalAssignModal;
