import './AdminUploadPage.less';

import { PageHeader } from '@ant-design/pro-layout';
import { Select, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

import RatesUpload from '../components/RatesUpload';
import { useCarriers, useShipTypes } from '../hooks/apiHooks';
import Layout from '../layout';

const AdminUploadPage = ({ routes }) => {
  const { allCarriers } = useCarriers();
  const { allShipTypes } = useShipTypes();
  const [selectedCarrier, setSelectedCarrier] = useState();

  const onCarrierChange = useCallback(
    (carrierId) => {
      setSelectedCarrier(allCarriers.find((c) => c.id === carrierId));
    },
    [allCarriers]
  );

  return (
    <Layout
      showCarriersNavigation
      disableLoading={false}
      menuIndex={0}
      routes={routes}
    >
      <PageHeader className="site-page-header" title="Upload Rates" />

      <div className="admin-upload-page__carrier-select">
        <Typography.Paragraph>Select Carrier:</Typography.Paragraph>
        <Select value={selectedCarrier?.id} onChange={onCarrierChange}>
          {allCarriers.map((carrier) => (
            <Select.Option key={carrier.id} value={carrier.id}>
              {carrier.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {selectedCarrier && (
        <RatesUpload
          selectedCarrier={selectedCarrier}
          shipTypes={allShipTypes}
        />
      )}
    </Layout>
  );
};

export default AdminUploadPage;
