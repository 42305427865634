import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import * as cognito from '../library/cognito';
import userService from '../services/userService';

export const AUTH_LOADING = 'Loading';
export const AUTH_SIGNEDIN = 'SignedIn';
export const AUTH_SIGNEDOUT = 'SignedOut';

/*
export interface IAuth {
  authStatus?: AuthStatus;
  authUser?: User;
  authUserStatus?: 'idle' | 'error' | 'loading' | 'success';
  isLoading?: boolean;
  failedLogin?: boolean;
  session?: cognito.UserSession;
  signInWithEmail?: (props: AuthenticateProps) => void;
  signOut?: () => void;
  changePassword?: (props: AuthenticateProps) => void;
}

*/
const defaultState = {
  authStatus: AUTH_LOADING,
  authUser: null,
};

export const AuthContext = React.createContext(defaultState);

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);
  const [authStatus, setAuthStatus] = useState(AUTH_LOADING);
  const [userEmail, setUserEmail] = useState();
  const [session, setSession] = useState();
  const { data: authUser, status: authUserStatus } = useQuery(
    ['currentUser', userEmail],
    () => userService.getByEmail(userEmail.toLowerCase()),
    {
      enabled: !!userEmail && !!session,
      retry: 0,
    }
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const [userSession, attributes] = await getSessionAttributes();
        axios.defaults.headers.common['Authorization'] =
          userSession.tokens.IdToken;
        setSession(userSession);
        setUserEmail(attributes['email']);
        setAuthStatus(AUTH_SIGNEDIN);
      } catch (err) {
        setAuthStatus(AUTH_SIGNEDOUT);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AUTH_SIGNEDIN && authUserStatus === 'error') {
    signOut && signOut();
  }

  if (authStatus === AUTH_LOADING) {
    return null;
  }

  function signInWithEmail(props) {
    // cognito.login() converts email to lowercase
    setIsLoading(true);
    setFailedLogin(false);
    cognito
      .login(props)
      .then((session) => {
        axios.defaults.headers.common['Authorization'] = session.tokens.IdToken;
        setSession(session);
        setFailedLogin(false);
        setAuthStatus(AUTH_SIGNEDIN);
      })
      .catch((err) => {
        axios.defaults.headers.common['Authorization'] = null;
        setAuthStatus(AUTH_SIGNEDOUT);
        setFailedLogin(true);
        // cognito errors can include user not found so this should just go back to regular login rather than throw an error here
        //throw err;
      })
      .then(() => {
        setIsLoading(false);
        queryClient.invalidateQueries(['currentUser', props.username]);
      });
  }

  function signOut() {
    axios.defaults.headers.common['Authorization'] = null;
    cognito.logout();
    setAuthStatus(AUTH_SIGNEDOUT);
  }

  async function changePassword(props) {
    await cognito.changePassword(props);
  }

  async function getSessionAttributes() {
    try {
      const sessionAttributs = await cognito.getUserSessionAttributes();
      return sessionAttributs;
    } catch (err) {
      throw err;
    }
  }

  const state = {
    AUTH_LOADING,
    AUTH_SIGNEDIN,
    AUTH_SIGNEDOUT,
    authStatus,
    authUser,
    authUserStatus,
    changePassword,
    failedLogin,
    isLoading,
    session,
    signInWithEmail,
    signOut,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
