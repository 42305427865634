import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Descriptions, Popconfirm, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import {
  useDefaultModeCreate,
  useDefaultModeRemove,
  useDefaultModeUpdate,
} from '../../hooks/apiHooks';
import { DefaultMode } from '../../models/Mode';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import Toggle from '../common/Toggle';
import ModeModal from '../modals/ModeModal';

interface IDefaultModeListProps {
  id?: string;
  modes: DefaultMode[];
}

const DefaultModeList: React.FC<IDefaultModeListProps> = ({ id, modes }) => {
  const createMutation = useDefaultModeCreate();
  const updateMutation = useDefaultModeUpdate();
  const removeMutation = useDefaultModeRemove();

  const [isEditing, setIsEditing] = useState(false);
  const [editableMode, setEditableMode] = useState<DefaultMode>();
  const [deletableMode, setDeletableMode] = useState<DefaultMode>();
  const [showAddModal, setShowAddModal] = useState<boolean>();
  const [showEditModal, setShowEditModal] = useState<boolean>();

  const onAdd = useCallback(
    (mode: DefaultMode) => {
      createMutation.mutate(mode);
    },
    [createMutation]
  );

  const onUpdate = useCallback(
    (mode: DefaultMode) => {
      updateMutation.mutate(mode);
    },
    [updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableMode) {
      removeMutation.mutate(deletableMode.id);
    }
  }, [deletableMode, removeMutation]);

  const onEdit = useCallback((mode: DefaultMode) => {
    setEditableMode(mode);
    setShowEditModal(true);
  }, []);

  const onToggleActive = useCallback(
    (mode: DefaultMode) => {
      mode.active = !mode.active;
      onUpdate(mode);
      setEditableMode(mode);
    },
    [onUpdate]
  );

  useEffect(() => {
    if (!isEditing) {
      setDeletableMode(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  return (
    <Container id={id}>
      <Descriptions
        colon={false}
        column={4}
        layout="vertical"
        title={
          <ListHeader>
            <Space direction="horizontal">
              <span>Default Modes of Transport</span>
            </Space>

            <Space>
              <EditButton
                color={isEditing ? '#389e0d' : undefined}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Done' : 'Edit'}
              </EditButton>
              <Button
                ghost
                style={{ width: 120 }}
                type="primary"
                onClick={() => setShowAddModal(true)}
              >
                Add Mode
              </Button>
            </Space>
          </ListHeader>
        }
      >
        {modes.map((mode, index) => (
          <Descriptions.Item key={index}>
            <Space direction="horizontal">
              <Toggle
                checked={mode.active}
                disabled={
                  !isEditing || (updateMutation.isLoading && !!editableMode)
                }
                loading={
                  updateMutation.isLoading && editableMode?.id === mode.id
                }
                size="small"
                onChange={() => onToggleActive(mode)}
              />
              <Typography.Text strong>{mode.name}</Typography.Text>
              {isEditing && (
                <Space>
                  <Button
                    icon={<EditOutlined />}
                    shape="circle"
                    size="small"
                    onClick={() => onEdit(mode)}
                  />
                  <Popconfirm
                    okButtonProps={{ loading: removeMutation.isLoading }}
                    title="Are you sure to delete this mode?"
                    visible={deletableMode?.id === mode.id}
                    onCancel={() => setDeletableMode(undefined)}
                    onConfirm={onDelete}
                  >
                    <Button
                      danger
                      icon={<CloseOutlined />}
                      shape="circle"
                      size="small"
                      onClick={() => setDeletableMode(mode)}
                    />
                  </Popconfirm>
                </Space>
              )}
            </Space>
          </Descriptions.Item>
        ))}
        {modes.length % 4 === 3 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
        {modes.length % 4 === 2 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
        {modes.length % 4 === 1 && (
          <Descriptions.Item>&nbsp;</Descriptions.Item>
        )}
      </Descriptions>

      {showAddModal && (
        <ModeModal
          error={createMutation.error}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableMode && (
        <ModeModal
          error={updateMutation.error}
          selectedMode={editableMode}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default DefaultModeList;
