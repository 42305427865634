import { useMutation, useQueryClient } from 'react-query';

import { Carrier } from '../../models/Carrier';
import { Insurance } from '../../models/Insurance';
import insuranceService from '../../services/insuranceService';

export const useInsuranceUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Insurance, Error, Insurance>(insuranceService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Carrier | undefined>(
        ['carrier', data.carrierId],
        (carrier?: Carrier) =>
          carrier && {
            ...carrier,
            insurance: data,
          }
      );
    },
  });
};
