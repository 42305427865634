import { CloseCircleFilled, EditFilled } from '@ant-design/icons';
import { Button, Descriptions, Popconfirm, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import {
  useVehicleCreate,
  useVehicleRemove,
  useVehicleUpdate,
} from '../../hooks/apiHooks';
import { Carrier } from '../../models/Carrier';
import { Vehicle } from '../../models/Vehicle';
import Container from '../common/Container';
import EditButton from '../common/EditButton';
import ListHeader from '../common/ListHeader';
import VehicleModal from '../modals/VehicleModal';

interface IProps {
  id: string;
  carrier: Carrier;
  vehicles: Vehicle[];
}

const VehicleList: React.FC<IProps> = ({ carrier, id, vehicles }) => {
  const createMutation = useVehicleCreate();
  const updateMutation = useVehicleUpdate();
  const removeMutation = useVehicleRemove();

  const [isEditing, setIsEditing] = useState(false);
  const [editableVehicle, setEditableVehicle] = useState<Vehicle>();
  const [deletableVehicle, setDeletableVehicle] = useState<Vehicle>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const onAdd = useCallback(
    (vehicle: Vehicle) => {
      vehicle.carrierId = carrier.id;
      createMutation.mutate(vehicle);
    },
    [carrier.id, createMutation]
  );

  const onUpdate = useCallback(
    (vehicle: Vehicle) => {
      updateMutation.mutate(vehicle);
    },
    [updateMutation]
  );

  const onDelete = useCallback(() => {
    if (deletableVehicle) {
      removeMutation.mutate(deletableVehicle);
    }
  }, [deletableVehicle, removeMutation]);

  const onEdit = useCallback((vehicle: Vehicle) => {
    setEditableVehicle(vehicle);
    setShowEditModal(true);
  }, []);

  useEffect(() => {
    if (!isEditing) {
      setDeletableVehicle(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setShowAddModal(false);
    }
  }, [createMutation.isSuccess]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      setShowEditModal(false);
    }
  }, [updateMutation.isSuccess]);

  return (
    <Container id={id}>
      <Descriptions
        colon={false}
        column={7}
        layout="vertical"
        title={
          <ListHeader>
            <Space direction="horizontal">
              <span>Vehicle Types</span>
            </Space>

            <Space>
              <EditButton
                color={isEditing ? '#389e0d' : undefined}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Done' : 'Edit'}
              </EditButton>
              <Button
                ghost
                style={{ width: 120 }}
                type="primary"
                onClick={() => setShowAddModal(true)}
              >
                Add Type
              </Button>
            </Space>
          </ListHeader>
        }
      >
        <Descriptions.Item>
          {vehicles.map((vehicle) => (
            <Tag key={vehicle.id} className="vehicle-tag" color="#262626">
              <Space direction="horizontal">
                {vehicle.name}
                {isEditing && !vehicle.default && (
                  <>
                    <EditFilled onClick={() => onEdit(vehicle)} />
                    <Popconfirm
                      okButtonProps={{ loading: removeMutation.isLoading }}
                      title="Are you sure to delete this type?"
                      visible={deletableVehicle?.id === vehicle.id}
                      onCancel={() => setDeletableVehicle(undefined)}
                      onConfirm={onDelete}
                    >
                      <CloseCircleFilled
                        onClick={() => setDeletableVehicle(vehicle)}
                      />
                    </Popconfirm>
                  </>
                )}
              </Space>
            </Tag>
          ))}
        </Descriptions.Item>
      </Descriptions>

      {showAddModal && (
        <VehicleModal
          error={createMutation.error}
          submitting={createMutation.isLoading}
          onClose={() => setShowAddModal(false)}
          onSubmit={onAdd}
        />
      )}
      {showEditModal && editableVehicle && (
        <VehicleModal
          error={updateMutation.error}
          selectedVehicle={editableVehicle}
          submitting={updateMutation.isLoading}
          onClose={() => setShowEditModal(false)}
          onSubmit={onUpdate}
        />
      )}
    </Container>
  );
};

export default VehicleList;
