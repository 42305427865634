import { Divider, Typography } from 'antd';
import { sortBy } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';

import Toggle from '../components/common/Toggle';
import ClassList from '../components/lists/ClassList';
import ModeList from '../components/lists/ModeList';
import TerminalsServicedList from '../components/lists/TerminalsServicedList';
import VehicleList from '../components/lists/VehicleList';
import CarrierDetail from '../components/panels/CarrierDetail';
import CarrierInsurancePanel from '../components/panels/CarrierInsurancePanel';
import CarrierNotesPanel from '../components/panels/CarrierNotesPanel';
import CarrierOptionsPanel from '../components/panels/CarrierOptionsPanel';
import CarrierRatingsPanel from '../components/panels/CarrierRatingsPanel';
import PasswordChangePanel from '../components/panels/PasswordChangePanel';
import { AuthContext } from '../contexts/authContext';
import {
  useCarrierUpdate,
  useClassVehicleMap,
  useDefaultVehicles,
  useSelectedCarrier,
  useUserPermission,
} from '../hooks/apiHooks';
import Layout from '../layout';
import { UserRole } from '../models/User';

const CarrierSettingsPage = ({ routes }) => {
  const { authUser } = useContext(AuthContext);
  const { selectedCarrier } = useSelectedCarrier();
  const { defaultVehicles } = useDefaultVehicles();
  const { classVehicleMap } = useClassVehicleMap();
  const userHasPermission = useUserPermission();
  const carrierUpdateMutation = useCarrierUpdate();

  const isAdmin = useMemo(
    () => [UserRole.SUPER_ADMIN, UserRole.ADMIN].includes(authUser.role),
    [authUser.role]
  );

  const carrierModes = useMemo(
    () => sortBy(selectedCarrier?.modes, (mode) => mode.defaultModeId),
    [selectedCarrier]
  );
  const carrierVehicles = useMemo(
    () => [...defaultVehicles, ...(selectedCarrier?.vehicles || [])],
    [defaultVehicles, selectedCarrier?.vehicles]
  );
  const carrierTerminals = useMemo(
    () => selectedCarrier?.terminals || [],
    [selectedCarrier?.terminals]
  );

  const toggleCarrierActive = useCallback(() => {
    if (selectedCarrier) {
      carrierUpdateMutation.mutate({
        ...selectedCarrier,
        active: !selectedCarrier.active,
      });
    }
  }, [carrierUpdateMutation, selectedCarrier]);

  return (
    <Layout
      menuIndex={isAdmin ? 1 : 0}
      routes={routes}
      showCarriersNavigation={isAdmin}
    >
      {!selectedCarrier ? null : (
        <>
          <div className="carrier-title">
            <Typography.Text>{selectedCarrier.name}</Typography.Text>
            {authUser?.role === UserRole.SUPER_ADMIN && (
              <Toggle
                defaultChecked={selectedCarrier.active}
                loading={carrierUpdateMutation.isLoading}
                size="small"
                onChange={toggleCarrierActive}
              />
            )}
          </div>

          <CarrierDetail carrier={selectedCarrier} id="profile" />
          <Divider />

          {userHasPermission(selectedCarrier, 'modePermission') && (
            <>
              <ModeList
                carrier={selectedCarrier}
                id="modes"
                modes={carrierModes || []}
                userRole={authUser?.role}
              />
              <Divider />
            </>
          )}

          {userHasPermission(selectedCarrier, 'classPermission') && (
            <>
              <ClassList
                carrier={selectedCarrier}
                classVehicleMap={classVehicleMap}
                id="rate-class"
                userRole={authUser?.role}
                vehicles={carrierVehicles}
              />
              <Divider />

              <VehicleList
                carrier={selectedCarrier}
                id="vehicle-types"
                vehicles={carrierVehicles}
              />
              <Divider />
            </>
          )}

          {userHasPermission(selectedCarrier, 'optionPermission') && (
            <>
              <CarrierOptionsPanel
                carrier={selectedCarrier}
                id="options"
                userRole={authUser?.role}
              />
              <Divider />
            </>
          )}

          <TerminalsServicedList id="terminals" terminals={carrierTerminals} />
          <Divider />

          {userHasPermission(selectedCarrier, 'insurancePermission') && (
            <>
              <CarrierInsurancePanel
                carrier={selectedCarrier}
                id="insurance"
                userRole={authUser?.role}
              />
              <Divider />
            </>
          )}

          {userHasPermission(selectedCarrier, 'ratingPermission') && (
            <>
              <CarrierRatingsPanel
                carrier={selectedCarrier}
                id="ratings"
                userRole={authUser?.role}
              />
              <Divider />
            </>
          )}

          {userHasPermission(selectedCarrier, 'notesPermission') && (
            <>
              <CarrierNotesPanel
                carrier={selectedCarrier}
                id="notes"
                userRole={authUser?.role}
              />
            </>
          )}

          {authUser?.role === UserRole.CARRIER && (
            <PasswordChangePanel id="user" title="Change User Password" />
          )}
        </>
      )}
    </Layout>
  );
};

export default CarrierSettingsPage;
