import { useQuery } from 'react-query';

import shipCarsService from '../../services/shipCarsService';

export const useYears = () => {
  const { data, isLoading, status } = useQuery('years', () =>
    shipCarsService.getYears()
  );

  return {
    isLoading,
    status,
    years: data || [],
  };
};

export const useMakes = (year?: number) => {
  const { data, isLoading, status } = useQuery(['makes', year], () =>
    shipCarsService.getMakes(year)
  );

  return {
    isLoading,
    makes: data || [],
    status,
  };
};

export const useModels = (year?: number, make?: string) => {
  const { data, isLoading, status } = useQuery(['models', year, make], () =>
    shipCarsService.getModels(year, make)
  );

  return {
    isLoading,
    models: data || [],
    status,
  };
};
