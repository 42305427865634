import axios from 'axios';

import { Make, Model, Year } from '../models/ShipCars';

const baseUrl = 'https://done.ship.cars';

const getYears = async () => {
  const { data } = await axios.get<Year[]>(`${baseUrl}/years`);

  return data;
};

const getMakes = async (year?: number) => {
  if (!year) {
    return [];
  }

  const { data } = await axios.get<Make[]>(`${baseUrl}/makes`, {
    params: {
      year,
    },
  });

  return data;
};

const getModels = async (year?: number, make?: string) => {
  if (!year || !make) {
    return [];
  }

  const { data } = await axios.get<Model[]>(`${baseUrl}/models`, {
    params: {
      make,
      year,
    },
  });

  return data.filter((m) => m.year === year);
};

const apis = {
  getMakes,
  getModels,
  getYears,
};

export default apis;
