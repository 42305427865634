import axios from 'axios';

import { User } from '../models/User';

const uri = '/user';

const create = async (user: User) => {
  const { data } = await axios.post<User>(`${uri}`, user);
  return data;
};

const getByEmail = async (email: string) => {
  const { data } = await axios.get<User>(`${uri}/email/${email}`);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<User[]>(`${uri}`);
  return data;
};

const update = async (user: User) => {
  const { data } = await axios.put<User>(`${uri}/${user.id}`, user);
  return data;
};

const remove = async (id: number) => {
  const { data } = await axios.delete(`${uri}/${id}`);
  return data;
};

const apis = {
  create,
  getAll,
  getByEmail,
  remove,
  update,
};

export default apis;
