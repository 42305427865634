import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

interface IProps {
  value?: string;
  onChange?: (value: string) => void;
}

const AddressSearchInput: React.FC<IProps> = ({
  onChange,
  value: defaultValue,
}) => {
  const [address, setAddress] = useState('');

  const handleChange = (value: string) => {
    setAddress(value);
    if (onChange) {
      onChange(value);
    }
  };

  const getFormattedAddress = (value: string) => {
    let newValue = value;
    // remove ", canada" from ending
    const endValue = ', canada';
    if (value.toLowerCase().endsWith(endValue)) {
      newValue = value.substr(0, value.length - endValue.length);
    }

    // for some reason Google's Places API returns full length "Nova Scotia" and "Prince Edward Island" but abbreviations for every other province/territory
    // so replace them with the proper abbreviations.
    newValue = newValue.replace('Nova Scotia', 'NS');
    newValue = newValue.replace('Prince Edward Island', 'PE');

    return newValue;
  };

  useEffect(() => {
    if (defaultValue) {
      setAddress(defaultValue);
    }
  }, [defaultValue]);

  return (
    <PlacesAutocomplete
      searchOptions={{
        componentRestrictions: {
          country: 'ca',
        },
        types: ['(regions)'],
      }}
      value={address}
      onChange={handleChange}
    >
      {({ getInputProps, loading, suggestions }) => (
        <AutoComplete
          notFoundContent={
            loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin
                  indicator={<LoadingOutlined spin style={{ fontSize: 20 }} />}
                />
              </div>
            ) : null
          }
          placeholder="Address"
          style={{ minWidth: 150 }}
          value={address}
          onSearch={(value) => getInputProps().onChange({ target: { value } })}
          onSelect={handleChange}
        >
          {suggestions.map((suggestion, index) => (
            <AutoComplete.Option
              key={index}
              value={getFormattedAddress(suggestion.description)}
            >
              {getFormattedAddress(suggestion.description)}
            </AutoComplete.Option>
          ))}
        </AutoComplete>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressSearchInput;
