import { PageHeader } from '@ant-design/pro-layout';
import React from 'react';

import RatesUpload from '../components/RatesUpload';
import { useSelectedCarrier, useShipTypes } from '../hooks/apiHooks';
import Layout from '../layout';

const CarrierUploadPage = ({ routes }) => {
  const { selectedCarrier } = useSelectedCarrier();
  const { allShipTypes } = useShipTypes();

  return (
    <Layout
      showCarriersNavigation
      disableLoading={false}
      menuIndex={0}
      routes={routes}
    >
      <PageHeader className="site-page-header" title="Upload Rates" />

      {selectedCarrier && (
        <RatesUpload
          selectedCarrier={selectedCarrier}
          shipTypes={allShipTypes}
        />
      )}
    </Layout>
  );
};

export default CarrierUploadPage;
