import axios from 'axios';

import { Terminal } from '../models/Terminal';
import { CarrierTerminals } from '../models/Terminal';

const uri = '/terminal';

const create = async (terminal: Terminal) => {
  const { data } = await axios.post<Terminal>(`${uri}`, terminal);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<Terminal[]>(`${uri}`);
  return data;
};

const update = async (terminal: Terminal) => {
  const { data } = await axios.put<Terminal>(`${uri}/${terminal.id}`, terminal);
  return data;
};

const remove = async (id: number) => {
  const { data } = await axios.delete(`${uri}/${id}`);
  return data;
};

const assignToCarrier = (carrierTerminal: CarrierTerminals) => {
  return axios.post<void>(`${uri}/assign`, carrierTerminal);
};

const apis = {
  assignToCarrier,
  create,
  getAll,
  remove,
  update,
};

export default apis;
