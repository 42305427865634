import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { useRateAssignTerminals } from '../../hooks/apiHooks/rate';
import { getInAddressTerminals } from '../../library/util';
import { RateTableContext } from '../tables/RateTable';

/*
interface IProps {
  carrier?: Carrier;
  terminals: Terminal[];
  rate: Rate;
  onClose: () => void;
}
*/

/*
type FormState = {
  originTerminals: Array<number>;
  destinationTerminals: Array<number>;
};
*/

const RateTerminalAssignModal = ({ carrier, onClose, rate, terminals }) => {
  const { shipTypes } = useContext(RateTableContext);
  const { isLoading, isSuccess, mutate } = useRateAssignTerminals();

  const [form] = Form.useForm();

  const originShipType = useMemo(
    () => shipTypes.find((s) => s.id === rate.shipTypeId),
    [rate.shipTypeId, shipTypes]
  );
  const destinationShipType = useMemo(
    () => shipTypes.find((s) => s.id === rate.destinationShipTypeId),
    [rate.destinationShipTypeId, shipTypes]
  );

  const closeModal = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const submitModal = useCallback(
    (formState) => {
      mutate([
        rate.id,
        formState.originTerminals,
        formState.destinationTerminals,
        carrier?.id,
      ]);
    },
    [carrier?.id, mutate, rate.id]
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  useEffect(() => {
    form.setFieldsValue({
      destinationTerminals: rate.destinationTerminals || [],
      originTerminals: rate.originTerminals || [],
    });
  }, [form, rate.destinationTerminals, rate.originTerminals]);

  // target can be 'origin' or 'destination'
  const terminalSelect = useCallback(
    (shipType, target) => (
      <Select
        disabled={shipType.name !== 'Terminal'}
        mode="multiple"
        optionLabelProp="label"
        placeholder={
          shipType.name !== 'Terminal' ? shipType.name : 'Select Terminals'
        }
        style={{ width: '100%' }}
      >
        {getInAddressTerminals(terminals, rate[target]).map((terminal) => (
          <Select.Option
            key={terminal.id}
            label={terminal.name}
            value={terminal.id}
          >
            <Typography.Text className="terminal-assign-modal__terminal-name">
              {terminal.name}
            </Typography.Text>
            <Typography.Text
              className="terminal-assign-modal__terminal-info"
              type="secondary"
            >
              {terminal.address1} {terminal.address2}, {terminal.city}{' '}
              {terminal.state}, {terminal.zipCode}
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    ),
    [rate, terminals]
  );

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      title="Assign Terminal"
      visible={true}
      width={800}
      onCancel={closeModal}
    >
      <Form form={form} layout="vertical" onFinish={submitModal}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={rate.origin} name="originTerminals">
              {terminalSelect(originShipType, 'origin')}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={rate.destination} name="destinationTerminals">
              {terminalSelect(destinationShipType, 'destination')}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={20}>
            <Form.Item>
              <Button className="modal-close-btn" onClick={closeModal}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button
                className="modal-save-btn"
                htmlType="submit"
                loading={isLoading}
                type="primary"
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RateTerminalAssignModal;
