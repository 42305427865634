import { PageHeader } from '@ant-design/pro-layout';
import { Space } from 'antd';
import React, { useMemo, useState } from 'react';

import RatesFilter from '../components/filters/RatesFilter';
import RateTable from '../components/tables/RateTable';
import { useCarriers, useDefaultModes, useShipTypes } from '../hooks/apiHooks';
import { useRates } from '../hooks/apiHooks/rate';
import Layout from '../layout';
import { isLocationMatch } from '../models/Rate';

const AdminRatesPage = ({ routes }) => {
  const { allCarriers, isLoading: isCarriersLoading } = useCarriers();
  const { allShipTypes } = useShipTypes();
  const { defaultModes } = useDefaultModes();
  const { isLoading: isRatesLoading, rates } = useRates();
  const [filter, setFilter] = useState(null);

  const filteredRates = useMemo(() => {
    if (!filter) return [];
    // Check if it's a unified search
    const isUnifiedSearch = filter.searchType !== 'separate';
    return rates.filter(
      (rate) =>
        (!filter.carrierIds || filter.carrierIds.includes(rate.carrierId)) &&
        (!filter.modeName || rate.modeName === filter.modeName) &&
        (!filter.origin ||
          isLocationMatch(rate, filter.origin, true, isUnifiedSearch)) &&
        (!filter.destination ||
          isLocationMatch(rate, filter.destination, false, false))
    );
  }, [filter, rates]);

  const isTableLoading = useMemo(
    () => isCarriersLoading || (isRatesLoading && !rates.length),
    [isCarriersLoading, isRatesLoading, rates.length]
  );

  return (
    <Layout disableLoading showCarriersNavigation menuIndex={0} routes={routes}>
      <PageHeader
        className="site-page-header"
        style={{ marginBottom: '20px' }}
        title="Rates for All Carriers"
      />
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <RatesFilter
          showAllCarriers
          carriers={allCarriers}
          filter={filter}
          modes={defaultModes}
          onFilterChange={setFilter}
        />
        <RateTable
          showAllCarriers
          carriers={allCarriers}
          customEmptyText={!filter ? 'No filters selected' : false}
          loading={isTableLoading}
          rates={isTableLoading ? [] : filteredRates}
          selectedCarrier={null}
          shipTypes={allShipTypes}
        />
      </Space>
    </Layout>
  );
};

export default AdminRatesPage;
