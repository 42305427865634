export type User = {
  id: number;
  carrierId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  ext?: number;
  password?: string;
  confirmPassword?: string;
  role?: UserRole;
  company?: string;
};

export enum UserRole {
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Admin',
  CARRIER = 'Carrier',
  CUSTOMER = 'Customer',
}
