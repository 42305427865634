import axios from 'axios';

import { Contact, Contactnote } from '../models/Contact';

const uri = '/contact';

const create = async (contact: Contact) => {
  const { data } = await axios.post<Contact>(`${uri}`, contact);
  return data;
};

const getAll = async () => {
  const { data } = await axios.get<Contact[]>(`${uri}`);
  return data;
};


const getFull = async (id: number) => {
  const { data } = await axios.get<Contact>(`${uri}/${id}`);
  return data;
};

const update = async (contact: Contact) => {
  const { data } = await axios.put<Contact>(`${uri}/${contact.id}`, contact);
  return data;
};

const remove = async (id: number) => {
  const { data } = await axios.delete(`${uri}/${id}`);
  return data;
};

const createNote = async (note: Contactnote) => {
  const { data } = await axios.post<Contactnote>(`/contact/${note.contactId}/note`, note);
  return data;
}

const deleteNote = async (id: number) => {
  const { data } = await axios.delete(`/contact/note/${id}`);
  return data;
};

const apis = {
  create,
  createNote,
  deleteNote,
  getAll,
  getFull,
  remove,
  update,
};

export default apis;
