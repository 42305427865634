import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Terminal } from '../../models/Terminal';
import terminalService from '../../services/terminalService';

export const useTerminals = () => {
  const { data, status } = useQuery('allTerminals', () =>
    terminalService.getAll()
  );

  return {
    allTerminals: data || [],
    status,
  };
};

export const useTerminalCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<Terminal, Error, Terminal>(terminalService.create, {
    onSuccess: (data) => {
      queryClient.setQueryData<Terminal[]>(
        'allTerminals',
        (terminals: Terminal[] | undefined) => [...(terminals || []), data]
      );
    },
  });
};

export const useTerminalUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<Terminal, Error, Terminal>(terminalService.update, {
    onSuccess: (data) => {
      queryClient.setQueryData<Terminal[]>(
        'allTerminals',
        (terminals: Terminal[] | undefined) =>
          terminals?.map((terminal) =>
            terminal.id === data.id ? data : terminal
          ) || []
      );
    },
  });
};

export const useTerminalRemove = () => {
  const queryClient = useQueryClient();

  return useMutation<Terminal, Error, number>(terminalService.remove, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<Terminal[]>(
        'allTerminals',
        (terminals: Terminal[] | undefined) =>
          terminals?.filter((terminal) => terminal.id !== variables) || []
      );
    },
  });
};
