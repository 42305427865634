import { ClassRateMap } from './Class';
import { PROVINCES } from './Constants';

export type Rate = {
  id: number;
  carrierId: number;
  carrierName?: string;
  origin: string;
  originLat?: number;
  originLng?: number;
  distanceToOrigin?: number;
  shipTypeId: number;
  destination: string;
  destinationLat?: number;
  destinationLng?: number;
  destinationShipTypeId: number;
  modeId: number;
  modeName?: string;
  distance?: number;
  transitDays?: number;
  createdAt?: Date;
  updatedAt?: Date;
  classRate: ClassRateMap;
  preferred?: number;
  active: boolean;
  originTerminals?: Array<number>;
  destinationTerminals?: Array<number>;
  transitional?: boolean;
  skipFuelSurcharge?: boolean;
  fuelSurcharge?: number;
  freeformPrice?: number; // to simplify freeform pricing
};

export type RateFilter = {
  carrierIds?: Array<number>;
  origin?: string;
  destination?: string;
  modeName?: string;
  searchType?: string;
};

export const isPreferred = (rate: Rate) => {
  return 1 === rate.preferred;
};
export const isUnPreferred = (rate: Rate) => {
  return -1 === rate.preferred;
};

export const isLocationMatch = (
  rate: Rate,
  searchText: String,
  isOrigin: boolean,
  isUnified: boolean
): Boolean => {
  let result = false;
  const regions = [
    [
      'Abbotsford, BC',
      'Burnaby, BC',
      'Chilliwack, BC',
      'Coquitlam, BC',
      'Delta, BC',
      'Kitsilano, BC',
      'Langley, BC',
      'Maple Ridge, BC',
      'Mission, BC',
      'New Westminster, BC',
      'North Vancouver, BC',
      'Pitt Meadows, BC',
      'Port Coquitlam, BC',
      'Port Moody, BC',
      'Richmond, BC',
      'Surrey, BC',
      'Tsawwassen, BC',
      'Vancouver, BC',
      'West Vancouver, BC',
      'Westminster, BC',
      'White Rock, BC',
    ],
    [
      'Acheson, AB',
      'Beaumont, AB',
      'Edmonton, AB',
      'Fort Saskatchewan, AB',
      'Gibbons, AB',
      'Leduc, AB',
      'Nisku, AB',
      'Sherwood Park, AB',
      'Spruce Grove, AB',
      'St. Albert, AB',
      'Stony Plain, AB',
    ],
    [
      'Airdrie, AB',
      'Banff, AB',
      'Calgary, AB',
      'Canmore, AB',
      'Chestermere, AB',
      'Langdon, AB',
      'Okotoks, AB',
    ],
    [
      'Ajax, ON',
      'Aurora, ON',
      'Barrie, ON',
      'Bolton, ON',
      'Brampton, ON',
      'Burlington, ON',
      'Caledon, ON',
      'Concord, ON',
      'Dundas, ON',
      'East York, ON',
      'Etobicoke, ON',
      'Georgetown, ON',
      'Halton Hills, ON',
      'Hamilton, ON',
      'King City, ON',
      'King City, ON',
      'Maple, ON',
      'Markham, ON',
      'Milton, ON',
      'Mississauga, ON',
      'Newmarket, ON',
      'North York, ON',
      'Oakville, ON',
      'Orangeville, ON',
      'Oshawa, ON',
      'Pickering, ON',
      'Richmond Hill, ON',
      'Scarborough, ON',
      'Stoney Creek, ON',
      'Stouffville, ON',
      'Thornhill, ON',
      'Toronto, ON',
      'Vaughan, ON',
      'Waterdown, ON',
      'Whitby, ON',
      'Woodbridge, ON',
      'York, ON',
    ],
    [
      'Beaconsfield, QC',
      'Blainville, QC',
      'Boisbriand, QC',
      'Boucherville, QC',
      'Brossard, QC',
      'Dollard-Des-Ormeaux, QC',
      'Dorval, QC',
      'Greenfield Park, QC',
      'Kirkland, QC',
      'Lachine, QC',
      'Lasalle, QC',
      'Laval, QC',
      'Longueuil, QC',
      'Longueuil, QC',
      'Mirabel, QC',
      'Montreal, QC',
      'Pointe-Claire, QC',
      'Rosemere, QC',
      'Saint Eustache, QC',
      'Saint-Eustache, QC',
      'Saint-Hubert, QC',
      'Saint-Laurent, QC',
      'Saint-Lazare, QC',
      'Sainte-Catherine, QC',
      'St-Eustache, QC',
      'St-Hubert, QC',
      'Terrebonne, QC',
      'Vaudreuil-Dorion, QC',
      'Verdun, QC',
      'Westmount, QC',
    ],
    [
      'Bedford, NS',
      'Cole Harbour, NS',
      'Dartmouth, NS',
      'Halifax, NS',
      'Lower Sackville, NS',
      'Porters Lake, NS',
      'Sackville, NS',
      'Shearwater, NS',
    ],
    [
      'Carp, ON',
      'Kanata, ON',
      'Nepean, ON',
      'Orleans, ON',
      'Ottawa, ON',
      'Vars, ON',
    ],
    [
      'Conception Bay South, NL',
      'Mount Pearl, NL',
      'Paradise, NL',
      'Portugal Cove, NL',
      "St. John's, NL",
      'Torbay, NL',
    ],
    ['Corunna, ON', 'Sarnia, ON'],
    ['Dieppe, NB', 'Moncton, NB', 'Riverview, NB'],
    ['Fredericton, NB', 'Gagetown, NB', 'Oromocto, NB'],
    ['Headingley, MB', 'Winnipeg, MB'],
    ['Kitimat, BC', 'Terrace, BC'],
    ['Lacombe, AB', 'Red Deer, AB'],
    ['Levis, QC', 'Levis, QC', 'Quebec City, QC', 'Quebec, QC', 'Vanier, QC'],
    ['Quispamsis, NB', 'Rothesay, NB', 'Saint John, NB'],
    ['Saanich, BC', 'Victoria, BC'],
    ['Sydney Mines, NS', 'Sydney, NS'],
  ];
  // CP-107: Use Regex to replace Provinces in the search text with appropriate abbreviations
  const updatedSearchText = searchText.replace(
    /\b(\w+(\s\w+)?)\b/g,
    (match) => {
      const province = PROVINCES.find(
        (p) => p.text.toLowerCase() === match.toLowerCase()
      );
      return province ? province.value : match;
    }
  );
  let searchSet = [updatedSearchText.toLowerCase()];
  // typing a province abbreviation should filter entries formatted like 'Caledon, ON'
  if (updatedSearchText.length === 2) {
    searchSet = [`, ${updatedSearchText}`.toLowerCase()];
  } else {
    // see if our search text matches any of the regions
    let matchedRegions = regions.filter((nextRegion) => {
      // for each region, check if any entries match passed text
      let regionMatches = nextRegion.filter((nextPlace) =>
        // allow matching if regardless of comma
        nextPlace
          .toLowerCase()
          .replace(/,/g, '')
          .includes(updatedSearchText.replace(/,/g, '').toLowerCase())
      );
      return regionMatches.length > 0;
    });
    if (matchedRegions.length > 0) {
      searchSet = matchedRegions[0];
    }
  }

  let matches = [];

  if (isUnified) {
    // If it's a unified search, check both origin and destination
    matches = searchSet.filter(
      (nextText) =>
        rate.origin.toLowerCase().includes(nextText.toLowerCase()) ||
        rate.destination.toLowerCase().includes(nextText.toLowerCase())
    );
  } else {
    if (isOrigin) {
      matches = searchSet.filter((nextText) =>
        rate.origin.toLowerCase().includes(nextText.toLowerCase())
      );
    } else {
      matches = searchSet.filter((nextText) =>
        rate.destination.toLowerCase().includes(nextText.toLowerCase())
      );
    }
  }

  result = matches.length > 0;
  return result;
};
