import { Space, Typography } from 'antd';
import React, { useCallback } from 'react';

import { CarrierPermission } from '../../models/Carrier';
import Toggle from './Toggle';

interface IProps {
  defaultValue: CarrierPermission;
  onChange: (permission: CarrierPermission) => void;
  adminOnly?: boolean;
  submitting?: boolean;
}

const PermissionsToggle: React.FC<IProps> = ({
  adminOnly,
  defaultValue,
  onChange,
  submitting,
}) => {
  const togglePermission = useCallback(
    (permission: CarrierPermission, on: boolean) => {
      let newValue = defaultValue;
      if (!on) {
        newValue ^= permission;
      } else {
        newValue |= permission;
      }
      onChange(newValue);
    },
    [defaultValue, onChange]
  );

  return (
    <Space>
      <Space>
        <Toggle
          defaultChecked={
            defaultValue === CarrierPermission.admin ||
            defaultValue === CarrierPermission.all
          }
          loading={submitting}
          size="small"
          onChange={(value) => togglePermission(CarrierPermission.admin, value)}
        />
        <Typography.Text style={{ fontSize: 12, fontWeight: 400 }}>
          Admin
        </Typography.Text>
      </Space>
      {!adminOnly && (
        <Space>
          <Toggle
            defaultChecked={
              defaultValue === CarrierPermission.carrier ||
              defaultValue === CarrierPermission.all
            }
            loading={submitting}
            size="small"
            onChange={(value) =>
              togglePermission(CarrierPermission.carrier, value)
            }
          />
          <Typography.Text style={{ fontSize: 12, fontWeight: 400 }}>
            Carrier
          </Typography.Text>
        </Space>
      )}
    </Space>
  );
};

export default PermissionsToggle;
