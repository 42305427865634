import axios from 'axios';

import { Vinaudit } from '../models/Vinaudit';

const baseUrl = 'https://specifications.vinaudit.com/v3';

const getSpecifications = async (vin?: string) => {
  if (!vin) {
    return null;
  }

  const { data } = await axios.get<Vinaudit>(`${baseUrl}/specifications`, {
    params: {
      key: process.env.REACT_APP_VINAUDIT_API_KEY,
      vin,
    },
  });

  if (!data.success) {
    return null;
  }

  return data;
};

const apis = {
  getSpecifications,
};

export default apis;
